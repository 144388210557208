import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";

import apiActions from "../../../../../actions/apiActions";
import { alert } from "../../../../common/Modal";
import routes from "../../../../../routes";
import mapRouteParams from "../../../../common/router/mapRouteParams";
import catalogActions from "../../../../../actions/catalogActions";
import { connect } from "../../../../StateProvider";
import RESOURCE_TYPES from "../../../../../configs/resourceTypes";
import PRIVILEGE_CODES from "../../../../../configs/privilegeCodes";
import { checkAccessOnObject } from "../../../../../utils/rights";

import Menu from "../../../../common/UI/Menu";

import styles from "./styles.less";

class CatalogsMenu extends React.PureComponent {
  static propTypes = {
    sectionId: PropTypes.string,
    catalogs: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      isAccessAdmin: false,
      reordering: false,
    };
  }

  componentDidMount(prevProps) {
    this.setSectionAccess();
    this.sortCatalogs(this.props.catalogs);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.section !== this.props.section) {
      this.setSectionAccess();
    }

    // if data changed not during reordering
    if ((prevProps.section !== this.props.section || prevProps.catalogs !== this.props.catalogs ) && !this.state.reordering) {
      this.sortCatalogs(this.props.catalogs);
    }
  }

  sortCatalogs = ( catalogs, order ) => {
    if (order) {
      order = order.filter(key => key !== "$add");
      order.forEach((id, index) => {
        const catalogIndex = catalogs.findIndex(c => c.get("id") === id);
        if (catalogIndex !==-1) {
          catalogs = catalogs.setIn([catalogIndex, "index"], index);
        }
      });
  
      catalogs = catalogs && catalogs
        .sortBy(s => s.get("index"))
    }

    catalogs = catalogs ? catalogs.toList() : Immutable.List();
    this.setState({ catalogs });
  };

  setSectionAccess = () => {
    let isAccessAdmin;

    if (this.props.sectionId === "$favorite") {
      isAccessAdmin = true;
    } else {
      isAccessAdmin = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ADMIN
      );
    }

    this.setState({ isAccessAdmin });
  }

  isSchemaLocked = () => {
    const { t } = this.props;
    const isSchemaLocked =
      this.props.license && this.props.license.getIn(["modules", "schemalock"]);
    if (isSchemaLocked) {
      alert({
        headerText: t("modals.schemaLock.headerText"),
        text: t("modals.schemaLock.text"),
        okText: t("modals.schemaLock.okText")
      });
    }
    return isSchemaLocked;
  };

  canDrag = dragItem => {
    if (dragItem.key === "$add") {
      return false;
    }

    // если не админ секции
    if ( ! this.state.isAccessAdmin ) {
      return false;
    }

    // внешняя дополнительная проверка
    if ( this.props.canDrag && ! this.props.canDrag(dragItem) ) {
      return false;
    }

    return true;
  };

  canDrop = (dragItem, dropItem) => {
    if (dropItem.key === "$add") {
      return false;
    }

    // deny move catalog to Favorite section
    if (this.props.sectionId === "$favorite" && dragItem.listId !== "$favorite") {
      return false;
    }

    const allowedDragTypes = [RESOURCE_TYPES.CATALOG];
    if (!allowedDragTypes.find(item => item === dragItem.dragType)) {
      return false;
    }

    // внешняя дополнительная проверка
    if ( this.props.canDrop && ! this.props.canDrop(dragItem, dropItem) ) {
      return false;
    }

    return true;
  };

  onDrop = (dragItem, dropItem, orderKey) => {
    let catalogs = this.state.catalogs;

    // check if move to another section
    if ( dragItem ) {
      const catalogId = dragItem.key;
      const sectionId = this.props.sectionId;
      let newCatalog = dragItem.item.props.item;
      const catalogSectionId = newCatalog && newCatalog.get("sectionId");
      const movedToAnotherSection = catalogSectionId && catalogSectionId != sectionId && sectionId !== "$favorite";

      if ( movedToAnotherSection ) {
        const params = { sectionId: catalogSectionId, catalogId };
        const data = {
          sectionId: sectionId
        };
  
        // reordering state
        this.setState({ reordering: true });

        // save in local
        newCatalog = newCatalog.set("sectionId", sectionId);
        catalogs = catalogs.push(newCatalog);

        // save in local
        this.sortCatalogs(catalogs, orderKey)

        // save
        apiActions
          .updateCatalog(params, data)
          .then(()=>{
            // save
            this.saveOrder(orderKey);
          })
          .catch(e => {
            this.setState({ reordering: false });
            alert({
              headerText: this.props.t("modals.moveCatalogError.headerText"),
              text: _.get(e, "response.text"),
              okText: this.props.t("modals.moveCatalogError.okText")
            })
          });

        return;
      }    
    }    
    
    // save in local
    this.sortCatalogs(catalogs, orderKey)

    // save
    this.saveOrder(orderKey);
  };

  onRemoved = (dragItem, dropItem, orderKey) => {    
    // deny move catalog out of the Favotire
    if ( this.props.sectionId === "$favorite" ) {
      return;
    }

    // save in local
    const catalogId = dragItem.key;
    let catalogs = this.state.catalogs;
    catalogs = catalogs.filter(c => c.get("id") !== catalogId);
    this.sortCatalogs(catalogs, orderKey)

    // save
    this.saveOrder(orderKey);
  }
  saveOrder = (orderKey) => {
    // reorder catalogs list
    const filteredKey = orderKey.filter(key => key !== "$add");
    this.setState({ reordering: true });
    apiActions.updateSection(
      {
        sectionId: this.props.sectionId
      },
      {
        catalogsPriorities: filteredKey
      }
    ).then(() => {
      this.setState({ reordering: false });
    })
    .catch(e => {
      this.setState({ reordering: false });
    });
  }

  render() {
    let catalogs = this.state.catalogs; 
    const {sectionId, className, activeId, direction, renderItem} = this.props;
    if (! catalogs ){
      return null;
    }

    if (this.state.isAccessAdmin && !this.isSchemaLocked() && sectionId !== "$favorite" ) {
      catalogs = catalogs.concat([
        Immutable.Map({
          id: "$add",
          sectionId: sectionId,
          icon: "interface-72",
          name: this.props.t("buttons.addCatalog"),
          route: routes.catalogAdd,
          className: styles.addButton
        })
      ]);
    }

    const items = catalogs.map( item => renderItem(item, activeId));


    return (
        <Menu
          listId={sectionId}
          items={items}
          activeId={activeId}
          direction={direction}
          className={cn(styles.menu, className)}
          popupClassName={styles.popup}
          dragType="catalog"
          canDrag={this.canDrag}
          canDrop={this.canDrop}
          onDrop={this.onDrop}
          onRemoved={this.onRemoved}
        />
    );
  }
}

export default withTranslation()(
  connect(
    mapRouteParams(CatalogsMenu, routes.catalog),
    {
      license: ["license"],
      sections: ["sections"],
    },
    (props, {license, sections}) => {
        const section = sections.get(props.sectionId);
        return {
            ...props,
            license,
            section
        };
    }
  )
);

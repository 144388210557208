import React from 'react';

import ButtonTransparent from '../../../../common/UI/ButtonTransparent';
import Icon from '../../../../common/UI/Icon';

import styles from "./userInfo.less";

const userInfo = ({ userName, userEmail, onClickExit, t }) => {
    return (
        <div className={styles.containerUserMenuAbout}>
            <div className={styles.containerUserInfo}>
                <span className={styles.userName}>{userName}</span>
                <span className={styles.userEmail}>{userEmail}</span>
            </div>
            <ButtonTransparent
                onClick={onClickExit}
                className={styles.btnExit}
            >
                <div className={styles.containerLogout}>
                    <Icon type="icon interface-13" />
                    {t("userMenu.userInfo.logout")}
                </div>
            </ButtonTransparent>
        </div>
    );
};

export default userInfo;
import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { connect } from "../StateProvider";

import Dropdown from "./Dropdown";

const log = require("debug")("CRM:Component:DropdownRemote");

class DropdownRemote extends React.PureComponent {
  static propTypes = {
    sortBy: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
      PropTypes.string
    ]),

    type: PropTypes.string.isRequired,
    cacheTime: PropTypes.number,
    additionalItems: PropTypes.array,
    onLoadItems: PropTypes.func,
    filterFn: PropTypes.func || PropTypes.bool,
    requestParams: PropTypes.object,
    sortFn: PropTypes.func,
    loadAvailableItems: PropTypes.func,
    clearAvailableItems: PropTypes.func,
    className: PropTypes.string,
    showArrow: PropTypes.bool,
    storeKey: PropTypes.string.isRequired,
    onVisibleChange: PropTypes.func
  };

  static defaultProps = {
    storeKey: String(Math.random()),
    items: []
  };

  hasChanges = false;
  wasOpened = false;

  state = { text: "", items: [] };

  onOpenChange = isOpen => {
    this.props.onVisibleChange && this.props.onVisibleChange(isOpen);

    this.wasOpened = true;
    const title = this.state.text ? { title: this.state.text } : {};
    const params = _.extend(title, this.props.requestParams);

    if (isOpen && !this.hasChanges) {
      if (this.props.loadAvailableItems) {
        this.props.loadAvailableItems(
          this.props.type,
          this.props.storeKey,
          params
        );
      }
    } else if (isOpen && this.hasChanges) {
      if (this.props.loadAvailableItems) {
        this.props.loadAvailableItems(
          this.props.type,
          this.props.storeKey,
          params
        );
      }
    }
  };

  _onTextChange = text => {
    // if (this.props.searchable) {
    this.hasChanges = true;

    this.setState({ text }, () => {
      const {
        loadAvailableItems,
        onSearchText,
        type,
        storeKey,
        requestParams
      } = this.props;

      const params = _.extend({ title: this.state.text }, requestParams);
      loadAvailableItems && loadAvailableItems(type, storeKey, params);
      onSearchText && onSearchText(text);
    });

    // }
  };

  onSelectItems = value => {
    // It necessary when user is selecting
    // item after searching
    // so we load back items that search has filtered
    if (this.state.text) {
      this.setState({ text: "" }, () => this._onTextChange());
    }

    this.props.onSelectItems && this.props.onSelectItems(value);
  };

  sortItems = () => {
    let { items, additionalItems, filterFn, sortFn, sortBy } = this.props;

    items = items.toJS ? items.toJS() : items;

    if (filterFn) {
      items = items.filter(filterFn);
    }

    if (sortBy === undefined || sortBy === true) {
      sortBy = "text";
    }

    items = _.sortBy(items, sortBy);

    if (additionalItems && additionalItems.length) {
      items.unshift(...additionalItems);
    }

    items = _.uniqBy(items, "key");

    if (sortFn) {
      items = sortFn(items);
    }

    /* сортировка элементов для того чтобы поля типа "+добавит" и [все] были вначале списка */
    items.sort((a, b) => {
      if (a.sort === b.sort) {
        return 0;
      } else {
        if (!a.sort) {
          return 1;
        }
        if (!b.sort) {
          return -1;
        }
      }
      return a.sort - b.sort;
    });

    this.setState(() => ({
      items: items
    }));
  };

  onTextChange = _.debounce(this._onTextChange, 400);

  componentDidMount() {
    const { items } = this.props;
    this.sortItems(items);
  }

  componentWillUnmount() {
    if (this.props.clearAvailableItems) {
      this.props.clearAvailableItems(this.props.type, this.props.storeKey);
    }
  }

  componentDidUpdate(prevProps) {
    let { items, additionalItems, filterFn, sortFn, sortBy } = this.props;

    let {
      items: prevItems,
      additionalItems: prevAdditionalItems,
      filterFn: prevFilterFn,
      sortFn: prevSortFn,
      sortBy: prevSortBy
    } = prevProps;

    if (
      (prevItems && prevItems !== items) ||
      (prevAdditionalItems && prevAdditionalItems !== additionalItems) ||
      (prevFilterFn && prevFilterFn !== filterFn) ||
      (prevSortFn && prevSortFn !== sortFn) ||
      (prevSortBy && prevSortBy !== sortBy)
    ) {
      this.sortItems(items);

      log("items", items);
    }
  }

  render() {
    let items = this.state.items;
    let additionalItems = this.props.additionalItems;

    if (this.props.filterFn) {
      items = items.filter(this.props.filterFn);
    }

    let sortBy = this.props.sortBy;
    if (sortBy === undefined || sortBy === true) {
      items = _.sortBy(items, "text");
    } else if (sortBy) {
      items = _.sortBy(items, sortBy);
    }

    if (additionalItems && additionalItems.length) {
      items.unshift(...additionalItems);
    }

    items = _.uniqBy(items, "key");

    if (this.props.sortFn) {
      items = this.props.sortFn(items);
    }

    return (
      <Dropdown
        bordered={this.props.bordered}
        onSelectItems={this.onSelectItems}
        showLoading={this.state.loading}
        searchable={this.props.searchable}
        filterable={this.props.filterable}
        value={this.props.value}
        items={items}
        placeholder={this.props.placeholder}
        className={this.props.className}
        dropdownClassName={this.props.dropdownClassName}
        optionsClassName={this.props.optionsClassName}
        autoFocus={this.props.autoFocus}
        showArrow={this.props.showArrow}
        showSearch={true}
        open={this.props.open}
        openedSelect={this.props.openedSelect}
        multiselect={this.props.multiselect}
        onTextChange={this.onTextChange}
        onOpenChange={this.onOpenChange}
        loadAvailableItems={this.props.loadAvailableItems}
        clearAvailableItems={this.props.clearAvailableItems}
      />
    );
  }
}

export default connect(
  DropdownRemote,
  ["dropdownCollections"],
  function(
    { storeKey, type, items, loading, ...props },
    { dropdownCollections }
  ) {
    storeKey =
      storeKey || this.storeKey || (this.storeKey = String(Math.random()));
    items = dropdownCollections.getIn([type, storeKey, "items"]);
    loading = !!dropdownCollections.getIn([type, storeKey, "loading"]);
    return {
      items,
      loading,
      type,
      storeKey,
      ...props
    };
  }
);

export default t => ({
  hidden: true,
  element: "bpmn:TimerEventDefinition",
  service: "",
  title: t("script.controlls.intermediateCatchEvent.title"),
  helplink: "",
  class: "event",
  color: "#7d7f80",
  border: 1,
  icon: "anticon-icon time-4",
  offset_x: -60,
  offset_y: -16,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "section",
      name: t("script.controlls.intermediateCatchEvent.title"),
      type: "group"
    },
    {
      id: "timeDuration",
      name: t("script.controlls.intermediateCatchEvent.timeout"),
      type: "text",
      readOnly: false,
      hint:
      t("script.controlls.intermediateCatchEvent.timeoutHint"),
      config: {
        type: "text",
        theme: "script",
        map: "timer"
      }
    }
  ],
  exits: []
});

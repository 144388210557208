import _ from "lodash";

function getInputOutput(businessObject) {
  return _.chain(businessObject)
    .get("extensionElements.values")
    .find({ $type: "camunda:InputOutput" });
}

export function getInput(businessObject) {
  return (
    getInputOutput(businessObject)
      .get("inputParameters")
      .value() || []
  );
}

export function getOutput(businessObject) {
  return (
    getInputOutput(businessObject)
      .get("outputParameters")
      .value() || []
  );
}

import React from "react";
import cn from "classnames";
import { Modal } from "antd";

const Base = ({ visible, ...props }) => {
  const width = props.width || "80%";
  const wrapClassName = cn("base-modal-bpium", props.wrapClassName|| null);
  return (
    <Modal
      open={visible !== undefined ? visible : true}
      closable={false}
      maskClosable={false}
      footer=""
      width={width}
      {...props}
      style={{ transition: "none" }}
      transitionName=""
      maskTransitionName=""
      wrapClassName={wrapClassName}
    />
  );
};

export default Base;

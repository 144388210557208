// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__widgetButton___VErVl {\n  margin: 0 5px;\n}\n.styles__langPreview___3PFpw {\n  color: white;\n  font-variant: small-caps;\n  letter-spacing: 1px;\n}\n.styles__langWidgetMenu___LzgOC .ant-dropdown-menu-item {\n  padding-left: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/widgets/LanguageSwitcher/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,YAAA;EACA,wBAAA;EACA,mBAAA;AAAF;AAGA;EAEI,kBAAA;AAFJ","sourcesContent":[".widgetButton {\n  margin: 0 5px;\n}\n\n.langPreview {\n  color: white;\n  font-variant: small-caps;\n  letter-spacing: 1px;\n}\n\n.langWidgetMenu {\n  :global(.ant-dropdown-menu-item) {\n    padding-left: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetButton": "styles__widgetButton___VErVl",
	"langPreview": "styles__langPreview___3PFpw",
	"langWidgetMenu": "styles__langWidgetMenu___LzgOC"
};
export default ___CSS_LOADER_EXPORT___;

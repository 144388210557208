import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select } from "antd";

import Icon from '../../../common/UI/Icon';
import cn from "classnames";
import ImmutablePropTypes from "react-immutable-proptypes";
import { withTranslation } from 'react-i18next';

import fieldTypeIcons from "../../../../configs/fieldTypeIcons";
import { DEFAULT_GROUP_ID } from "../../../../configs/import";
import FIELD_TYPES from "../../../../configs/fieldTypes";
import { connect } from "../../../StateProvider";

import styles from "../ImportStyles.less";

class HeaderWithDropDown extends React.Component {
  UNSAFE_componentWillMount() {
    this.setSelectItems();
  }

  componentDidUpdate(prevProps) {
    const { availableFields, additionalFields } = this.props;
    const {
      availableFields: prevAvailableFields,
      additionalFields: prevAdditionalFields
    } = prevProps;

    const fieldsBySectionsChanged =
      prevAvailableFields && prevAvailableFields !== availableFields;
    const additionalFieldsChanged =
      prevAdditionalFields && prevAdditionalFields !== additionalFields;

    if (fieldsBySectionsChanged || additionalFieldsChanged) {
      this.setSelectItems();
    }
  }

  setSelectItems() {
    let {
      availableFields,
      fieldId,
      additionalFields,
      catalogName,
      filterFn
    } = this.props;

    let _curGroup;
    const sections = [];

    const systemGroup = {
      id: DEFAULT_GROUP_ID,
      default: true,
      fields: []
    };

    additionalFields.forEach(field => {
      systemGroup.fields.push(field);
    });

    sections.push(systemGroup);

    availableFields = availableFields.filter(field => filterFn(field, fieldId));

    availableFields.forEach(field => {
      if (field.get("type") === FIELD_TYPES.GROUP) {
        _curGroup = {
          id: field.get("id"),
          name: field.get("name"),
          fields: []
        };
        sections.push(_curGroup);
      } else {
        if (!_curGroup) {
          _curGroup = {
            id: "$virtualGroupId",
            name: catalogName,
            fields: []
          };
          sections.push(_curGroup);
        }
        _curGroup.fields.push(field);
      }
    });

    this.setState({
      sections
    });
  }

  onSelectItem = selectedFieldId => {
    const { onSelectItem, fieldId } = this.props;

    onSelectItem && onSelectItem(fieldId, selectedFieldId);
  };

  render() {
    const { sections } = this.state;
    const { fieldName, selectedFieldId, disabled, t } = this.props;

    return (
      <div className={styles.headerWrapper}>
        <div className={styles.headerName}>{fieldName}</div>
        {/* {disabled && <LoadingSpin/>} */}
        {selectedFieldId && (
          <Select
            value={selectedFieldId}
            disabled={disabled}
            // onFocus={this.onFocusInput}
            // onBlur={this.onBlurInput}
            // onChange={this.onChange}
            // onSearch={this.onSearch}
            // filterOption={this.filterOption}
            onSelect={this.onSelectItem}
            getPopupContainer={() => document.getElementById("importTable")}
          >
            {sections.map(section => {
              if (section["default"]) {
                return section.fields.map(field => {
                  const fieldId = field.get("id");
                  const fieldName = field.get("name");
                  return (
                    <Select.Option
                      key={fieldId}
                      value={fieldId}
                      title={fieldName}
                    >
                      <span className={styles.fieldName}>{fieldName}</span>
                    </Select.Option>
                  );
                });
              }
              return (
                <Select.OptGroup key={section.id} label={section.name}>
                  {section.fields.map(field => {
                    const fieldId = field.get("id");
                    const fieldName = field.get("name");
                    const fieldType = field.get("type");
                    const fieldIcon = fieldTypeIcons[fieldType];

                    return (
                      <Select.Option
                        key={fieldId}
                        value={fieldId}
                        title={fieldName}
                      >
                        <Icon
                          className={styles.fieldIcon}
                          type={"icon " + fieldIcon}
                        />
                        <span className={styles.fieldName}>{fieldName}</span>
                        <span className={styles.fieldType}>
                          {"(" + t(`fieldTypes.${fieldType}.name`) + ")"}
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select.OptGroup>
              );
            })}
          </Select>
        )}
      </div>
    );
  }
}

HeaderWithDropDown.propTypes = {
  fieldId: PropTypes.string,
  fieldName: PropTypes.string,
  columns: ImmutablePropTypes.list,

  selectedFieldId: PropTypes.string,
  additionalFields: ImmutablePropTypes.list,
  availableFields: ImmutablePropTypes.list,
  catalogName: PropTypes.string,
  onSelectItem: PropTypes.func,
  filterFn: PropTypes.func
};

export default withTranslation()(connect(
  React.memo(HeaderWithDropDown),
  {},
  props => {
    const { fieldId, fields } = props;

    const column = fields.find(column => column.get("id") === fieldId);
    const selectedFieldId = column && column.get("selectedFieldId");
    const disabled = column && column.get("disabled");
    return {
      ...props,
      selectedFieldId,
      disabled
    };
  }
));

// utils
import _ from "lodash";
import Immutable from "immutable";
import i18n from "../../configs/i18n";

const isImmutable = item => {
  return Immutable.Map.isMap(item) || Immutable.List.isList(item);
};

function containsOnlyDigits(str) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export const validateFieldId = (field, allFields, attr) => {
  const errors = [];

  if (isImmutable(field) && !field.has(attr)) {
    return errors;
  }

  const newId = isImmutable(field) ? field.get(attr) : _.get(field, [attr]);
  const currentField = isImmutable(field) ? field.toJS() : field;
  const currentAllFields = isImmutable(allFields)
    ? allFields.toJS()
    : allFields;

  if (!newId) {
    return errors;
  }

  if (containsOnlyDigits(newId) || !_.isString(newId)) {
    errors.push({
      fieldId: String(_.get(currentField, ["id"], "Not specified")),
      fieldUuid: _.get(currentField, ["uuid"], null),
      message: i18n.t("modals.setApiKeyErrors.notString")
    });
  }

  if (_.startsWith(newId, "$")) {
    errors.push({
      fieldId: String(_.get(currentField, ["id"], "Not specified")),
      fieldUuid: _.get(currentField, ["uuid"], null),
      message: i18n.t("modals.setApiKeyErrors.startWithDollar")
    });
  }

  // We allow only letters, numbers and separation symbols: hyphen, underscore
  const validCharactersRegex = /^[a-zA-Z0-9_-]+$/;

  // We check whether the key matches the allowed characters
  if (!validCharactersRegex.test(newId)) {
    errors.push({
      fieldId: String(_.get(currentField, ["id"], "Not specified")),
      fieldUuid: _.get(currentField, ["uuid"], null),
      message: i18n.t("modals.setApiKeyErrors.containUncorrectSymbol")
    });
  }

  const isSomeValueTheSame = _.some(currentAllFields, f => {
    const fieldId = _.get(f, ["id"], null);
    if (_.isString(fieldId) && fieldId == newId) {
      return true;
    }
    return false;
  });

  if (isSomeValueTheSame) {
    errors.push({
      fieldId: String(_.get(currentField, ["id"], "Not specified")),
      fieldUuid: _.get(currentField, ["uuid"], null),
      message: i18n.t("modals.setApiKeyErrors.someFieldIdTheSame")
    });
  }

  return errors;
};

/*
    Допустимый формат для ключа поля:
        – разрешены латинские буквы, цифры, дефис (-), подчеркивание (_)
        – запрещено задавать чисто числовые идентификаторы
        – чтобы удалить newId, задайте поле пустым
    Дополнительная проверка филдов:
        - нигде в других филдах не должно встречаться в поле id(статический айдишник прилетевший с апишки) 
        - или newId(новый айдишник, который мы задаем), такого же значения как то которое мы задаем
        - id не может быть таким же, как newId
    Значение может быть пустым,
        в этом случае мы просто отпрвляем null на апишку и она уберет запись рекорда из таблицы рекордов
        и возвращаем на клиент айдишник в виде числа
*/

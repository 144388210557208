export const FiltersKeys = {
  // date ranges.
  FIXED: "fixed",
  RELATIVE: "relative",
  NULL: "NULL_DATE",
  
  TODAY: "TODAY_DATE",
  TILL_TODAY: "TILL_TODAY_DATE",    
  THIS_WEEK: "THIS_WEEK_DATE",
  // THIS_WEEK_TILL_TODAY: "THIS_WEEK_TILL_TODAY_DATE",    
  // THIS_WEEK_NEXT_TODAY: "THIS_WEEK_NEXT_TODAY_DATE",
  CURRENT_MONTH: "CURRENT_MONTH_DATE",
  THIS_YEAR: "THIS_YEAR_DATE",

  YESTERDAY: "YESTERDAY_DATE",
  TILL_YESTERDAY: "TILL_YESTERDAY_DATE",
  LAST_WEEK: "LAST_WEEK_DATE",
  LAST_30_DAYS: "LAST_30_DAYS_DATE",
  LAST_MONTH: "LAST_MONTH_DATE",
  LAST_365_DAYS: "LAST_365_DAYS_DATE",
  LAST_YEAR: "LAST_YEAR_DATE",
  CURRENT_DAY: "CURRENT_DAY_DATE",

  TOMORROW: "TOMORROW_DATE",
  TOMORROW_NEXT: "TOMORROW_NEXT_DATE",
  NEXT_WEEK: "NEXT_WEEK_DATE",
  NEXT_30_DAYS: "NEXT_30_DAYS_DATE",
  NEXT_MONTH: "NEXT_MONTH_DATE",
  NEXT_365_DAYS: "NEXT_365_DAYS_DATE",
  NEXT_YEAR: "NEXT_YEAR_DATE",
};

function isNumber(value) {
  return /^\-?\d*$/.test(value);
}

export default function getKeyRange(value) {
  if (typeof value === "string") {
    return {
      keyRange: value
    };
  } else {
    if (
      value &&
      value.get &&
      (isNumber(value.get("at")) || isNumber(value.get("to")))
    ) {
      return {
        keyRange: FiltersKeys.RELATIVE
      };
    } else {
      return {
        keyRange: FiltersKeys.FIXED
      };
    }
  }
}

import * as valueHelper from "./value";

export function format(value) {
  return elementCreator =>
    elementCreator("camunda:List", {
      items: value.map(value => {
        // workaround, because server doesn't support "script" format:
        // https://github.com/paed01/bpmn-engine/blob/v4.2.0/lib/parameter.js#L46
        if (value.type !== "const") {
          throw new Error(`Server doesn't support "${value.type}" in list`);
        }
        return children => children("camunda:Value", value);
      })
    });
}

export function parse(parameterParser, items) {
  return items.map(value => {
    return valueHelper.parse(parameterParser(value));
  });
}

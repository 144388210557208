import Reflux from "reflux";

export default {
  loadMessages: Reflux.createAction({ sync: false }),
  sendMessage: Reflux.createAction({ sync: false }),

  // input actions
  setMessageText: Reflux.createAction({ sync: false }),
  setMessageAttachments: Reflux.createAction({ sync: false }),
  setLoadingAttachment: Reflux.createAction({ sync: false }),

  setReplyMessage: Reflux.createAction({ sync: false }),
  setEditMessage: Reflux.createAction({ sync: false }),
  cancelReplyMessage: Reflux.createAction({ sync: false }),
  cancelEditMessage: Reflux.createAction({ sync: false }),

  cancelResendMessage: Reflux.createAction({ sync: false }),
  cancelUpdateMessage: Reflux.createAction({ sync: false }),
  cancelDeleteMessage: Reflux.createAction({ sync: false }),

  copyMessageTextToClipboard: Reflux.createAction({ sync: false }),
  prepareAvailableMentions: Reflux.createAction({ sync: false }),

  clearMessagesInput: Reflux.createAction({ sync: false })
};

import React, { Component } from "react";
import cn from "classnames";

import { withTranslation } from "react-i18next";

import Icon from "../../../../Icon";
import LoadingSpin from "../../../../../LoadingSpin";

import styles from "../../controls.less";
import TextInputWithActions from "./TextInputWithActions";

class TextInput extends Component {
  state = {
    shouldProcess: false
  };

  onChange = value => {
    this.props.onChange && this.props.onChange(value);
    this.props.eventable && this.setState({ shouldProcess: true });
  };

  onEndEditing = value => {
    this.props.onEndEditing && this.props.onEndEditing(value);
    this.setState({ shouldProcess: false });
  };

  render() {
    const {
      updateProcess,
      eventable,
      actions,
      onEndEditing,
      t,
      tReady, //need to remove from props (cause warning)
      fieldId,
      ...props
    } = this.props;
    let { shouldProcess } = this.state;
    const inProcess = updateProcess && updateProcess.get("inProcess");

    const typeIcon = cn({
      "icon edition-66": shouldProcess && !inProcess
    });

    const newActions = [...(actions || [])];
    if (shouldProcess || inProcess) {
      newActions.push(
        <span
          className={cn(styles.actionIcon, {
            [styles.actionIconGray]: inProcess
          })}
          title={inProcess ? "" : t("record.fields.text.status.readyToSend")}
        >
          {inProcess ? (
            <LoadingSpin />
          ) : (
            <Icon type={typeIcon} className={typeIcon} />
          )}
        </span>
      );
    }
    return (
      <TextInputWithActions
        {...props}
        fieldId={fieldId}
        onEndEditing={this.onEndEditing}
        onChange={this.onChange}
        actions={newActions}
      />
    );
  }
}

export default withTranslation(undefined, { withRef: true })(TextInput);

import _ from "lodash";
import { validateFieldId } from "../utils/validateFieldId";

/**
 * Validate func for newId property
 * @param  {field - current immutable field in editing catalog} field
 * @param  {allFields - all immutable fields in editing catalog} allFields
 * @return {Array} [{ fieldId: id, fieldUuid: 0.4989871972272757, message: "something went wrong" }, ...etc ]
 */

export class Field {
  static validateField(field, allFields) {
    // validate newId option
    if (field.has("newId") && allFields) {
      const attrsToValidate = ["newId"];
      const errors = attrsToValidate.reduce((acc, attr) => {
        const fieldErrors = validateFieldId(field, allFields, attr);
        return acc.concat(fieldErrors);
      }, []);
      return errors;
    }
  }
}

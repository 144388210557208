import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

import RecordDropdown from "../../common/UI/ControlList/controls/RecordDropdown";
import availableLinkedRecordActions from "../../../actions/availableLinkedRecordActions";
import { userFilters } from "../../../configs/filterKeys";

export default class UserField extends React.PureComponent {
  inMapper = v => {
    if (v.toJS) {
      v = v.toJS();
    }
    return {
      key: v.id,
      text: v.title,
      icon: "users-1"
    };
  };

  outMapper = item => {
    return {
      id: item.key,
      title: item.text
    };
  };

  onChange = items => {
    const { onSave, fieldId } = this.props;

    items = items.map(this.outMapper);

    onSave(fieldId, items);
  };

  render() {
    // @override this.props.multiselect
    let config = this.props.config || Immutable.Map({});
    config = config.set("multiselect", true);
    config = config.set("enableSelect", true);
    config = config.set("enableCreate", false);

    let additionalItems = [];
    if (userFilters) {
      userFilters.forEach(f => {
        additionalItems.push({
          key: f.key,
          text: f.value,
          sort: -1
        });
      });
    }

    return (
      <RecordDropdown
        config={config}
        additionalItems={additionalItems}
        remoteGroup="users"
        value={this.props.value && this.props.value.map(this.inMapper)}
        readOnly={this.props.readOnly}
        apiOnly={false}
        onChange={this.onChange}
        loadAvailableItems={availableLinkedRecordActions.loadAvailableItems}
        clearAvailableItems={availableLinkedRecordActions.clearAvailableItems}
      />
    );
  }
}

UserField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onSave: PropTypes.func,
  readOnly: PropTypes.bool
};

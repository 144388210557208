import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Row, Dropdown } from "antd";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "../../../../../../../../StateProvider";

import Icon from "../../../../../../../../common/UI/Icon";
import ButtonTransparent from "../../../../../../../../common/UI/ButtonTransparent";
import { confirm, promptModal } from "../../../../../../../../common/Modal";

import routes from "../../../../../../../../../routes";
import getLink from "../../../../../../../../common/router/getLink";

import PRIVILEGE_CODES from "../../../../../../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../../../../../../configs/resourceTypes";

import apiActions from "../../../../../../../../../actions/apiActions";
import modalsActions from "../../../../../../../../../actions/modalsActions";
import viewActions from "../../../../../../../../../actions/viewActions";
import { validateApiKey } from "../../../../../../../../../utils/validateApiKey";
import { checkAccessOnObject } from "../../../../../../../../../utils/rights";

import styles from "./viewActions.less";

class ViewActions extends Component {
  static propTypes = {
    sceneId: PropTypes.string,
    viewId: PropTypes.string,
    view: PropTypes.object,
    catalog: PropTypes.object,

    history: PropTypes.object,
    location: PropTypes.object
  };

  openApiKeyModal = () => {
    const { t, view } = this.props;
    promptModal({
      headerText: t("modals.apiKeys.viewApiKeyConfirm.headerText"),
      defaultValue: (view && view.get("id")) || "",
      onOk: this.onSetKey,
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel"),
      promptHint: t("modals.apiKeys.promptHint"),
      validateFunc: validateApiKey,
      t
    });
  };

  onSetKey = async newKey => {
    const { view, sceneId, location, history } = this.props;
    const oldViewId = view && view.get("id");
    const viewCatalogId = view.get("catalogId");
    const { id: newViewId } = await apiActions.updateView(
      {
        catalogId: viewCatalogId,
        viewId: oldViewId
      },
      { id: newKey, name: view.get("name"), forRights: view.get("forRights") },
      { sceneId }
    );
    if (newViewId) {
      viewActions.truncateView({
        viewId: oldViewId,
        catalogId: viewCatalogId,
        sceneId
      });
      const link = getLink(location, routes.view, {
        viewId: newViewId
      });
      history.push(link);
    }
  };

  renameView = e => {
    const view = this.props.view;
    const catalogId = view.get("catalogId");
    const sceneId = this.props.sceneId;
    modalsActions.openViewInputModalEdit(view, catalogId, sceneId);
  };

  saveView = e => {
    const { sceneId, view } = this.props;

    let params = {
      name: (view && view.get("name")) || (view && view.get("originName")),
      forRights: view && view.get("forRights")
    };

    // Get new filters from scene view, current filters + new ones
    const filters = view && view.get("filters");
    viewActions.updateExistingView(sceneId, view, params, filters, () => {
      const { location, history } = this.props;
      history.push(location.pathname);
    });
  };

  saveNewView = () => {
    const { sceneId, viewId, catalog } = this.props;

    // catalog right
    const accessOnViewForRights = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.ACCESS
    );

    modalsActions.openViewInputModal(
      sceneId,
      viewId,
      accessOnViewForRights,
      res => {
        const { location, history } = this.props;
        // при создании нового вида, редиректить на станицу вида без search
        location.search = "";
        history.push(getLink(location, null, { viewId: res.id }));
      }
    );
  };

  accessView = () => {
    const { view, location, history } = this.props;

    if (view) {
      let isAccessView =
        view &&
        checkAccessOnObject(RESOURCE_TYPES.VIEW, view, PRIVILEGE_CODES.ACCESS);
      let readOnly =
        !checkAccessOnObject(
          RESOURCE_TYPES.CATALOG,
          this.props.catalog,
          PRIVILEGE_CODES.ACCESS
        ) && !isAccessView;
      modalsActions.openViewAccessModal(view, readOnly, result => {
        if (result && result.viewId) {
          const link = getLink(location, routes.view, {
            viewId: result.viewId
          });
          history.push(link);
        }
      });
    }
  };

  // remove view.
  removeView = e => {
    const { t, sceneId, viewId, view, history, location } = this.props;
    const catalogId = view.get("catalogId");

    confirm({
      headerText: t("modals.removeViewConfirm.headerText"),
      text: view.get("forRights")
        ? t("modals.removeViewConfirm.textForRights")
        : t("modals.removeViewConfirm.text"),
      okText: t("modals.removeViewConfirm.okText"),
      cancelText: t("modals.removeViewConfirm.cancelText"),
      onOk() {
        apiActions.deleteView({
          catalogId,
          viewId,
          sceneId
        });
        history.push(getLink(location, null, { viewId: 0 }));
      }
    });
  };

  resetView = e => {
    const { sceneId, viewId, location, history } = this.props;

    if (viewId == "$new") {
      // сброс на все записи
      history.push(getLink(location, null, { viewId: 0 }));
    } else {
      // set original filters
      viewActions.resetView(sceneId, viewId);
    }
  };

  isViewAdmin = () => {
    const { catalog, view } = this.props;
    const catalogPrivilegeCode = catalog && catalog.get("privilegeCode");

    // is my private view
    const isPrivateView = view && !view.get("forRights");
    if (isPrivateView) {
      return true;
    }

    // is admin of catalog
    if (catalogPrivilegeCode == PRIVILEGE_CODES.ADMIN) {
      return true;
    }

    // has right to view
    const isViewAdmin = checkAccessOnObject(
      RESOURCE_TYPES.VIEW,
      view,
      PRIVILEGE_CODES.ACCESS
    );
    if (isViewAdmin) {
      return true;
    }
  };

  render() {
    const { t, viewId, view, className, isWebForm, withLabel } = this.props;

    if (!viewId || viewId == 0) {
      return null;
    }

    // view state
    const isViewAdmin = this.isViewAdmin();
    const isNewView = viewId === "$new";
    const filtersChanged = view && view.get("filtersChanged");

    // create dropdown actions
    let dropDownButtonItems = [];
    if (filtersChanged) {
      // Check acces to catalog
      if (isViewAdmin) {
        dropDownButtonItems.push({
          icon: "transfers-48",
          text: t("buttons.save"),
          iconClassName: styles.settingIconBig,
          onClick: this.saveView
        });
      }

      dropDownButtonItems.push({
        // "save as" button, need to check acces right for prived views
        icon: "transfers-51",
        text: t("buttons.saveAs"),
        iconClassName: styles.settingIconBig,
        onClick: this.saveNewView
      });
    } else {
      // edit and remove
      if (view) {
        // if ((!view.get('isNew') && Number(view.get('id')) !== 0) && (isViewAdmin)) {
        if (isViewAdmin) {
          dropDownButtonItems = [
            {
              key: "renameView",
              icon: "edition-33",
              text: t("views.renameView"),
              onClick: this.renameView
            },
            {
              key: "setApiKey",
              icon: "edition-59",
              text: t("buttons.setApiKey"),
              onClick: this.openApiKeyModal
            },
            {
              key: "removeView",
              icon: "edition-41",
              text: t("buttons.removeView"),
              className: styles.settingRemove,
              onClick: this.removeView
            }
          ].concat(dropDownButtonItems);
        }
      }

      // access button if view for rights.
      if (view && view.get("forRights") && isViewAdmin) {
        dropDownButtonItems = [
          {
            icon: "edition-55",
            text: t("buttons.accessView"),
            onClick: this.accessView
          }
        ].concat(dropDownButtonItems);
      }
    }

    // create dropdown
    const dropdownMenu = dropDownButtonItems.map((item, i) => {
      return {
        key: i,
        label: (
          <a
            onClick={item.onClick}
            className={item.className ? item.className : null}
          >
            <Icon
              type={"icon " + item.icon}
              className={cn(styles.settingIcon, item.iconClassName || null)}
            />
            {item.text}
          </a>
        )
      };
    });

    // allow actions
    const allowActions = isNewView || dropdownMenu.length || filtersChanged;
    if (!allowActions) {
      return null;
    }
    console.log(isNewView);

    return (
      <Row className={className}>
        {!isWebForm &&
          (isNewView ? (
            <ButtonTransparent
              className={styles.saveButton}
              title={t("buttons.save")}
              onClick={this.saveNewView}
            >
              <span className={styles.saveText}>{t("buttons.save")}</span>
            </ButtonTransparent>
          ) : (
            <Dropdown
              menu={{ items: dropdownMenu }}
              trigger={["click"]}
              placement="bottomRight"
            >
              {filtersChanged ? (
                <ButtonTransparent
                  className={styles.saveButton}
                  title={t("buttons.save")}
                >
                  <span className={styles.saveText}>{t("buttons.save")}</span>
                </ButtonTransparent>
              ) : (
                <ButtonTransparent
                  className={styles.editButton}
                  title={t("views.actionsButton")}
                >
                  <Icon type="icon edition-37" className={styles.editIcon} />
                </ButtonTransparent>
              )}
            </Dropdown>
          ))}

        {filtersChanged && (
          <ButtonTransparent
            className={styles.restoreButton}
            title={t("buttons.abort")}
            onClick={this.resetView}
          >
            <Icon type="icon transfers-70" className={styles.restoreIcon} />
            <Icon
              type="icon interface-71"
              className={styles.restoreIconCross}
            />
          </ButtonTransparent>
        )}
      </Row>
    );
  }
}

export default withTranslation()(
  connect(
    withRouter(ViewActions),
    {
      scenes: ["scenes"],
      isWebForm: ["ui", "isWebForm"]
    },

    (props, { scenes, isWebForm }) => {
      const { sceneId, viewId } = props;
      /* сделано для добавления нового вида из сцены, так как в каталог новый вид не сохраяняется, а список видов отображается по видам каталога */
      const view = scenes.getIn([sceneId, "views", viewId]);
      return {
        isWebForm,
        view,
        ...props
      };
    }
  )
);

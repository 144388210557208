import _ from "lodash";

export const parseDadata = data => {
  const d = {
    value: data.value,
    provider: "dadata",
    data: _.pick(data.data, [
      "postal_code",
      "country",
      "region_with_type",
      "region_fias_id",
      "region_kladr_id",
      "area_kladr_id",
      "area_fias_id",
      "area_with_type",
      "city_fias_id",
      "city_kladr_id",
      "city",
      "city_with_type",
      "settlement_fias_id",
      "settlement_kladr_id",
      "settlement_with_type",
      "street_fias_id",
      "street_kladr_id",
      "street",
      "street_with_type",
      "house_fias_id",
      "house_kladr_id",
      "house_type",
      "house",
      "block",
      "block_type",
      "flat",
      "flat_type",
      "kladr_id",
      "fias_id",
      "geo_lat",
      "geo_lon"
    ])
  };
  return d;
};

export const prepareProperties = (field, properties, values) => {
  const isRequiredExcludeProperty =
    field.get("type") === "group" ||
    field.get("type") === "button" ||
    field.get("type") === "tab";

  if (isRequiredExcludeProperty) {
    properties = _.filter(properties, p => p.id !== "required");
    values = _.omit(values, "required");
  }
};

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from 'react-i18next';

import { formatDate, formatTime } from "../../../utils/formatDate";

import styles from "./dataTypes.less";

const DateField = props => {
  const { i18n: { language } } = useTranslation();
  
  moment.locale(language);
  let date = moment(new Date(props.value));

  const withTime = !!props.config.get("time");

  if (props.value) {
    return (
      <span className={cn(props.containerClassName, styles.simpleField)}>
        {formatDate(date, withTime)}
        &nbsp;
        {
          <span className={styles.extraData}>
            {withTime
              ? formatTime(date)
              : date.format("dd").toUpperCase()}
          </span>
        }
      </span>
    );
  } else {
    return null;
  }
};

DateField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  config: PropTypes.object
};

export default DateField;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu__container___6539D {\n  overflow: hidden;\n  height: 47px;\n}\n.menu__menuVertical___wgLIX {\n  width: 100%;\n}\n.menu__menuVertical___wgLIX .ant-tabs-nav {\n  width: 100%;\n}\n.menu__menuVertical___wgLIX .ant-tabs-tab,\n.menu__menuVertical___wgLIX .ant-tabs-tab-btn {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/UI/Menu/menu.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,YAAA;AADF;AASA;EACE,WAAA;AAPF;AAQE;EAEI,WAAA;AAPN;AAKE;;EAMI,WAAA;AAPN","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.container {\n  overflow: hidden;\n  height: @HEADER_BASE;\n}\n\n.menu {\n}\n\n.menuHorizontal {}\n\n.menuVertical {\n  width: 100%;\n  & :global {\n    .ant-tabs-nav {\n      width: 100%;\n    }\n    .ant-tabs-tab,\n    .ant-tabs-tab-btn {\n      width: 100%;\n\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "menu__container___6539D",
	"menuVertical": "menu__menuVertical___wgLIX"
};
export default ___CSS_LOADER_EXPORT___;

export const defineLink = (text) => {
    let messageText = text && text.trim();

    const returnedObj = {
        isLink: false,
        textWithLink: ""
    };

    if (messageText) {
        messageText = text.replace(/https?:\/\/[^\s]+/, (url) => {
            returnedObj.isLink = true;
            return `<a href="${url}" target="_blank">${url}</a>`;
        });
        returnedObj.textWithLink = messageText;
    };
    return returnedObj;
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserAvatarMenu__containerAvatar___vpDuA {\n  background: rgba(255, 255, 255, 0.1);\n  border-radius: 20px;\n  cursor: pointer;\n}\n.UserAvatarMenu__arrow___NGj3e {\n  font-size: 11px;\n  color: #e7e9eb;\n  padding-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppHeader/UserMenu/UserAvatar/UserAvatarMenu.less"],"names":[],"mappings":"AAEA;EACI,oCAAA;EACA,mBAAA;EACA,eAAA;AADJ;AAIA;EACI,eAAA;EACA,cAAA;EACA,mBAAA;AAFJ","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\n.containerAvatar {\n    background: rgba(255, 255, 255, 0.1);\n    border-radius: @PADDING_BASE * 2;\n    cursor: pointer;\n}\n\n.arrow {\n    font-size: 11px;\n    color: @BG_PANELS;\n    padding-right: @PADDING_BASE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerAvatar": "UserAvatarMenu__containerAvatar___vpDuA",
	"arrow": "UserAvatarMenu__arrow___NGj3e"
};
export default ___CSS_LOADER_EXPORT___;

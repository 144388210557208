import React from "react";
import _ from "lodash";
import Immutable from "immutable";
import PropTypes from "prop-types";
import { Modal, Button, Row } from "antd";
import { withTranslation } from "react-i18next";

import { connect } from "../StateProvider";
import RightsFields from "./rights/RightsFields";
import HelpIcon from "../common/HelpIcon";
import ButtonClose from "../common/UI/ButtonClose";
import HelperToggler from "../common/widgets/Helper/HelperToggler";

import styles from "./access.less";

const log = require("debug")("CRM:Component:FieldRights:Modal");

const PolicyFieldsHelpIcon = connect(
  HelpIcon,
  {
    helpPath: ["vendor", "docsPolicyFieldsPath"]
  }
);

class FieldRightsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fields: new Immutable.Map(),
      index: null
    };
  }

  onSave = () => {
    this.props.onOk(this.state.fields);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rule) {
      this.setState({
        fields: nextProps.rule.get("fields") || new Immutable.Map()
      });
    }
  }
  componentDidMount() {
    this.setState({
      fields: this.props.rule.get("fields") || new Immutable.Map()
    });
  }
  onSaveField = (fieldId, privilege) => {
    log({ fieldId, privilege });
    let fields = this.state.fields || new Immutable.Map();

    fields = privilege
      ? fields.set(fieldId, privilege)
      : fields["delete"](fieldId);

    this.setState({ fields });
  };

  render() {
    const { t } = this.props;
    const readOnly = this.props.readOnly;
    const fieldsValues = this.state.fields ? this.state.fields.toJS() : {};
    return (
      <Modal
        open={true}
        maskClosable={false}
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            disabled={readOnly}
            onClick={this.onSave}
          >
            {t("modals.save")}
          </Button>,
          <Button key="back" type="default" onClick={this.props.onCancel}>
            {readOnly ? t("modals.close") : t("modals.cancel")}
          </Button>
        ]}
        width="60%"
      >
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={styles.headerModal}
        >
          <Row type="flex" align="middle">
            <h2 className={styles.headerModalTitle}>
              {t("modals.fieldRights.header")}
            </h2>
            <HelperToggler href="https://docs.bpium.ru/manual/rights/fields" />
          </Row>
          <ButtonClose large shiftRight onClick={this.props.onCancel} />
        </Row>
        <div className={styles.fieldRightsModalBody}>
          <RightsFields
            catalogId={this.props.catalogId}
            object={this.props.object}
            subjectPrivilege={this.props.subjectPrivilege}
            fields={this.props.fields}
            values={fieldsValues}
            onSaveField={this.onSaveField}
            basePrivilege={this.props.basePrivilege}
          />
        </div>
      </Modal>
    );
  }
}

FieldRightsModal.defaultProps = {
  rules: new Immutable.Map(),
  index: null,
  basePrivilege: ""
};

const FieldRightsModalController = props => {
  // TODO React.memo
  return (
    <FieldRightsModal
      t={props.t}
      rule={props.rule}
      index={props.index}
      object={props.object}
      subjectPrivilege={props.subjectPrivilege}
      fields={props.fields}
      catalogId={props.catalogId}
      basePrivilege={props.basePrivilege}
      onCancel={props.onCancel}
      onOk={props.onOk}
    />
  );
};

FieldRightsModal.propTypes = {
  catalogId: PropTypes.string,
  object: PropTypes.object,
  fields: PropTypes.object,
  onCancel: PropTypes.func,
  onOk: PropTypes.func
};

FieldRightsModalController.propTypes = {
  catalogId: PropTypes.string,
  object: PropTypes.object,
  fields: PropTypes.object,
  parents: PropTypes.array
};

export default withTranslation()(FieldRightsModalController);

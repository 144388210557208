import { useState, useEffect, useRef } from "react";
import Immutable from "immutable";

import recordActions from "../../../../../actions/recordActions";

const useLinkedItems = (config, linkedItems) => {
  const [items, setItems] = useState(Immutable.List());
  const prevLinkedItems = useRef(Immutable.List());

  const restoringIsDisabled = item =>
    !item ||
    !item.get("canRestore") ||
    (!config.get("multiselect") && items.some(item => !item.get("canRestore")));

  const araryItemsToImmutable = array => {
    return array.map(i => Immutable.fromJS(i));
  };

  const setRemovedItem = keyName => {
    const index = items.findIndex(item => item.get("key") === keyName);

    if (!items.getIn([index, "item", "isRemoved"])) {
      setItems(items.setIn([index, "canRestore"], true));
    }
  };

  const addItem = newItem => {
    const multiselect = config.get("multiselect");
    if (linkedItems.some(item => item.key === newItem.get("key"))) {
      return;
    }

    if (!multiselect && items.some(item => !item.get("canRestore"))) {
      return;
    }

    const newItems = items.push(newItem);

    return newItems.filter(newItem => !newItem.get("canRestore")).toJS();
  };

  const deleteRestoreItem = keyName => {
    const index = items.findIndex(item => item.get("key") === keyName);
    const [catalogId, recordId] = keyName.split(":");

    recordActions.clearRecord(catalogId, recordId);

    setItems(items.delete(index));
  };

  const removeItem = keyName => {
    setRemovedItem(keyName);
    const index = linkedItems.findIndex(item => item.key === keyName);
    return linkedItems.delete(index).toJS();
  };

  const restoreItem = keyName => {
    const index = items.findIndex(item => item.get("key") === keyName);

    if (restoringIsDisabled(items.get(index))) {
      return;
    }

    let newItems = items.setIn([index, "canRestore"], false);

    setItems(newItems);

    return newItems.filter(item => !item.get("canRestore")).toJS();
  };

  useEffect(() => {
    if (Immutable.is(prevLinkedItems.current, linkedItems)) return;

    let newItems = araryItemsToImmutable(linkedItems);

    items.forEach((item, index) => {
      const isRemoved = item.get("canRestore");
      const existInLinkedItems = linkedItems.some(linkedItem => {
        return linkedItem.key === item.get("key");
      });

      if (isRemoved && !existInLinkedItems) {
        newItems = newItems.insert(index, item);
      }
    });

    setItems(newItems);
    prevLinkedItems.current = linkedItems;
  });

  return { items, removeItem, addItem, restoreItem, deleteRestoreItem };
};

export default useLinkedItems;

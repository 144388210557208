// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__wideoWrapper___MirOU {\n  width: 100%;\n}\n.styles__wideoWrapper___MirOU video {\n  line-height: 1;\n  display: block;\n}\n.styles__modal___1q4J\\+ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Modal/VideoViewer/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAFA;EAII,cAAA;EACA,cAAA;AACJ;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADF","sourcesContent":[".wideoWrapper {\n  width: 100%;\n\n  video {\n    line-height: 1;\n    display: block;\n  }\n}\n\n.modal {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wideoWrapper": "styles__wideoWrapper___MirOU",
	"modal": "styles__modal___1q4J+"
};
export default ___CSS_LOADER_EXPORT___;

/**
 *
 * @returns {string}
 */

import qs from "qs";
import _ from "lodash";

const redirectWithBackUrl = path => {
  if (window.location.href.indexOf("back_url") === -1) {
    let { pathname, search, hash } = window.location;

    // Регулярное выражение для поиска логина и пароля в URL, иначе qs обрезает символы пароля
    const loginRegex = /login=([^&]+)/;
    const passwordRegex = /password=([^&]+)/;

    // Используем метод match для поиска совпадений с регулярными выражениями
    const loginMatch = search.match(loginRegex);
    const passwordMatch = search.match(passwordRegex);

    // remove login & password to prevent recursion
    const { login, password, ...otherSearch } = qs.parse(search);
    search = qs.stringify(otherSearch);

    // create backurl to return after login
    let backUrl = pathname + search + hash;
    backUrl = encodeURIComponent(backUrl);

    const loginPath = "/#/auth/login"

    if (loginMatch && passwordMatch) {
      // get user data from expression
      const userLogin = loginMatch[1];
      const userPassword = passwordMatch[1];

      window.location.href =
        loginPath +
        "?back_url=" +
        backUrl +
        `&login=${userLogin}&password=${userPassword}`;

    } else if (!_.isEmpty(login) && !_.isEmpty(password)) {
      window.location.href =
        loginPath +
        "?back_url=" +
        backUrl +
        `&login=${login}&password=${password}`;
    } else {
      window.location.href = path + "?back_url=" + backUrl;
    }
  } else {
    window.location.href = path;
  }
};

export default {
  logout() {
    window.location.href = "/auth/logout";
  },

  authorize() {
    if (window.location.href.indexOf("auth/login") > -1) {
      return;
    }

    redirectWithBackUrl("/#/auth/companies");
  },

  login() {
    redirectWithBackUrl("/#/auth/login");
  },

  paymentRequired() {
    redirectWithBackUrl("/#/auth/payment/required");
  }
};

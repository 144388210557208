import LazyLoad from "lazyload-js";

export default {
  startDragging() {
    if (this.get("dragging")) {
      return;
    }
    this.set("dragging", true);
    this.changed();
  },
  stopDragging() {
    if (!this.get("dragging")) {
      return;
    }
    this.set("dragging", false);
    this.changed();
  },

  loadExtensions() {
    let extensions = this.getIn(["company", "extensions"]);

    let changed = false;
    // initializing new extensions
    extensions = extensions.map(m => {
      if (!m.get("loaded")) {
        m.get("css") && LazyLoad.css(m.get("css"));
        m.get("js") && LazyLoad.js(m.get("js"));
        m = m.setIn(["loaded"], true);
        changed = true;
      }
      return m;
    });

    if (changed) {
      this.setIn(["company", "extensions"], extensions);
      this.changed();
    }
  }
};

import _ from "lodash";

function containsOnlyDigits(str) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export const validateApiKey = (apiKey, t, oldValue = null) => {
  if (oldValue && _.isEqual(oldValue, apiKey)) {
    return (
      t("modals.setApiKeyErrors.sameValue") ||
      "Предыдущее значение не может быть таким же."
    );
  }

  // Значение может быть пустым и это нормально, в такой ситуации мы просто удаляем id из recordIds
  if (!apiKey) {
    return ""
  }

  if (containsOnlyDigits(apiKey) || !_.isString(apiKey)) {
    return (
      t("modals.setApiKeyErrors.notString") ||
      "Разрешены только строковые значения."
    );
  }

  if (_.startsWith(apiKey, "$")) {
    return (
      t("modals.setApiKeyErrors.startWithDollar") ||
      "Служебные ID (начинаются с $) изменять нельзя."
    );
  }

  // We allow only letters, numbers and separation symbols: hyphen, underscore
  const validCharactersRegex = /^[a-zA-Z0-9_-]+$/;

  // We check whether the key matches the allowed characters
  if (!validCharactersRegex.test(apiKey)) {
    return (
      t("modals.setApiKeyErrors.containUncorrectSymbol") ||
      "Разрешены только буквы, цифры и следующие символы разделения: дефис (-) и подчёркивание (_)."
    );
  }

  return "";
};

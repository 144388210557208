import React from "react";
import _ from "lodash";
import $ from "jquery";
import "fotorama/fotorama";
import { Row } from "antd";

import ButtonClose from "../../../../../ButtonClose";
// import trs from "../../../../../../../../getTranslations";
import AntImage from "./AntImage";
import i18n from "../../../../../../../../configs/i18n";

import styles from "../../../controls.less";
import imageStyles from "./image.less";
import { antImageViewer } from "./AntImage";

class ImageCaption extends React.PureComponent {
  toMb = value => {
    var resVal = (Number(value) / 1000 / 1000).toFixed(1);
    if (resVal == "0.0") resVal = "0.1";
    return resVal + " " + i18n.t("record.fields.file.size");
  };

  render() {
    let file = this.props.file;
    return (
      <Row type="flex" align="middle" justify="space-between">
        <a
          href={file.url}
          className={imageStyles.fileImageViewerLink}
          title={i18n.t("record.fields.file.download")}
          target="_blank"
        >
          {file.title}
        </a>

        <span className={styles.fileSize}>{this.toMb(file.size)}</span>

        {!this.props.readOnly && (
          <ButtonClose
            title={i18n.t("record.fields.file.remove")}
            onClick={this.props.onRemove}
            className={imageStyles.fileImageRemoveBtn}
            small
          />
        )}
      </Row>
    );
  }
}

class ImageViewer extends React.PureComponent {
  static viewerName = "ImageViewer";
  static isMyFile(file) {
    const maxAllowedFileSize = 3 * 1000000;
    const isAllowedFileSize = file && file.size < maxAllowedFileSize;

    return isAllowedFileSize;
  }

  static priority = 1;

  constructor(props) {
    super(props);
    /* стейт нужен для смены вызова ререндера */
    this.state = {
      currentFile: false
    };

    this.refFotorama = React.createRef(null);
  }

  componentDidMount() {
    this.initFotorama(this.props.files || []);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.files, this.props.files)) {
      this.updateFotorama(this.props.files);
    }
  }

  onRemove = (file) => {
    this.props.removeFn && this.props.removeFn(file);
  };

  initFotorama = files => {
    const images = this.parseImages(files);

    $(this.refFotorama.current)
      /* при смене изображений запоминаем текущее */
      .on("fotorama:show", (e, fotorama) => {
        this.setState({ currentFile: this.props.files[fotorama.activeIndex] });
      })
      .on("fotorama:ready", (e, fotorama) => {
        /* после рендера фоторамы, запоминаем активный элемент (по дефолту первый) */
        setTimeout(() => {
          if (this.props.files.length > 0 && !this.state.currentFile) {
            this.setState({
              currentFile: this.props.files[0]
            });
          }
        });
      })
      .fotorama({
        data: images,
        ...this.props.options
      });
  };

  updateFotorama = files => {
    const api = $(this.refFotorama.current).data("fotorama");

    // fotorama plugin inits async, so update can come before fotorama is ready
    if (!api) {
      this.initFotorama(files);
      return;
    }

    const switchToLast = api.data && api.data.length < files.length;

    const images = this.parseImages(files);
    api.load(images);

    //Выделение последнего файла при добавлении нового изображения
    switchToLast && api.show(">>");
  };

  parseImages = files => {
    return _.map(files, file => {
      let image = {
        id: file.id,
        temporaryId: file.temporaryId,
        img: file.url || file.src,
        size: file.size,
        title: file.title
      };
      if (file.metadata && file.metadata.preview && file.metadata.thumbnail) {
        image = {
          ...image,
          full: file.url || file.src,
          img: file.metadata.preview,
          thumb: file.metadata.thumbnail
        };
      }
      return image;
    });
  };

  render() {
    if (!this.props.files) {
      return null;
    }

    if (this.props.componentType == antImageViewer) {
      return (
        <AntImage
          files={this.props.files}
          readOnly={this.props.readOnly}
          width={this.props.width}
          height={this.props.height}
          calcSizes={this.props.calcSizes}
          containerClassName={this.props.containerClassName}
          itemWrapperClassName={this.props.itemWrapperClassName}
          itemClassName={this.props.itemСlassName}
          onRemove={this.onRemove}
        />
      );
    }

    return (
      <div className={imageStyles.fileImageViewer}>
        <div
          ref={this.refFotorama}
          className={imageStyles.fotorama}
          data-min-width="100%"
          data-max-width="100%"
          data-height="300px"
          data-auto="false"
          data-fit="scaledown"
          data-align="center"
        />
        {this.state.currentFile && (
          <ImageCaption
            file={this.state.currentFile}
            readOnly={this.props.readOnly}
            onRemove={()=>this.onRemove(this.state.currentFile)}
          />
        )}
      </div>
    );
  }
}

ImageViewer.defaultProps = {
  files: [],
  options: {
    nav: "thumbs",
    navposition: "top",
    loop: true,
    allowFullScreen: true
  }
};

export default ImageViewer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__footer___SUUmH .styles__footerCell___\\+UMjh {\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/UI/RecordsTable/FooterCell/styles.less"],"names":[],"mappings":"AAEA;EAEI,WAAA;EACA,YAAA;AAFJ","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\n.footer {\n  .footerCell {\n    width: 100%;\n    height: 100%;\n    // :global {\n    //   .public_fixedDataTableCell_cellContent {\n    //     padding-left: @PADDING_BASE * 1.5;\n    //   }\n    // }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "styles__footer___SUUmH",
	"footerCell": "styles__footerCell___+UMjh"
};
export default ___CSS_LOADER_EXPORT___;

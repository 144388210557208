import availableLinkedRecordActions from "../../../../../actions/availableLinkedRecordActions";
import Immutable from "immutable";

function outMapper({ key, text, alwaysVisible, sort }) {
  return Immutable.fromJS({
    id: key,
    title: text,
    alwaysVisible: alwaysVisible,
    sort: ~~sort
  });
}

function config({ field, value }) {
  if (value) {
    value = value.map(item => {
      if (item.toJS) {
        item = item.toJS();
      }
      return {
        id: item.id,
        key: item.id,
        text: item.title,
        icon: "users-1",
        alwaysVisible: item.alwaysVisible,
        sort: ~~item.sort
      };
    });
  }

  field = field.set("loadAvailableItems", (...args) => {
    availableLinkedRecordActions.loadAvailableItems(...args);
  });

  field = field.set("clearAvailableItems", (...args) => {
    availableLinkedRecordActions.clearAvailableItems(...args);
  });

  field = field.set("elementsRemoteGroup", "users");

  return { field, value };
}

function onChange(values) {
  values = values.map(outMapper);
  return Immutable.fromJS(values);
}

function onEndEditing(values) {
  values = values.map(outMapper);
  return Immutable.fromJS(values);
}

export default {
  config,
  onChange,
  onEndEditing
};

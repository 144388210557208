import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import LoadingSpin from "../../LoadingSpin";

import styles from "./process.less";

export default props => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(styles.updateProcess, {
        [styles.updateProcessShow]: props.show
      })}
    >
      <LoadingSpin /> {props.text || t("loadingText")}
    </div>
  );
};

export default t => ({
  element: "bpmn:ExclusiveGateway",
  title: t("script.controlls.exclusiveGateway.title"),
  helplink: "",
  class: "gateway",
  color: "orange",
  border: 2,
  icon: "anticon-icon interface-72",
  icon_rotate: 45,
  offset_x: -24,
  offset_y: -20,
  toolbar_offset_x: 8,
  toolbar_offset_y: -42,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t(
          "script.controlls.exclusiveGateway.defaultEmptyValue"
        ),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    }
  ],
  exits: []
});

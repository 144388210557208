import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Row } from "antd";

import routes from "../../../../../../../../routes";

import NavRoute from "../../../../../../../common/router/Route";
import { connect } from "../../../../../../../StateProvider";

import styles from "./viewBody.less";
import _ from "lodash";
import Loading from "../../../../../../../common/Loading";

// workaround: https://react.dev/reference/react/lazy
// cause bundle it has a lot of weight

const Records = lazy(() => import("../../../../../../../Records"));
const Cards = lazy(() => import("../../../../../../../Cards"));
const Calendar = lazy(() => import("../../../../../../../Calendar"));
const Reports = lazy(() => import("../../../../../../../Reports"));
const History = lazy(() => import("../../../../../../../History"));
const PivotTables = lazy(() => import("../../../../../../../PivotTables"));
const StatisticsData = lazy(() =>
  import("../../../../../../../../components/Statistics")
);

class ViewBody extends Component {
  static propTypes = {
    catalog: PropTypes.object,
    catalogId: PropTypes.string,
    scene: PropTypes.object,
    sceneId: PropTypes.string,
    viewId: PropTypes.string
  };

  render() {
    const {
      catalog,
      catalogId,
      scene,
      sceneId,
      viewId,
      openRecordsInModal,
      isWebForm,
      getFieldsToRender
    } = this.props;
    return (
      <Row className={styles.container}>
        
        {/*
        // TODO #catalogsOnTop, delete code & components
        
        <Suspense fallback={<Loading />}>
          <ViewsPanel
            sceneId={sceneId}
            catalogId={catalogId}
            viewId={viewId}
            catalog={catalog}
            isWebForm={isWebForm}
            openRecordsInModal={openRecordsInModal}
          />
    </Suspense>*/}

        <NavRoute
          route={routes.records}
          render={props => {
            return scene && catalog ? (
              <Suspense fallback={<Loading />}>
                <Records
                  sceneId={sceneId}
                  catalogId={catalogId}
                  viewId={viewId}
                  scene={scene}
                  catalog={catalog}
                  isWebForm={isWebForm}
                  openRecordsInModal={openRecordsInModal}
                />
              </Suspense>
            ) : null;
          }}
        />
        <NavRoute
          route={routes.cards}
          render={props => {
            return scene && catalog ? (
              <Suspense fallback={<Loading />}>
                <Cards
                  sceneId={sceneId}
                  catalogId={catalogId}
                  viewId={viewId}
                  scene={scene}
                  catalog={catalog}
                  isWebForm={isWebForm}
                  openRecordsInModal={openRecordsInModal}
                />
              </Suspense>
            ) : null;
          }}
        />
        <NavRoute
          route={routes.calendar}
          render={props => {
            return scene && catalog ? (
              <Suspense fallback={<Loading />}>
                <Calendar
                  sceneId={sceneId}
                  catalogId={catalogId}
                  viewId={viewId}
                  scene={scene}
                  catalog={catalog}
                  isWebForm={isWebForm}
                  openRecordsInModal={openRecordsInModal}
                />
              </Suspense>
            ) : null;
          }}
        />
        <NavRoute
          route={routes.reports}
          render={props => {
            return catalog ? (
              <Suspense fallback={<Loading />}>
                <Reports
                  sceneId={sceneId}
                  catalogId={catalogId}
                  viewId={viewId}
                  scene={scene}
                  catalog={catalog}
                  isWebForm={isWebForm}
                  openRecordsInModal={openRecordsInModal}
                  colsMode={this.props.colsMode}
                />
              </Suspense>
            ) : null;
          }}
        />
        <NavRoute
          route={routes.history}
          render={props => {
            return scene && catalog ? (
              <Suspense fallback={<Loading />}>
                <History
                  sceneId={sceneId}
                  catalogId={catalogId}
                  viewId={viewId}
                  scene={scene}
                  catalog={catalog}
                  isWebForm={isWebForm}
                  openRecordsInModal={openRecordsInModal}
                  getFieldsToRender={getFieldsToRender}
                />
              </Suspense>
            ) : null;
          }}
        />
        <NavRoute
          route={routes.count}
          render={props => {
            return scene && catalog ? (
              <Suspense fallback={<Loading />}>
                <StatisticsData
                  sceneId={sceneId}
                  catalogId={catalogId}
                  viewId={viewId}
                  scene={scene}
                  catalog={catalog}
                  isWebForm={isWebForm}
                  openRecordsInModal={openRecordsInModal}
                />
              </Suspense>
            ) : null;
          }}
        />
        <NavRoute
          route={routes.pivot}
          render={props => {
            return scene && catalog ? (
              <Suspense fallback={<Loading />}>
                <PivotTables
                  catalog={catalog}
                  catalogId={catalogId}
                  sceneId={sceneId}
                  scene={scene}
                  viewId={viewId}
                />
              </Suspense>
            ) : null;
          }}
        />
      </Row>
    );
  }
}

export default connect(
  ViewBody,
  {
    scenes: ["scenes"],
    catalogs: ["catalogs"],
    userSettings: ["userSettings", "catalogs"]
  },
  (props, { scenes, catalogs, userSettings }) => {
    const scene = props.sceneId && scenes.get(props.sceneId);
    const catalog = props.catalogId && catalogs.get(props.catalogId);
    const sortField = userSettings.getIn([
      props.catalogId,
      "viewMode",
      "table",
      "sortingRecords",
      "sortField"
    ]);
    const sortType = userSettings.getIn([
      props.catalogId,
      "viewMode",
      "table",
      "sortingRecords",
      "sortType"
    ]);

    return {
      catalog,
      scene,
      sortType,
      sortField,
      ...props
    };
  }
);

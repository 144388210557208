import React, { Component } from "react";
import _ from "lodash";
import { DragSource, DropTarget } from "react-dnd";
import { Checkbox } from "antd";
import cn from "classnames";

import Icon from '../UI/Icon';
import dndTargets from "../../../configs/dndTargets";
import dragAndDropActions from "../../../actions/dragAndDropActions";

import styles from "./fieldConfig.less";

const dragSource = DragSource(
  dndTargets.TABLE_FIELD,
  {
    beginDrag(props, monitor, component) {
      let item = { id: component.props.field.get("id") };
      dragAndDropActions.beginDrag(dndTargets.TABLE_FIELD, item);
      // component.props.onDragStart(component.props.field.get('id'));
      return item;
    },
    endDrag(props, monitor, component) {
      dragAndDropActions.endDrag();
      component.props.onDragEnd(component.props.field.get("id"));
    }
  },
  function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    };
  }
);

const dropTarget = DropTarget(
  dndTargets.TABLE_FIELD,
  {
    hover(props, monitor) {
      let item = monitor.getItem();
      props.moveItem(item.id, props.field.get("id"));
    }
  },
  function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
);

class FieldConfigItem extends Component {
  render() {
    const {
      connectDropTarget,
      connectDragSource,
      field,
      onChange,
      visible,
      viewMode
    } = this.props;
    const isHistory = viewMode === "history";
    const content = (
      <li className={cn("ant-dropdown-menu-item", styles.menuItem)}>
        {!isHistory && (
          <Icon type="icon interface-42" className={styles.dragIcon} />
        )}
        <Checkbox
          onChange={onChange}
          checked={visible}
          className={cn("inline", styles.label)}
        >
          <label title={field.get("name")}>{field.get("name")}</label>
        </Checkbox>
      </li>
    );
    return !isHistory
      ? _.flow(
          connectDropTarget,
          connectDragSource
        )(content)
      : content;
  }
}

export default _.flow(
  dropTarget,
  dragSource
)(FieldConfigItem);

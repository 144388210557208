import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import styles from "./badge.less";

const Badge = ({ children, dot, count, className }) => {
  const [blink, setBlink] = useState(false);
  const [isCount, setIsCount] = useState(false);
  const prevCount = useRef(count);

  useEffect(
    () => {
      if (count && count !== 0) {
        if (prevCount.current < count) {
          setBlink(true);
          setTimeout(() => {
            setBlink(false);
          }, 3000);
        }
        setIsCount(true);
      } else {
        setIsCount(false);
      }
      prevCount.current = count;
    },
    [count]
  );

  return (
    <span
      className={classNames(styles.badge, {
        [styles.badgeBlink]: blink,
        [className]: !!className && isCount,
        [styles.badgeDot]: dot
      })}
      data-badge={count && !dot ? Math.min(99, Math.max(0, count)) : ""}
    >
      {children}
    </span>
  );
};

export default Badge;

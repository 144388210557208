import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import cn from "classnames";
import { DatePicker, TimePicker, Input } from "antd";
import { withTranslation } from "react-i18next";

import styles from "./controls.less";

const log = require("debug")("CRM:Component:Record:DateField");
const API_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
const API_FORMAT_DATE = "YYYY-MM-DD";
const API_FORMAT_TIME = "HH:mm:ssZ";
const MOMENT_DATE_FORMAT = "DD.MM.YYYY";
const MOMENT_TIME_FORMAT = "HH:mm";

const DateControl = props => {
  const {
    value,
    editable,
    config,
    onChange,
    onEndEditing,
    autoFocus,
    fieldId,
    t
  } = props;
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  useEffect(
    () => {
      if (value) {
        setDate(moment(value).format(API_FORMAT_DATE));
        setTime(moment(value).format(API_FORMAT_TIME));
      }
    },
    [value]
  );

  const onChangeHandler = (picker, val) => {
    if (picker === "date" && val) {
      setDate(val.format(API_FORMAT_DATE));
      if (time) {
        val = val.format(API_FORMAT_DATE) + "T" + time;
      }
    } else if (picker === "date" && val === null) {
      setDate("");
      val = null;
    }

    if (picker === "time" && val) {
      setTime(val.format(API_FORMAT_TIME));
      if (date) {
        val = date + "T" + val.format(API_FORMAT_TIME);
      }
    } else if (picker === "time" && val === null) {
      val = date + "T00:00:00";
      setTime("");
    }

    val = val !== null ? moment(val).format(API_FORMAT) : val;

    log("Changed field %s value", fieldId, val);
    onChange && onChange(val);
    onEndEditing && onEndEditing(val);
  };

  const selectTime = value => {
    const val = moment(value).format(API_FORMAT);
    setTime(value.format(API_FORMAT_TIME));
    onChange && onChange(val);
    onEndEditing && onEndEditing(val);
  };

  const formattedDateValue = moment(value || "").isValid()
    ? moment(value).format("L")
    : value;

  const dateComponent = !editable ? (
    <Input
      readOnly={true}
      value={formattedDateValue}
      className={styles.datePickerReadOnly}
    />
  ) : (
    <DatePicker
      autoFocus={autoFocus}
      value={value ? moment(value) : null}
      format={MOMENT_DATE_FORMAT}
      className={styles.datePicker}
      onChange={val => onChangeHandler("date", val)}
      placeholder={t("fieldTypes.date.name")}
    />
  );

  let timeComponent;
  if (config.get("time")) {
    const formattedTimeValue = moment(value || "").isValid()
      ? moment(value).format("LTS")
      : value;
    timeComponent = !editable ? (
      <Input
        readOnly={true}
        value={formattedTimeValue}
        className={styles.timePickerReadOnly}
      />
    ) : (
      <TimePicker
        hideDisabledOptions={true}
        value={value ? moment(value) : null}
        className={styles.timePicker}
        popupClassName={styles.timePickerPopup}
        format={MOMENT_TIME_FORMAT}
        onChange={val => onChangeHandler("time", val)}
        placeholder={t("fieldTypes.time.name")}
        onSelect={selectTime}
      />
    );
  }
  let pasteTime = moment(value, API_FORMAT).fromNow();
  if (!config.get("time") && formattedDateValue === moment().format("L")) {
    pasteTime = "";
  }

  return (
    <div className={styles.dateContainer}>
      {dateComponent}
      {timeComponent}
      {value && (
        <span className={cn(styles.oneLine, styles.subInfo)} title={pasteTime}>
          {pasteTime}
        </span>
      )}
    </div>
  );
};

DateControl.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool
};

export default withTranslation()(DateControl);

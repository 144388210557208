export function set(key, value) {
  window.localStorage[key] = JSON.stringify(value);
}

export function get(key, dafault) {
  try {
    const value = JSON.parse(window.localStorage[key]);
    if (value !== undefined) {
      return value;
    } else {
      return dafault;
    }
  } catch (e) {
    return dafault;
  }
}

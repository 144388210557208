import React from "react";
import ReactPlayer from "react-player";
import { Carousel } from "antd";
import Icon from "../../../../../Icon";
import styles from "./video.less";
import cn from "classnames";
import _ from "lodash"

const VideoViewer = ({ files, readOnly }) => {

  // by default a half of full volume
  const defaultVolume = 0.5;

  return (
    <div className={cn(styles.videoContainer)}>
      {files.length > 0 && (
        <Carousel>
          {files.map((file, index) => (
            <div key={index} className={cn(styles.videoItem)}>
              <div className={cn(styles.videoTitle)}>
                <Icon type="icon arrows-triangle-big-circle-4-01" />
                <div className={cn(styles.videoFileName)}>{file.title}</div>
              </div>
              <ReactPlayer
                className={cn(styles.videoPlayer)}
                volume={defaultVolume}
                controls={!readOnly}
                url={file.url}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

VideoViewer.viewerName = "VideoViewer";
VideoViewer.priority = 4;

export default VideoViewer;

const idsMap = {
  "1": "$catalogs",
  "2": "$sections",
  "3": "$users"
};

export const getSystemIds = id => {
  return idsMap[id] || id;
};

// TODO: in future we must remove this code

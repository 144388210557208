import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import _ from "lodash";
import { Route } from "react-router";
import { withTranslation } from "react-i18next";
import HashMemoryRouter from "../../common/router/HashMemoryRouter";
import getConfirmation from "../../common/Modal/getConfirmation";
import getLink from "../../common/router/getLink";
import routes from "../../../routes";

import ImportDataComponent from "./ImportDataComponent";

class Import extends React.Component {
  state = {
    openModal: false
  };

  // LIFECYCLE
  componentDidMount() {
    this.setState({ openModal: true });
  }
  render() {
    const { catalogId, sectionId } = this.props;
    let { t } = this.props;

    return (
      <HashMemoryRouter
        initialEntries={[
          getLink(null, routes.import, { sectionId, catalogId }).pathname
        ]}
        initialIndex={0}
        getUserConfirmation={(message, callback, state) => {
          getConfirmation(
            message,
            callback,
            state,
            {
              headerText: t("import.closeEditor.headerText"),
              text: t("import.closeEditor.text"),
              okText: t("import.closeEditor.okText"),
              cancelText: t("import.closeEditor.cancelText")
            },
            this.props.t
          );
        }}
      >
        <Route
          path={routes.import.path}
          render={() => {
            return (
              <ImportDataComponent
                {...this.props}
              />
            );
          }}
        />
      </HashMemoryRouter>
    );
  }
}

/* поправить */
Import.propTypes = {
  catalogId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  sceneId: PropTypes.string,
  file: PropTypes.object.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  columns: ImmutablePropTypes.list,
  records: ImmutablePropTypes.map
};

export default withTranslation()(Import);

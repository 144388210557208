export default t => ({
  hidden: false,
  element: "bpmn:ServiceTask",
  service: "generateDoc",
  title: t("script.controlls.generateDoc.title"),
  helplink: "",
  class: "bpium",
  color: "#7cab9e",
  border: 2,
  icon: "anticon-icon files-47",
  offset_x: -2,
  offset_y: -15,
  toolbar_offset_y: -10,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.generateDoc.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "section",
      name: t("script.controlls.generateDoc.template"),
      type: "group"
    },
    {
      id: "template_format",
      name: t("script.controlls.generateDoc.templatePath"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "catalog",
            name: t("script.common.formatCatalog"),
            color: "E7E9ED"
          },
          {
            id: "url",
            name: t("script.common.formatUrl"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["catalog"],
        map: "input"
      }
    },
    {
      id: "template_catalogId",
      name: t("script.common.catalog"),
      type: "radiobutton",
      readOnly: false,
      config: {
        items: [],
        type: "select",
        data: "catalogs",
        map: "input"
      },
      visible: {
        template_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "template_recordId",
      name: t("script.common.recordId"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.numberExpression")
      },
      visible: {
        template_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "template_fieldId",
      name: t("script.common.field"),
      type: "radiobutton",
      readOnly: false,
      config: {
        items: [],
        type: "select",
        data: "fields",
        subtypes: ["file"],
        parent: "template_catalogId",
        map: "input"
      },
      configOld: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.numberExpression")
      },
      visible: {
        template_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "template_url",
      name: t("script.controlls.generateDoc.templateUrl"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.generateDoc.templateHint"),
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      },
      visible: {
        template_format: {
          $include: "url"
        }
      }
    },
    {
      id: "section_data",
      name: t("script.common.data"),
      type: "group"
    },
    {
      id: "data_format",
      name: t("script.common.valueFormat"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "keyvalue",
            name: t("script.common.keyValue"),
            color: "E7E9ED"
          },
          {
            id: "json",
            name: t("script.common.json"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["keyvalue"],
        map: "input"
      }
    },
    {
      id: "data_values",
      name: t("script.common.data"),
      type: "pair",
      readOnly: false,
      config: {
        placeholderValue: t("script.common.constant"),
        placeholderSubValue: t("script.common.valueExpression"),
        type: "script",
        theme: "script",
        map: "input"
      },
      visible: {
        data_format: {
          $include: "keyvalue"
        }
      }
    },
    {
      id: "data_json",
      name: t("script.common.data"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.generateDoc.jsonHint"),
      config: {
        type: "multiline",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      },
      visible: {
        data_format: {
          $include: "json"
        }
      }
    },
    {
      id: "section_output",
      name: t("script.common.result"),
      type: "group"
    },
    {
      id: "output_format",
      name: t("script.common.saveAs"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "catalog",
            name: t("script.controlls.generateDoc.formatCatalog"),
            color: "E7E9ED"
          },
          {
            id: "url",
            name: t("script.controlls.generateDoc.formatUrl"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["catalog"],
        map: "input"
      }
    },
    {
      id: "output_catalogId",
      name: t("script.common.catalog"),
      type: "radiobutton",
      readOnly: false,
      config: {
        items: [],
        type: "select",
        data: "catalogs",
        map: "input"
      },
      visible: {
        output_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "output_recordId",
      name: t("script.common.recordId"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.numberExpression")
      },
      visible: {
        output_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "output_fieldId",
      name: t("script.common.field"),
      type: "radiobutton",
      readOnly: false,
      hint: t("script.controlls.generateDoc.fieldIdHint"),
      config: {
        items: [],
        type: "select",
        data: "fields",
        subtypes: ["file"],
        parent: "output_catalogId",
        map: "input"
      },
      configOld: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.numberExpression")
      },
      visible: {
        output_format: {
          $include: "catalog"
        }
      }
    },
    {
      id: "output_filename",
      name: t("script.controlls.generateDoc.filename"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.generateDoc.filenameHint"),
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "output_url",
      name: t("script.common.saveUrl"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        map: "output",
        placeholder: t("script.common.variableName")
      }
    }
  ],
  exits: []
});

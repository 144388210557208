import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import routes from "../../../routes";
import getLink from "../../common/router/getLink";
import Icon from '../../common/UI/Icon';

import styles from "./recordActivities.less";
import sceneActions from "../../../actions/sceneActions";

class RecordCloneModal extends Component {
  static propTypes = {
    linkProps: PropTypes.object,
    routeProps: PropTypes.object,
    params: PropTypes.object,
    parentSceneId: PropTypes.string
  };

  newRecordClone = e => {
    e.preventDefault();
    const { catalogId, recordId } = this.props.params;
    const { parentSceneId } = this.props;
    sceneActions.openCloneRecord({ catalogId, recordId, parentSceneId });
  };

  onClose = () => {
    const { history, location } = this.props;
    history.push(getLink(location, routes.records));
  };

  render() {
    const { linkProps, params } = this.props;
    const href = getLink("", routes.newRecord, params);

    return (
      <Link
        onClick={this.newRecordClone}
        to={href}
        className={styles.linkDropdown}
      >
        <Icon type={"icon " + linkProps.icon} className={styles.settingIcon} />
        {linkProps.text}
      </Link>
    );
  }
}

export default withRouter(RecordCloneModal);

import React from "react";
import PropTypes from "prop-types";
import { Row } from "antd";
import { withTranslation } from "react-i18next";

import Field from "./Field";

import styles from "./rights.less";

class Section extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true, // TODO: default value
      errors: [],
      values: {}
    };
  }

  toggleList = () => {
    this.setState({
      open: !this.state.open
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.values) {
      this.setState({
        values: nextProps.values
      });
    }
  }

  render() {
    const fieldItems = this.props.fields.map(field => {
      const fieldId = field.get("id");
      return (
        <Field
          key={fieldId}
          id={fieldId}
          object={this.props.object}
          subjectPrivilege={this.props.subjectPrivilege}
          onSaveField={this.props.onSaveField}
          name={field.get("name")}
          type={field.get("type")}
          value={this.state.values[fieldId] || this.props.basePrivilege}
        />
      );
    });
    return (
      <div>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={styles.sectionRow}
          onClick={this.toggleList}
        >
          <strong>{this.props.section.get("name")}</strong>
          {!this.state.open ? (
            <span className={styles.sectionRowCount}>
              {this.props.t(
                "record.groupFields.count",
                { count: this.props.fields.length }
              )}
            </span>
          ) : null}
        </Row>
        <div
          style={this.state.open ? null : { display: "none" }}
          className={styles.sectionFields}
        >
          {fieldItems}
        </div>
      </div>
    );
  }
}

Section.propTypes = {
  catalogId: PropTypes.string,
  basePrivilege: PropTypes.string,
  section: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  onSaveField: PropTypes.func.isRequired
};

export default withTranslation()(Section);

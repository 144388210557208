import React from "react";
import PropTypes from "prop-types";
import { Row } from "antd";

import FieldControl from "./FieldControl";

import styles from "./rights.less";

class Field extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  onSave = value => {
    this.props.onSaveField(this.props.id, value);
  };

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({
        value: nextProps.value
      });
    }
  }

  render() {
    return (
      <Row type="flex" justify="start">
        <div className={styles.fieldHeader} title={this.props.name}>
          {this.props.name}
        </div>
        <div className={styles.fieldBody}>
          <FieldControl
            object={this.props.object}
            subjectPrivilege={this.props.subjectPrivilege}
            value={this.state.value}
            onSave={this.onSave}
          />
        </div>
      </Row>
    );
  }
}

Field.propTypes = {
  catalogId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSaveField: PropTypes.func.isRequired
};

export default Field;

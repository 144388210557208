import React, { useEffect, useState } from "react";
import styles from "./controls.less";
import cn from "classnames";
import _ from "lodash";

const ChatraHelper = props => {
  // получаем модуль чатры если он есть
  const chatraModule = _.get(props, ["chatraModule"]);

  const [chatState, setChatState] = useState(false);

  // Начальный стейт чатры сетаем в невидимость
  useEffect(() => {
    window.ChatraSetup = {
      startHidden: true
    };
  }, []);

  // В зависимости от стейта раскрываем или убираем чатру
  useEffect(
    () => {
      if (chatraModule) {
        chatState ? chatraModule("show") : chatraModule("hide");
        chatState && chatraModule("openChat", true);
      }
    },
    [chatState]
  );

  return (
    <React.Fragment>
      <span
        title={props.t("userMenu.helpers.helpQuestionToSales")}
        className={cn("anticon-icon interface-56", styles.chatraIcon)}
        onClick={() => setChatState(prev => !prev)}
      />
    </React.Fragment>
  );
};

export default ChatraHelper;

import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import availableLinkedRecordActions from "../../actions/availableLinkedRecordActions";
import DropdownRemote from "../common/DropdownRemote";
import historyActions from "../../actions/historyActions";
import UrlParamSync from "../UrlParamSync";

class HistoryUserFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      text: ""
    };
  }

  onSelectUser = items => {
    const item = items[0];
    if (!item) {
      return;
    }
    const { sceneId, catalogId, recordId } = this.props;

    if (item.key === "all") {
      const filter = { ...this.props.filter, userId: null };
      historyActions.setFilter(filter, { catalogId, recordId });
    } else {
      const filter = { ...this.props.filter, userId: item.key };
      historyActions.setFilter(filter, { catalogId, recordId });
    }
    historyActions.loadHistory(sceneId, catalogId, recordId, {}, true);
  };

  componentWillUnmount() {
    /* Deleted filter for ant-select */
    const { catalogId, recordId } = this.props;
    historyActions.setFilter({}, { catalogId, recordId });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedUser = (nextProps.filter && nextProps.filter.userId) || "all";
    this.setState({ selectedUser });
  }

  render() {
    const { t } = this.props;
    const allUsers = [
      {
        key: "all",
        text: t("catalogData.history.allEmployers"),
        alwaysVisible: true,
        sort: -1
      }
    ];

    return (
      <React.Fragment>
        <DropdownRemote
          type="users"
          sortBy={false}
          onSelectItems={this.onSelectUser}
          additionalItems={allUsers}
          placeholder={t("catalogData.history.allEmployers")}
          value={this.state.selectedUser}
          loadAvailableItems={availableLinkedRecordActions.loadAvailableItems}
          clearAvailableItems={availableLinkedRecordActions.clearAvailableItems}
        />
        <UrlParamSync name="userId" value={this.state.selectedUser} />
      </React.Fragment>
    );
  }
}

HistoryUserFilter.propTypes = {
  catalogId: PropTypes.string,
  recordId: PropTypes.string
};

export default withTranslation()(HistoryUserFilter);

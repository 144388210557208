// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".config__buttonConfig___7kIC6 {\n  margin-left: -5px;\n  margin-right: -5px;\n  height: 34px;\n}\n.config__overlay___WrCfe {\n  width: 238px;\n  padding: 10px;\n  padding-bottom: 10px;\n  background: #e7e9eb;\n}\n.config__controlRow___zJ5Eg {\n  margin-bottom: 15px;\n  position: relative;\n  height: auto;\n  margin-right: 0;\n  margin-left: 0;\n  zoom: 1;\n  display: block;\n  box-sizing: border-box;\n}\n.config__controlLabel___4O43F {\n  display: inline-block;\n  margin-bottom: 2px;\n  color: #666b70;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Cards/Header/Config/config.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;AADF;AAIA;EACE,YAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;AAFF;AAKA;EACE,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,OAAA;EACA,cAAA;EACA,sBAAA;AAHF;AAMA;EACE,qBAAA;EACA,kBAAA;EACA,cAAA;AAJF","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.buttonConfig {\n  margin-left: -(@PADDING_BASE / 2);\n  margin-right: -(@PADDING_BASE / 2);\n  height: @RECORD_DATA_HEADER - 1px; // Otherwise, the border of the header is invisible\n}\n\n.overlay {\n  width: 238px;\n  padding: @PADDING_BASE;\n  padding-bottom: @PADDING_BASE;\n  background: @BG_PANELS;\n}\n\n.controlRow {\n  margin-bottom: @PADDING_BASE*1.5;\n  position: relative;\n  height: auto;\n  margin-right: 0;\n  margin-left: 0;\n  zoom: 1;\n  display: block;\n  box-sizing: border-box;\n}\n\n.controlLabel {\n  display: inline-block;\n  margin-bottom: 2px;\n  color: @TEXT_SUB;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonConfig": "config__buttonConfig___7kIC6",
	"overlay": "config__overlay___WrCfe",
	"controlRow": "config__controlRow___zJ5Eg",
	"controlLabel": "config__controlLabel___4O43F"
};
export default ___CSS_LOADER_EXPORT___;

import * as valueHelper from "./value";

export function format(value) {
  return elementCreator =>
    elementCreator("camunda:Map", {
      entries: child =>
        value.map(({ key, value }) =>
          child("camunda:Entry", {
            key,
            definition: valueHelper.format(value)
          })
        )
    });
}

export function parse(parameterParser, entries) {
  return entries.map(({ key, definition, value }) => {
    value = parameterParser(definition || value);
    return {
      key,
      value
    };
  });
}

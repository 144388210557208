import React from "react";
import _ from "lodash";
import Immutable from "immutable";
import { withRouter } from "react-router";
import apiActions from "../../../../actions/apiActions";
import { withTranslation } from "react-i18next";
import { alert } from "../../../common/Modal";
import Menu from "../../../common/UI/Menu";
import { connect } from "../../../StateProvider";
import { prompt } from "../../../common/Modal";

import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../utils/rights";

import styles from "./styles.less";

class SectionsMenu extends React.PureComponent {
  state = {
    sections: Immutable.List(),
    reordering: false
  };

  // need to fast reorder by drag-n-grop
  componentDidMount() {
    this.setState({
      sections: this.prepareSections(this.props.sections)
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.sections !== prevProps.sections
      || this.props.favoriteCatalogs !== prevProps.favoriteCatalogs) {
      if ( !this.state.reordering ) {
        this.prepareSections(this.props.sections);
      }
    }
  }

  prepareSections = sections => {
    // clean
    sections = sections
      // show sections with privilegeCode > available (search, view, ...)
      .filter(section => section.get("privilegeCode") !== "available")
      .valueSeq()

    // clean data
    sections = sections.map(section => section.set("icon", ""));

    // add new section button
    const adminSection = sections.find(section => {
      return section.get("id") == "$system" || section.get("id") == "$settings" || Number(section.get("id")) === 1;
    });
    const adminOfAdminSection = adminSection && this.isAdminOfSection(adminSection.get("id"));
    if (adminOfAdminSection) {
      sections = sections.concat([
        Immutable.Map({
          id: "addButton",
          name: "+ " + this.props.t("section.menu.create"),
          className: styles.addButton,
          onClick: this.createSection,
          priority: Infinity
        })
      ]);
    }

    // favorite section
    if (this.props.favoriteCatalogs && this.props.favoriteCatalogs.size) {
      sections = sections.concat([
        Immutable.Map({
          id: "$favorite",
          icon: "vote-38",
          name: "",
          title: this.props.t("section.favorite"),
          className: styles.favorite,
          priority: -Infinity
        })
      ]);
    }

    // sort
    sections = sections
      .sortBy(s => s.get("priority"))

    this.setState({ sections });
  };

  
  /* actions */
  createSection = (section, e) => {
    const { t } = this.props;
    
    e.preventDefault();
    e.stopPropagation();

    prompt({
      headerText: t("modals.createSection.headerText"),
      placeHolder: t("modals.createSection.inputPlaceHolder"),
      okText: t("buttons.create"),
      cancelText: t("buttons.cancel"),
      onOk: this.onCreateSection
    });
  };

  onCreateSection = name => {
    apiActions
      .createSection(
        {},
        {
          name: name,
          icon: "content-3"
        }
      )
      .then(res => {
        this.props.history.push({
          pathname: `/section/${res.id}`,
          search: this.props.location.search
        });
      });
  };

  isAdminOfSection = key => {
    const section = this.props.sections.find(section => section.get("id") === key);

    const isAdmin =
      section &&
      checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        section,
        PRIVILEGE_CODES.ADMIN
      );

    return isAdmin;
  };

  /* dnd */
  canDrag = dragItem => {
    if (dragItem.key === "addButton") {
      return false;
    }
    
    const isAdmin = this.isAdminOfSection(dragItem.key);
    if ( ! isAdmin )  {
      return false;
    }

    // внешняя дополнительная проверка
    if ( this.props.canDrag && ! this.props.canDrag(dragItem) ) {
      return false;
    }

    return true;
  };

  canDrop = (dragItem, dropItem) => {
    if (dropItem === "addButton") {
      return false;
    }

    // если перетаскиваем каталог в свою же секцию
    if (dropItem.key === this.props.activeId) {
      return false;
    }
    
    // если нет прав на секцию
    const isSectionAdmin = this.isAdminOfSection(dropItem.key);
    if (!isSectionAdmin) {
      return false;
    }

    // в секцию можно перетащить либо секцию, либо каталоги
    const allowedDragTypes = [RESOURCE_TYPES.CATALOG, RESOURCE_TYPES.SECTION];
    if (!allowedDragTypes.find(item => item === dragItem.dragType)) {
      return false;
    }
    
    // внешняя дополнительная проверка
    if ( this.props.canDrop && ! this.props.canDrop(dragItem, dropItem) ) {
      return false;
    }

    return true;
  };

  onDrop = (dragItem, dropItem, newOrderList) => {
    if (dragItem.dragType === RESOURCE_TYPES.CATALOG) {
      const catalogId = dragItem.key;
      const sectionId = dropItem.key;

      const params = { sectionId, catalogId };
      let data = {
        sectionId: dropItem.key
      };

      apiActions
        .updateCatalog(params, data)
        .then(() => {
          
        })
        .catch(e => {
          alert({
            headerText: this.props.t("modals.moveCatalogError.headerText"),
            text: _.get(e, "response.text"),
            okText: this.props.t("modals.moveCatalogError.okText")
          })
    });
    }

    if (dragItem.dragType === RESOURCE_TYPES.SECTION) {
      let sections = this.state.sections;
      let orderedList = newOrderList;
      const key = dragItem.key;

      // calc new priority
      orderedList = orderedList.toJS();
      orderedList = orderedList.filter(i => i != "addButton");
      let index = orderedList.findIndex(i => i == key);

      if (index > 0) {
        const prevKey = orderedList[index - 1]; // get key of previous section
        const section = sections.find(section => {
          return section.get("id") === prevKey;
        });
        index = section.get("priority") + 1;
      }

      // update in local
      sections = this.props.sections;
      let prevIndex;
      sections = sections.map(section => {
        if (section.get("id") == key) {
          prevIndex = section.get("priority");
          section = section.set("priority", index - 0.1); // to be sure be in need place
        }
        return section;
      });
      this.prepareSections(sections);
      this.setState({ reordering: true });

      // save
      apiActions
        .updateSection(
          {
            sectionId: key
          },
          {
            priority: index
          }
        )
        .then(() => {
          this.setState({ reordering: false });
        })
        .catch(() => {
          this.setState({ reordering: false });
          // put in previous place
          sections = this.props.sections;
          sections = sections.map(section => {
            if (section.get("id") == key) {
              section = section.set("priority", prevIndex);
            }
            return section;
          });
          this.prepareSections(sections);
        });
    }
  };
  /* end dnd */

  render() {
    const { activeId, direction, renderItem, onClick } = this.props;
    const sections = this.state.sections;
    if (! sections ){
      return null;
    }

    const items = sections.map( item => renderItem(item, activeId));
    return (
      <Menu
        items={items}
        activeId={activeId}
        direction={direction}
        className={styles.menu}
        popupClassName={styles.popup}
        dragType="section"
        canDrag={this.canDrag}
        canDrop={this.canDrop}
        onDrop={this.onDrop}
        onClick={onClick}
      />
    );
  }
}

export default connect(
  withRouter(withTranslation()(SectionsMenu)),
  {
    favoriteCatalogs: ["userSettings", "ui", "favoriteCatalogs"]
  }
);

import _ from "lodash";

function safeJsonParse(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export function formatScriptValue({ type = "script", value }) {
  return JSON.stringify({ type, value });
}

export function format(value) {
  return elementCreator =>
    elementCreator("camunda:Script", {
      scriptFormat: "JavaScript",
      value: formatScriptValue(value)
    });
}

export function parse(value) {
  const jsValue = safeJsonParse(value);
  return _.isPlainObject(jsValue) ? jsValue.value : value;
}

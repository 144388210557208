import React, { lazy, Suspense } from "react";
import cn from "classnames";
import { Switch, Route } from "react-router";

import routes from "../../../../../routes";

import DefaultRedirect from "../../../../common/router/DefaultRedirect";

import CatalogEditor from "../../../../catalogEditor";
import Loading from "../../../../common/Loading";

import uiActions from "../../../../../actions/uiActions";

import styles from "./sectionBody.less";

const CatalogSceneController = lazy(() => import("./CatalogSceneController"));

const SectionBody = props => {

  const closeGhost = () => {
    uiActions.sectionMenuHover(null);
  }

  const ghostMode = props.ghostMode;
  const firstCatalog = props.firstCatalog;

  return (
    <div className={cn(styles.body, {[styles.ghostMode]: ghostMode})}>

      <Switch>
        <Route
            path={routes.catalogEdit.path}
            render={props => {
              return (<CatalogEditor isStateEditCatalog={true} {...props} />);
            }}
          />

          <Route
            path={routes.catalogAdd.path}
            render={props => {
              return (<CatalogEditor isStateAddCatalog={true} {...props} />);
            }}
          />

          <Route
            path={routes.catalog.path}
            render={props => {
              return (
                <Suspense fallback={<Loading />}>
                  <CatalogSceneController {...props}/>
                </Suspense>
              );
            }}
          />

          <Route
            route={routes.catalog}
            render={props => {
              return (
                  <DefaultRedirect
                    route={routes.catalog}
                    params="catalogId"
                    object={firstCatalog}
                  />
              );
            }}
          />

      </Switch>

      {/* click to close catalogs menu overlay */}
      {ghostMode && (
        <div className={styles.ghostOverlay} onClick={closeGhost}/>
      )}
    </div>
  );
};

export default SectionBody;

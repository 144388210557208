import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RESOURCE_TYPES from "../../configs/resourceTypes";

import {
  ACTION_PARAM_NAME,
  ACTION_CATALOG_OPEN,
  ACTION_RECORD_OPEN,
  ACTION_RECORD_NEW,
  ACTION_RECORD_PARAM_CATALOG_NAME,
  ACTION_RECORD_PARAM_RECORD_NAME
} from "../../configs/routes";

import styles from "./access.less";

const PublicAccess = props => {
  const [buttons, setButtons] = useState([]);
  const { t } = useTranslation();

  const prepareParams = params => {
    return _.reduce(
      params,
      (arr, val, key) => {
        return arr.concat(key + "=" + val);
      },
      []
    ).join("&");
  };

  useEffect(() => {
    let buttons = [];
    if (props.resource === RESOURCE_TYPES.CATALOG) {
      buttons.push(
        {
          title: t("modals.access.publicAccess.catalogHeader"),
          params: {
            [ACTION_PARAM_NAME]: ACTION_RECORD_NEW,
            [ACTION_RECORD_PARAM_CATALOG_NAME]: props.object.catalogId
          }
        },
        {
          title: t("modals.access.publicAccess.catalogModal"),
          params: {
            [ACTION_PARAM_NAME]: ACTION_CATALOG_OPEN,
            [ACTION_RECORD_PARAM_CATALOG_NAME]: props.object.catalogId
          }
        }
      );
    } else if (props.resource === RESOURCE_TYPES.RECORD) {
      buttons.push({
        title: t("modals.access.publicAccess.recordHeader"),
        params: {
          [ACTION_PARAM_NAME]: ACTION_RECORD_OPEN,
          [ACTION_RECORD_PARAM_CATALOG_NAME]: props.object.catalogId,
          [ACTION_RECORD_PARAM_RECORD_NAME]: props.object.recordId
        }
      });
    }

    buttons = _.map(buttons, button => {
      return {
        ...button,
        params: prepareParams(button.params)
      };
    });

    setButtons(buttons);
  }, []);

  return (
    <div className={styles.publicAccessWrapper}>
      {_.map(buttons, ({ title, params: urlParams }, i) => (
        <a
          key={i}
          className={styles.publicAccessLink}
          target="_blank"
          href={
            window.location.protocol +
            "//" +
            window.location.host +
            "/?" +
            urlParams
          }
        >
          {title}
        </a>
      ))}
    </div>
  );
};

PublicAccess.propTypes = {
  resource: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired
};

export default PublicAccess;

import React, { useEffect, useRef, useState } from 'react';

const useStartTimer = (nextUpdate, showTimer, updateThrottled) => {
    const [duration, setDuration] = useState(null);
    const timeoutId = useRef();

    useEffect(() => {
        if (!showTimer) {
            clearTimeout(timeoutId.current);
            setDuration(null);
        } else {
            startUpdateDuration(nextUpdate);
        };
    }, [showTimer])

    useEffect(() => {
        showTimer && startUpdateDuration(nextUpdate);
        return () => clearTimeout(timeoutId);
    }, [nextUpdate]);

    const startUpdateDuration = (nextTime = nextUpdate) => {
        if (!nextTime) {
            return;
        };

        const duration = Math.max(nextTime - Date.now(), 0);

        setDuration(duration);

        clearTimeout(timeoutId.current);
        if (duration) {
            timeoutId.current = setTimeout(
                startUpdateDuration,
                Math.min(duration, 1000)
            );
        } else {
            if (updateThrottled) {
                updateThrottled();
            };
        };
    };
    return {
        duration
    };
};

export default useStartTimer;
import _ from "lodash";
import React from "react";
import Immutable from "immutable";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { connect } from "../../../../../StateProvider";

import Icon from '../../../Icon';
import LinkedItem from "../../../../../common/UI/LinkedItem";
import LinkedRecordOpen from "../../../../../Record/LinkedRecordOpen";
import RecordFields from "../../../../../Record/RecordBody/mainTab/RecordFields";

import styles from "../controls.less";

const ExtendedLinkedRecord = props => {
  const extFields = props.extFields;
  const extValues = props.values || Immutable.Map();

  const {
    t,
    item,
    params,
    removed,
    sceneId,
    readOnly,
    removable,
    onRemoveItem,
    onSaveExtField,
    onUpdateExtField
  } = props;

  return (
    <div className={styles.linkedItemExtended}>
      <LinkedRecordOpen params={params} sceneId={sceneId} />
      <LinkedItem
        sceneId={sceneId}
        className={styles.linkedItemExtendedActions}
        removed={removed}
        removable={removable}
        titleOnRemove={t("record.fields.object.delete")}
        item={{
          key: item.get("key"),
          text: "",
          icon: (
            <Icon
              type="icon programing-39"
              title={t("record.fields.object.open")}
            />
          )
        }}
        onClickRemove={onRemoveItem}
        params={params}
      />
      <RecordFields
        sceneId={sceneId}
        catalogId={item.getIn(["item", "catalogId"])}
        recordId={item.getIn(["item", "recordId"])}
        fields={extFields}
        values={extValues}
        onSaveField={onSaveExtField}
        onUpdateField={onUpdateExtField}
        readOnly={readOnly}
        viewOnly={readOnly}
        compact={true}
      />
    </div>
  );
};

ExtendedLinkedRecord.propTypes = {
  extFields: PropTypes.object,
  item: PropTypes.object,
  params: PropTypes.object,
  removed: PropTypes.bool,
  removable: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSaveExtField: PropTypes.func,
  onUpdateExtField: PropTypes.func,
  onRemoveItem: PropTypes.func
};

export default withTranslation()(
  connect(
    ExtendedLinkedRecord,
    ["catalogs", "records"],
    (props, { catalogs, records }) => {
      const catalogId = props.item.getIn(["item", "catalogId"]);
      const recordId = props.item.getIn(["item", "recordId"]);
      const catalogFields = catalogs.getIn([catalogId, "fields"]);
      const values = records.getIn([catalogId, recordId, "values"]);
      return {
        catalogFields,
        values,
        ...props
      };
    }
  )
);

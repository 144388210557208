import { InputMappers, OutputMappers } from "../helpers/mappers";
import i18n from "../../../../../configs/i18n";

const Properties = (field, t = i18n.t) => {
  const config = field.get("config");
  const trsPrefix =
    "catalogEditor.fieldProperties.configProperties.field." +
    field.get("type") +
    ".";

  return {
    properties: [
      {
        id: "configPropertiesSection",
        name: t("catalogEditor.fieldProperties.configProperties.section"),
        type: "group"
      },
      {
        id: "config.multiselect",
        name: t(trsPrefix + "multiselect.label"),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(trsPrefix + "multiselect.value")
            }
          ]
        }
      },
      {
        id: "config.enableSelect",
        name: t(trsPrefix + "enableSelect.label"),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(trsPrefix + "enableSelect.value")
            }
          ]
        }
      },
      {
        id: "config.accessOnly",
        name: t(trsPrefix + "accessOnly.label"),
        hint: t(trsPrefix + "accessOnly.hint"),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(trsPrefix + "accessOnly.value")
            }
          ]
        }
      },
      {
        id: "config.enableCreate",
        name: t(trsPrefix + "enableCreate.label"),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(trsPrefix + "enableCreate.value")
            }
          ]
        }
      },
      {
        id: "config.enableUnsaved",
        name: t(trsPrefix + "enableUnsaved.label"),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(trsPrefix + "enableUnsaved.value")
            }
          ]
        }
      }
    ],

    values: {
      "config.enableCreate": InputMappers.boolToCheckbox(
        config.get("enableCreate")
      ),
      "config.enableSelect": InputMappers.boolToCheckbox(
        config.get("enableSelect")
      ),
      "config.multiselect": InputMappers.boolToCheckbox(
        config.get("multiselect")
      ),
      "config.accessOnly": InputMappers.boolToCheckbox(
        config.get("accessOnly")
      ),
      "config.enableUnsaved": InputMappers.boolToCheckbox(
        config.get("enableUnsaved")
      )
    },

    getValues: (propery, value) => {
      switch (propery) {
        case "config.multiselect":
        case "config.enableSelect":
        case "config.enableCreate":
        case "config.accessOnly":
        case "config.enableUnsaved":
          value = OutputMappers.checkboxToBool(value);
          break;
      }
      return { [propery]: value };
    }
  };
};

export default Properties;

import _ from "lodash";
import i18n from "./i18n";

const dateKeys = [
  {
    title: undefined, 
    values: { 
      FIXED: "fixed",
      RELATIVE: "relative",
      NULL: "NULL_DATE",
    }
  },
  {
    title: "current",  
    values: {
      TODAY: "TODAY_DATE",
      TILL_TODAY: "TILL_TODAY_DATE",    
      THIS_WEEK: "THIS_WEEK_DATE",
      // THIS_WEEK_TILL_TODAY: "THIS_WEEK_TILL_TODAY_DATE",    
      // THIS_WEEK_NEXT_TODAY: "THIS_WEEK_NEXT_TODAY_DATE",
      CURRENT_MONTH: "CURRENT_MONTH_DATE",
      THIS_YEAR: "THIS_YEAR_DATE",
    }
  },
  {
    title: "past", 
    values: {
      YESTERDAY: "YESTERDAY_DATE",
      TILL_YESTERDAY: "TILL_YESTERDAY_DATE",
      LAST_WEEK: "LAST_WEEK_DATE",
      LAST_30_DAYS: "LAST_30_DAYS_DATE",
      LAST_MONTH: "LAST_MONTH_DATE",
      LAST_365_DAYS: "LAST_365_DAYS_DATE",
      LAST_YEAR: "LAST_YEAR_DATE",
      CURRENT_DAY: "CURRENT_DAY_DATE",
    }
  },
  {
    title: "future", 
    values: {
      TOMORROW: "TOMORROW_DATE",
      TOMORROW_NEXT: "TOMORROW_NEXT_DATE",
      NEXT_WEEK: "NEXT_WEEK_DATE",
      NEXT_30_DAYS: "NEXT_30_DAYS_DATE",
      NEXT_MONTH: "NEXT_MONTH_DATE",
      NEXT_365_DAYS: "NEXT_365_DAYS_DATE",
      NEXT_YEAR: "NEXT_YEAR_DATE",
    }
  },
];

const userKeys = ["$EMPTY", "CURRENT_USER"];
const objectKeys = ["$EMPTY"];

const getFilters = keys => {
  return _.map(keys, key => {

    if (_.isObject(key)) {
      return {
        title: key.title ? i18n.t("filter.titles.interval." + key.title) : undefined,
        values: _.map(Object.keys(key.values), (keyValue) => ({
          key: key.values[keyValue],
          value: i18n.t("filter.keys." + key.values[keyValue]),
        }))
      };
    } else {
      return {
        key,
        value: i18n.t("filter.keys." + key)
      };
    }
  });
};

const dateFilters = getFilters(dateKeys);
const userFilters = getFilters(userKeys);
const objectFilters = getFilters(objectKeys);

export { dateFilters, userFilters, objectFilters };

import Immutable, { fromJS } from "immutable";
import _ from "lodash";
import valueIsEmpty from "../../../helpers/valueIsEmpty";
import RecordFactory from "../../../models/RecordFactory";
import calcVisibleControls from "../../../utils/calcVisibleControls";

export default {
  setRecord(params, data, keepUnsavedValues = false, keepOriginValues) {
    const recordKey = ["records", params.catalogId, params.recordId];

    // для того чтобы объект data (body) не модифицировался
    data = _.cloneDeep(data);

    if (data.values) {
      // cut recordValues from linkedRecords
      // and create real records

      /* устанавливаем связанные записи в текущий список записей */
      this.setRecordsFromLinks(
        params,
        data.values,
        keepUnsavedValues,
        keepOriginValues
      );
      data.values = this.сutRecordValuesFromObjects(
        params.catalogId,
        fromJS(data.values)
      );
    }

    data.catalogId = params.catalogId;

    const existingRecord = this.getIn([...recordKey]);

    if (existingRecord) {
      const record = RecordFactory.create(data);
      this.mergeRecord(record, params, keepUnsavedValues, keepOriginValues);
    } else {
      const values = fromJS(data.values);
      const record = RecordFactory.create({
        ...data,
        values
      });

      this.setIn([...recordKey], record);
    }

    const prevHiddenFields = this.getIn([...recordKey, "hiddenFields"]);
    this.calcHiddenFields(params);

    const isNew = this.getIn([...recordKey, "isNew"]);

    /* тут мы высчитываем скрытые поля и подмешиваем дефолтные значения */
    !keepUnsavedValues &&
      this.setDefaultValues(
        params.catalogId,
        params.recordId,
        prevHiddenFields,
        isNew
      );

    this.setEditStatus(params.catalogId, params.recordId);
  },

  mergeRecord(record, params, keepUnsavedValues, keepOriginValues) {
    const recordKey = ["records", params.catalogId, params.recordId];
    let newValues = record.get("values");
    let originValues = this.getIn([...recordKey, "originValues"]);
    let values = this.getIn([...recordKey, "values"]);
    let parents = this.getIn([...recordKey, "parents"]) || Immutable.List();
    /* 
        keepOriginValues значит сохранить originValues.
        Условие тут если нам не нужно сохранить originValues.
        сохранить оригинальные значения нам нужно в каких случаях:
        1. Когда создается новая запись.
        2. Когда создаем новую запись через связанный каталог без всплытия, 
        у связанного каталога запись которую мы создали нужно сохранить оригинальне значения 
        потому что запись создатся без значений, так как оригинальные значения от текущих не отличаются
        Могли бы написать условие что-то типо если не новая запись и не нужно сохранять оригинальные значения то мерджим, но у нас ещё есть changes
    */
    if (!keepOriginValues) {
      originValues = originValues ? originValues.merge(newValues) : newValues;
    }

    if (keepUnsavedValues) {
      const changedValues = this.getChangedValues(
        params.catalogId,
        params.recordId,
        true
      );
      newValues = newValues.merge(changedValues);

      record = record.set("errors", this.getIn([...recordKey, "errors"]));
    }

    values = values ? values.merge(newValues) : newValues;

    record = record.set("originValues", originValues);
    record = record.set("values", values);
    record = record.set("parents", parents);

    this.mergeWith(
      recordKey,
      (oldValue, newValue) => (!valueIsEmpty(newValue) ? newValue : oldValue),
      record
    );
  },

  calcHiddenFields({ catalogId, recordId }) {
    const fields = this.getIn(["catalogs", catalogId, "fields"]);
    const values = this.getIn(["records", catalogId, recordId, "values"]);
    const visibleFields = calcVisibleControls(values, fields);

    const hiddenFields = {};

    _.mapKeys(visibleFields, (visible, fieldId) => {
      if (!visible) {
        hiddenFields[fieldId] = !visible;
      }
    });

    this.setIn(
      ["records", catalogId, recordId, "hiddenFields"],
      Immutable.fromJS(hiddenFields)
    );

    return Immutable.fromJS(hiddenFields);
  },

  // always call before "сutRecordValuesFromObjects" method
  setRecordsFromLinks(params, values, keepUnsavedValues, keepOriginValues) {
    /* использует только catalogId, для манипуляции с fields */
    /* возвращает массив связанных записей  */
    const linkedRecords = this.getLinkedRecords(
      params.catalogId,
      Immutable.fromJS(values),
      true
    );

    /* идем по каждой записи */
    linkedRecords.forEach(({ linkedRecord, fieldId }) => {
      /* получаем catalogId связанной записи */
      const catalogId = linkedRecord.get("catalogId");

      /* получаем recordId связанной записи */
      const recordId = linkedRecord.get("recordId");

      /* получаем все поля связанной записи */
      const fields = this.getIn(["catalogs", catalogId, "fields"]);

      // title
      const currentRecordTitle = this.getIn([
        "records",
        catalogId,
        recordId,
        "title"
      ]);

      /* формируем новые данные для записи по полученной связанной записи (регистрируем связанную запись, как запись родительского каталога) */
      const data = {
        id: recordId,
        isNew: linkedRecord.get("isNew"),
        title: linkedRecord.get("recordTitle") || currentRecordTitle,
        values: linkedRecord.get("recordValues"),
        fields: fields && fields.toJS()
      };

      /* параметры связанной записи */
      // const recordParams = { catalogId, recordId, sceneId };
      const recordParams = { catalogId, recordId };

      this.setRecord(recordParams, data, keepUnsavedValues, keepOriginValues);

      /* добавление информации о родительском элементе в текущий скписок записей */
      this.addParentToRecord(
        recordParams,
        params.catalogId,
        params.recordId,
        fieldId
      );
    });
  },

  setEditStatus(catalogId, recordId, checkingParents = false) {
    const recordKey = ["records", catalogId, recordId];

    /* проверка есть ли изменения в записи */
    const hadBeenEdit = this.getIn([...recordKey, "hasBeenEdit"]);
    const changedValues = this.getChangedValues(catalogId, recordId, true);
    const hasBeenEdit = !!(changedValues && changedValues.size);

    /* 
      терминология:
      исходная запись - исходная запись
      родительская запись - запись, для которой исходная запись является связанным объектом 
      родительская запись 1 уровня - родительская запись исходной связанной записи
      родительская запись 2 уровня - родительская запись родительская запись 1 уровня

      ограничение проверки на статус изменения родительской запись 2 уровня, при условии что статус редактирования записи до проверки и после будет совпадать

      непонятно зачем учитывать статусы редактирования записи
    */

    if (checkingParents && hadBeenEdit === hasBeenEdit) {
      return;
    }

    this.setIn([...recordKey, "hasBeenEdit"], hasBeenEdit);

    /* проверка родительских */
    const parents = this.getIn(["records", catalogId, recordId, "parents"]);
    parents &&
      parents.forEach(parent =>
        this.setEditStatus(
          parent.get("catalogId"),
          parent.get("recordId"),
          true
        )
      );
  },

  updateRecordValues(params, newValues, fromChanges = false) {
    const recordKey = ["records", params.catalogId, params.recordId];
    if (!this.getIn([...recordKey])) return;

    /* выделяем только изменившиеся значения */
    newValues = newValues.filter((newValue, id) => {
      const prevValue = this.getIn([...recordKey, "values", id]);
      return this.compareValues(newValue, prevValue);
    });

    // fiter by hidden fields, readOnly
    /*
      Фильтрация производится в момент сохранения полей, а не на процессе сохранения данных в стейт (раньше фильтрация была и на сохранение в стейт и на сохраненеи на сервере)

      функция filterEditableValues используется для фильтрации значений по правам на поля и по их видимости
      !но эта функция используется как перед сохранением записи, так и перед записью значений в локальное хранилище (стейт)
      такой подход к применению одной логики к двум событиям порождает коллизии, проявляющиеся при сохранении вложенных записей

      Пример:

      в записи (запись 1, каталог 1) есть поле типа связанный каталог (запись 2, каталог 2) с отображаемыми полями (поле 2 - изменять, поле 3 - изменять, поле 4 - изменять)
      на запись 2 в каталоге 2 действуют ограничения для текущего пользователя - скрыто поле 2, изменять поле 3 и видеть поле 4

      Открывая запись 2 в каталоге 2 пользователь увидит поле 3 с возможностью изменять и поле 4 с возможность просмотра

      Но при открытии записи 1 каталога 1 пользователь увидит связанное поле (запись 2, каталог 2) с тремя полями (поле 2, поле 3, поле 4)
      При этом у пользователя даже все будет работать, но при открытии записи 2 в модальном окне, будут загружены права на запись 2, каталога 2

      С загруженными правами на запись 2, каталога 2 функция filterEditableValues будет отфильтровывать значения в соответствии с правами на запись 2, каталога 2 и 
      привелегии на поля связанного поля будут проигнорированны, что является ошибочным поведением 
    */
    // newValues = this.filterAllowToSetValues(params, newValues);

    if (!(newValues && newValues.size)) {
      return;
    }

    /* фильтрация измененных полей на предмет, необходимости вызова по ним лайф сценрия */
    const valuesToRaiseChanges = this.getValuesNeedToRaise(
      params,
      newValues,
      this.updatedFields
    );
    if (valuesToRaiseChanges && valuesToRaiseChanges.size) {
      /* выставляется флаг о необходимости запуска лайф сценария */
      this.shouldUpdateProcess(params.catalogId, params.recordId);
    }

    /* создание связанных записей в стейте */
    const keepUnsavedValues = !fromChanges; // Changes should override previous made user changes
    const keepOriginValues = true; // do not trust values from Changes like from Database
    this.setRecordsFromLinks(
      params,
      newValues,
      keepUnsavedValues,
      keepOriginValues
    );
    /* удаление из связанных записей расширенных полей (recordsValue) */
    newValues = this.сutRecordValuesFromObjects(params.catalogId, newValues);

    /* внесение совершенных изменений над связанными записями в стейт */
    this.mergeIn([...recordKey, "values"], newValues);

    /* ставит флаг об изменениях в записи */
    this.setEditStatus(params.catalogId, params.recordId);

    /* проверка значений полей на их корректность */
    _.forEach(newValues.toJS(), (value, fieldId) => {
      this.validateRecordField(
        params.catalogId,
        params.recordId,
        fieldId,
        value
      );
    });

    this.changed();
  },

  mixDefaultValues(values = Immutable.Map(), fields) {
    if (!fields) return values;
    fields.forEach(field => {
      const fieldId = field.get("id");
      const defaultValue = field.getIn(["config", "defaultValue"]);

      if (defaultValue && !values.has(fieldId)) {
        const defaultEmptyValue = field.getIn(["config", "defaultEmptyValue"]);
        values = values.set(fieldId, defaultEmptyValue);
      }
    });

    return values;
  }
};

import { InputMappers, OutputMappers } from "../helpers/mappers";
import i18n from "../../../../../configs/i18n";

const Properties = (field, t = i18n.t) => {
  const config = field.get("config");
  const trsPrefix =
    "catalogEditor.fieldProperties.configProperties.field." +
    field.get("type") +
    ".";

  return {
    properties: [
      {
        id: "configPropertiesSection",
        name: t("catalogEditor.fieldProperties.configProperties.section"),
        type: "group"
      },
      {
        id: "config.min",
        name: t(trsPrefix + "min"),
        type: "number",
        readOnly: false,
        config: {}
      },
      {
        id: "config.max",
        name: t(trsPrefix + "max"),
        type: "number",
        readOnly: false,
        config: {}
      },
      {
        id: "config.unit",
        name: t(trsPrefix + "unit"),
        type: "text",
        readOnly: false,
        config: {}
      }
    ],

    values: {
      "config.min": config.get("min"),
      "config.max": config.get("max"),
      "config.unit": config.get("unit")
    },

    getValues: (propery, value) => {
      return { [propery]: value };
    }
  };
};

export default Properties;

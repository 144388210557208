import React from "react";
import _ from "lodash";

import FIELD_TYPES from "../../configs/fieldTypes";
import { VALUE_STATUSES } from "../../configs/import";
import { Field } from "./BaseClass.js";

export default class Group extends Field {
  constructor() {
      super()
    }
  static type = FIELD_TYPES.GROUP;

  static components = {};

  static getComponent = type => Group.components[type];

  static getCanComponentExpandWidthOrHeight = value => {
    return;
  };

  static parseValue = (field, value) => {
    return { };
  };

  static validateValue = (field, value) => {
    return false; 
  };

  static getEmptyValue = () => {
    return false;
  };

  static isEmpty = value => !value;

  static compare = (value1, value2) => {
    return value1 === value2;
  };

  static createComponent = (field, value, type) => {
    const Component = Group.components[type];
    return ({ containerClassName }) => {
      if (Group.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };
  static convertFilterToRecordValue = () => {
    return undefined;
  };
  static getDefaultValue = field => {
    const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  static validateRequired = () => {
    return;
  };

  static boardWitchColor = () => {
    return false;
  };

  static visibleRules = value => {
    return;
  };

  static sortRecordsInCards = (field, records) => {
    return records;
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(["config", "type"]);
    switch (type) {
      default:
        return false;
    }
  }

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}

import _ from "lodash";

import { id, newId } from "../configs/fieldIds";

/**
 * Updates the field identifier if necessary.
 *
 * @param {Object} values - An object containing identifiers.
 * @param {Object} field - The original field represented as an immutable object.
 */
export function updateFieldIds(values, field) {
  // If the user prompt requests to change the ID for a field,
  // we need to set this ID to the new ID.
  // null is okay if "" == null,
  // but it can also be undefined, which is also okay,
  // since a new field doesn't have an existing ID.

  // Get the changed ID from the values object or null if it doesn't exist
  const changedId = _.get(values, [id], null);

  // Get the initial ID from the field
  const initialId = _.get(field.toJS(), [id]);
  // If the changed ID exists, update it in the values
  if (changedId) {
    _.set(values, [newId], changedId);
    _.unset(values, [id]);
  }
  // If we want to remove the identifier
  if (_.has(values, [id]) && _.isEmpty(_.trim(values.id))) {
    _.set(values, [newId], null);
    _.unset(values, [id]);
  }
  // If the changed ID matches the initial one, "revert" the state
  if (changedId === initialId) {
    _.unset(values, [newId]);
    _.set(values, [id], initialId);
    field = field.deleteIn([newId]);
  }

  return field;
}

import React, { useRef, useEffect } from "react";
import { Modal, Button, Row, Input, Alert, Col } from "antd";

import { usePrompt } from "./prompt.hook";
import ButtonClose from "../UI/ButtonClose";

import styles from "./modal.less";

const Prompt = props => {
  const { isValid, value, onChange, onSave } = usePrompt(props);
  const inputRef = useRef(null);
  useEffect(
    () => {
      setTimeout(() => inputRef.current.focus(), 0);
    },
    [inputRef]
  );

  const disabled = !value.length;
  return (
    <Modal
      open={true}
      maskClosable={false}
      closable={false}
      footer={[
        <Button
          key="submit"
          disabled={disabled}
          type="primary"
          onClick={onSave}
        >
          {props.okText}
        </Button>,
        <Button key="back" type="default" onClick={props.onCancel}>
          {props.cancelText}
        </Button>
      ]}
    >
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className={styles.header}
      >
        <h1>{props.headerText}</h1>
        <ButtonClose
          large
          className={styles.close}
          shiftRight
          onClick={props.onCancel}
        />
      </Row>
      <Col className={styles.content}>
        <p>{props.text}</p>
        <form onSubmit={onSave} className={styles.form}>
          <Input
            ref={inputRef}
            type="text"
            defaultValue={props.value}
            onChange={onChange}
            placeholder={props.placeHolder}
          />
        </form>
      </Col>
      {props.message && (
        <div className={styles.message}>
          <Alert
            banner={true}
            closable={false}
            message={props.message}
            type={"info"}
          />
        </div>
      )}
    </Modal>
  );
};

export default Prompt;

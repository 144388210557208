import React, { useState, useEffect } from "react";
import _ from "lodash";
import {Row, Select, Button} from "antd";
import {DownOutlined} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { connect } from "../../../StateProvider";
import routes from "../../../../routes";

import Icon from '../../../common/UI/Icon';

import styles from "./fullMenu.less";

const Companies = props => {
  let {
    companies,
    company,
    isAdmin,
    hideMenu
  } = props;
  const { t } = useTranslation();
  const showAddCompanyButton = company.get("canCreateCompany");
  const currentDomain = company.get("domain");
  const [items, setItems] = useState([]);

  const createCompany = () => {
    window.open(routes.register.path);
  };
  const onSelect = (value, option) => {
    // timeout to prevent ui freeze
    setTimeout( () => {
        window.location = option.data.get("url");
    }, 0 );
    hideMenu && hideMenu()
  };

  // group Companies by Accounts
  useEffect(
    () => {
        let groupedItems = {};
        
        if (!companies) {
            return;
        } 

        // prepare
        companies = companies.sort
            ? companies.sort((a, b) => a.get("title").localeCompare(b.get("title")))
            : companies;
        
        // parse
        companies.forEach((company, index) => {
            const user = company.get("user");

            // create group
            if (!groupedItems[user]) {
                groupedItems[user] = {
                    key: "user:" + user,
                    label: user,
                    options: [],
                }
            }
        
            // create item
            const item = { 
                value: company.get("domain"), 
                title: company.get("title") + ":" + company.get("domain"),
                data: company,
                label: (
                    <div className={styles.companyTitle}>
                        {company.get("title")}
                        <span className={styles.companyDomain}>{company.get("domain")}</span>
                    </div>
                ), 
            };
            groupedItems[user].options.push(item)
        });

        // sort
        groupedItems = _.sortBy(groupedItems, "label")
        groupedItems = _.values(groupedItems);

        setItems(groupedItems);
    },
    [companies]
  );

  return (
    <Row>
        {companies && companies.size == 1 ? (
          <h2 className={styles.headerTitle}>{companies.get(0).get("title")}</h2>
        ) : (
        <Select
            className={styles.companiesSelect}
            options={items}
            value={{label: company.get("title"), value: company.get("domain")}}
            showSearch
            filterOption={(input, option) =>
                String(option.title || '').toLowerCase().includes(input.toLowerCase())
              }
            suffixIcon={<DownOutlined />}
            notFoundContent={""}
            bordered={false}
            onSelect={onSelect}
        />
        )}
        {showAddCompanyButton &&
            (isAdmin) && (
            <Button
                onClick={createCompany}
                key="submit"
                type="default"
                className={styles.companiesAddButton}
            >
                <Icon type="icon interface-72" />
                {t("section.menu.createCompany")}
            </Button>
            )}
    </Row>

        
        
  );
};

export default connect(Companies,
  {
    companies: ["companies"],
    company: ["company"],
  }
);

export const getItemByCode = (items, code) => {
  let result = null;
  for (const item of items) {
    if (item.code === code) {
      return item;
    } else if (item.items) {
      result = getItemByCode(item.items, code);
      if (result) {
        return result;
      }
    }
  }
  return result;
};

export default t => ({
  hidden: true,
  element: "bpmn:errorEventDefinition",
  service: "",
  title: t("script.controlls.errorEventDefinition.title"),
  helplink: "",
  class: "event",
  color: "#c42e33",
  icon_scale: 0.7,
  border: 1,
  icon: "anticon-icon edition-66",
  offset_x: -60,
  offset_y: -10,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        theme: "script",
        valueType: "const",
        map: "input"
      }
    }
  ],
  exits: []
});

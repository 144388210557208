import _ from "lodash";

export default function getFilterValues(filters, catalogs, catalogId, user) {
  const FieldApi = require("../models/FieldApi").default;
  let fields = catalogs.getIn([catalogId, "fields"]);
  let fieldPrivilegeCodes = catalogs.getIn([catalogId, "fieldPrivilegeCodes"]);
  let valuesFromFilters = {};
  _.forEach(filters, (fieldFilter, fieldId) => {
    const field = fields.find(f => f.get("id") == fieldId);
    const hidden = field && field.get("hidden");
    // если филда нет или он скрыт ничего не делаеи
    if (!field || hidden) {
      return;
    }

    // api only
    if (field.get("apiOnly")) return;

    // проверка на права
    const editable = fieldPrivilegeCodes.get(fieldId);
    const isNotEditable = editable && editable !== "edit";
    if (isNotEditable) return;

    // конвертация значений фильтров в значение записи
    let values = FieldApi.convertFilterToRecordValue(
      field,
      fieldFilter,
      catalogs,
      user
    );

    // проверка на мултиселектность
    const multiselect = field.getIn(["config", "multiselect"]);
    if (multiselect === false) values = values?.slice(0, 1);
    // применение значечий из записи
    valuesFromFilters[fieldId] = values;
  });
  return valuesFromFilters;
}

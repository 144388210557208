import { Checkbox, Switch } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import useStartTimer from '../../../utils/hooks/useStartTimer.hook';
import { connect } from '../../StateProvider';

import styles from "./timer.less"


const TimerLabel = ({ timeUntilTimerEnd, userSettingsTimer: showTimer, t }) => {
    const { duration } = useStartTimer(timeUntilTimerEnd, showTimer, undefined);

    return (
        <div className={styles.containerTimer}>
            <Checkbox className={styles.settingIcon} defaultChecked={showTimer} checked={showTimer} />
            <span className={styles.autoUpdateTitle}>{t("timer.autoUpdate")}</span>
            <span className={styles.timer}>{duration && moment(duration).format("m:ss")}</span>
        </div>
    );
};

export default withTranslation()(connect(TimerLabel, {
    userSettings: ["userSettings", "catalogs"],
}, (props, { userSettings }) => {
    const timeUntilTimerEnd = props.scene && props.scene.get("timeUntilTimerEnd");
    return {
        userSettingsTimer: userSettings.getIn([
            props.catalogId,
            "viewMode",
            "table",
            "options",
            "timer",
            "value"
        ]),
        timeUntilTimerEnd,
        ...props
    }
}));
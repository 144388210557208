import _ from "lodash";
import SelectAxis from "./Axis";
import FIELD_TYPES from "../../../../../../configs/fieldTypes";

class SelectAxisX extends SelectAxis {
  getDefaultValue(fields) {
    const { availableValues } = this.state;

    if (!availableValues || !availableValues.length) {
      return null;
    }

    if (!fields) {
      return null;
    }

    let field;

    field =
      field || fields.find(field => field.get("type") === FIELD_TYPES.DROPDOWN);
    field =
      field || fields.find(field => field.get("type") === FIELD_TYPES.USER);
    field =
      field || fields.find(field => field.get("type") === FIELD_TYPES.OBJECT);
    field =
      field ||
      fields.find(field => field.get("type") === FIELD_TYPES.RADIOBUTTON);
    field =
      field || fields.find(field => field.get("type") === FIELD_TYPES.STARS);
    field =
      field || fields.find(field => field.get("type") === FIELD_TYPES.CHECKBOXES);
    field =
      field || fields.find(field => field.get("type") === FIELD_TYPES.SWITCH);

    if (!field) {
      return null;
    }

    return _.find(availableValues, item => item.value === field.get("id"));
  }
}

export default SelectAxisX;

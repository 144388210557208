import Immutable from "immutable";
import _ from "lodash";

export default {
    updateChatOption(params, query) {

    },
    updateChatOptionCompleted(body, params, data, query, res, actionParams) {
        const { catalogId, recordId } = params;
    
        this.mergeIn(
            ["records", catalogId, recordId, "chatOptions"],
            Immutable.fromJS(data)
        );

        this.changed();
    },
    updateChatOptionFailed(body, params, data, query, res, actionParams) {

    },
    getChatOptionCompleted(body, params, data, query, res, actionParams) {
        const { catalogId, recordId } = params;
        body = Immutable.fromJS(body);
        this.setIn(
            ["records", catalogId, recordId, "chat", "options"],
            body
        );
        this.changed();
    },
};
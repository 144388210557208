import _ from "lodash";

import { isVisibleByPrivilegeCode } from "./isVisibleByPrivilegeCode";

export const filterByPrivilege = (tutorials, privilege) => {
  let result = _.each(tutorials, item =>
    _.each(
      item.items,
      e =>
        (e.items = _.filter(e.items, item => {
          return isVisibleByPrivilegeCode(privilege, item);
        }))
    )
  );
  return result;
};

import FIELD_TYPES from "../../../../../configs/fieldTypes";

const tableControlFieldConfigMappers = {
    [FIELD_TYPES.OBJECT]: require("./Object")[
      "default"
    ]
  };

export default (field) => {
  if ( ! field ) {return}
  
  const type = field.get("type");
  const mapper = tableControlFieldConfigMappers[type];

  return mapper ? mapper(field): field;
};

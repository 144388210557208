import React from 'react';
import ColorPicker from "rc-color-picker";
import _ from 'lodash';

import LicenseInfo from './License';
import Icon from '../../../../common/UI/Icon';

import styles from "./companyInfo.less"
import LicenseEmpty from './License/LicenseEmpty';

const CompanyInfoContent = (props) => {

    const { licenseDate, diff: diffInDays, redTitleDate } = props.remainsLicense;
    const { redTitlePercentage, recordsRemained, recordsRemainingPercentage } = props.remainsRecords;
    const emptyLicense = _.isEmpty(props.remainsLicense);

    return (
        <div className={styles.containerUserMenuLicense} style={{ backgroundColor: props.backgroundColor }}>
            <div className={styles.content}>
                <div className={styles.changeBackground}>
                    <span title={props.companyName} className={styles.nameCompanyUserMenu}>{props.companyName}</span>
                    {ColorPicker && (
                        <ColorPicker
                            color={props.backgroundColor}
                            onChange={props.changeHandler}
                            onOpen={(show) => props.listenerIsTheChildPopupOpen(show?.open)}
                            onClose={(show) => props.listenerIsTheChildPopupOpen(show?.open)}
                            enableAlpha={false}
                            placement="bottomRight"
                            style={{ position: "absolute", zIndex: 99999 }}
                        >
                            <div className={styles.containerForYou}>
                                <span>
                                    {props.t("userMenu.background.backgroundFromYou")}
                                </span>
                                <Icon type="icon arrows-triangle-big-1-01" className={styles.iconForYou} />
                            </div>
                        </ColorPicker>
                    )}
                </div>
                {emptyLicense ? (
                    <LicenseEmpty t={props.t} />
                ) : (
                    <LicenseInfo
                        licenseDate={licenseDate.format("LL")}
                        diffInDays={diffInDays}
                        recordsLimit={props.recordsLimit}
                        recordsRemained={recordsRemained}
                        redTitleDate={redTitleDate}
                        redTitlePercentage={redTitlePercentage}
                        recordsRemainingPercentage={recordsRemainingPercentage}
                        usersCompanyCount={props.usersCompanyCount}
                        isAdmin={props.isAdmin}
                        email={props.email}
                        t={props.t}
                        setFeedback={props.setFeedback}
                        feedback={props.feedback}
                        requestProcessed={props.requestProcessed}
                        feedbackSuccess={props.feedbackSuccess}
                        feedbackError={props.feedbackError}
                        setFeedbackSuccess={props.setFeedbackSuccess}
                        setFeedbackError={props.setFeedbackError}
                        onFinish={props.onFinish}
                        getNumberFromDate={props.getNumberFromDate}
                        downloadTxtFile={props.downloadTxtFile}
                    />
                )}
            </div>
        </div>
    );
};

export default CompanyInfoContent;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".view__container___QQqGn {\n  min-height: inherit;\n  max-width: 100%;\n  flex-grow: 1;\n  height: 100%;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/Catalog/View/view.less"],"names":[],"mappings":"AACA;EACE,mBAAA;EAEA,eAAA;EACA,YAAA;EAEA,YAAA;EACA,kBAAA;AAFF","sourcesContent":["@import (reference) \"../../../../../../../styles/helpers/index.less\";\n.container {\n  min-height: inherit;\n  height: 100%;\n  max-width: 100%;\n  flex-grow: 1;\n  position: relative;\n  height: 100%;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "view__container___QQqGn"
};
export default ___CSS_LOADER_EXPORT___;

export default (t) => {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <bpmn:definitions
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL"
      xmlns:camunda="http://camunda.org/schema/1.0/bpmn"
      xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
      xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
      xmlns:di="http://www.omg.org/spec/DD/20100524/DI" id="Definitions_1"
      targetNamespace="http://bpmn.io/schema/bpmn">
      <bpmn:process id="Process_1" isExecutable="false">
        <bpmn:startEvent id="StartEvent_0fjrq1j" name="${t("script.controlls.startEvent.title")}">
          <bpmn:outgoing>SequenceFlow_1hi6yod</bpmn:outgoing>
        </bpmn:startEvent>
        <bpmn:endEvent id="EndEvent_18hoylb" name="${t("script.controlls.endEvent.title")}">
          <bpmn:incoming>SequenceFlow_1hi6yod</bpmn:incoming>
        </bpmn:endEvent>
        <bpmn:sequenceFlow id="SequenceFlow_1hi6yod" sourceRef="StartEvent_0fjrq1j" targetRef="EndEvent_18hoylb"/>
      </bpmn:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
          <bpmndi:BPMNShape id="StartEvent_0fjrq1j_di" bpmnElement="StartEvent_0fjrq1j">
            <dc:Bounds x="64" y="243" width="64" height="64" />
            <bpmndi:BPMNLabel>
              <dc:Bounds x="96" y="310" width="0" height="13" />
            </bpmndi:BPMNLabel>
          </bpmndi:BPMNShape>
          <bpmndi:BPMNShape id="EndEvent_18hoylb_di" bpmnElement="EndEvent_18hoylb">
            <dc:Bounds x="414" y="243" width="64" height="64" />
            <bpmndi:BPMNLabel>
              <dc:Bounds x="446" y="310" width="0" height="13" />
            </bpmndi:BPMNLabel>
          </bpmndi:BPMNShape>
          <bpmndi:BPMNEdge id="SequenceFlow_1hi6yod_di" bpmnElement="SequenceFlow_1hi6yod">
            <di:waypoint xsi:type="dc:Point" x="128" y="275" />
            <di:waypoint xsi:type="dc:Point" x="414" y="275" />
            <bpmndi:BPMNLabel>
              <dc:Bounds x="321" y="253" width="0" height="13" />
            </bpmndi:BPMNLabel>
          </bpmndi:BPMNEdge>
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </bpmn:definitions>
    `
}
import tinycolor from "tinycolor2";
import { md5 } from "./colorToHash";

export default {
  colorFromString(str) {
    if (!str) {
      return "#000000";
    }
    str = md5(str);
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  },

  vividColorFromString(str) {
    str = md5(str);
    let color = this.colorFromString(str);

    // make vivid
    let tcolor = tinycolor(color).saturate(100);

    // set brightness to 50% (most vivid)
    const lighten = tcolor.toHsl().l * 100;
    tcolor = tcolor.lighten(50 - lighten);

    color = tcolor.toString();
    return color;
  },

  colorAppBackground() {
    const domen = document.location.host;
    return this.vividColorFromString(domen);
  },

  colorUserIcon(name) {
    //name = this.getShortName(name);
    let color = this.vividColorFromString(name);

    let tcolor = tinycolor(color).desaturate(40);

    return tcolor;
  },

  getShortName(userName) {
    userName = userName.split(" ");
    userName = userName.map(i => i.substr(0, 1));
    userName = userName.join("");
    userName = userName.substr(0, 2);
    return userName;
  }
};

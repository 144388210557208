import Reflux from "reflux";
import _ from "lodash";

const log = require("debug")("CRM:Action:recordActions");

let recordActions = Reflux.createActions({
  createRecordCompleted: {},
  openLinkedRecordModal: {},
  generateRecordFromLink: {},
  setRecord: {},
  generateNewRecord: {},
  generateRecord: {},
  updateValues: { sync: true },
  raiseChanges: { sync: true },
  rollBackValues: { sync: true },
  clearRecordsCollection: { sync: true },
  requestForRecords: { sync: true },
  requestForExportRecords: { sync: true },
  requestForRecordsImmediate: { sync: true },

  requestForCalendarRecords: { sync: true },
  requestForCalendarRecordsImmediate: { sync: true },

  setRecordHiddenFields: { sync: true },
  setDefaultValues: { sync: true },
  calcRecordHiddenFields: { sync: true },

  validateAndSaveRecord: { sync: true },
  saveRecord: { sync: true },
  getRecordIsNotLoaded: { sync: true },
  validateRecord: { sync: true },
  validateRecordField: { sync: true },
  updateErrorFields: {},
  updateErrorField: {},
  clearErrorField: {},
  clearErrors: {},
  moveFocusToError: {},

  cloneRecord: {},
  createNewRecord: {},
  clearRecordValues: { sync: true },
  getChangedFields: { sync: true },
  setShouldReload: { sync: true },
  setTimeUntilTimerEnd: { sync: true },
  removeParent: { sync: true },

  backUpRecordValue: { sunc: true },
  clearRecord: { sync: true },
  clearAllRecords: { sync: true }
});

_.forEach(recordActions, (act, name) => {
  act.preEmit = function preEmit(...args) {
    log(name, args);
  };
});

export default recordActions;

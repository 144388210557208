import React from "react";
import _ from "lodash";
import { Popover, Button } from "antd";
import { withTranslation } from "react-i18next";

import Icon from '../../../../../../../../common/UI/Icon';
import { connect } from "../../../../../../../../StateProvider";
import FilterList from "../../../CatalogBody/FilterList";

import UrlParamSync from "../../../../../../../../UrlParamSync";
import filterActions from "../../../../../../../../../actions/filterActions";

import styles from "./PopoverFilter.less";
class PopoverFilter extends React.Component {
  state = {};

  onFocus = () => {
    this.setState({
      showTitle: true
    });
  };
  onFiltersChange = filters => {
    const { sceneId, catalogId, viewId } = this.props;
    filterActions.updateFieldFilters({ sceneId, catalogId, viewId }, filters);
  };

  render() {
    const {
      catalog,
      filters,
      filtersFromScene,
      sceneId,
      viewId,
      filtersCount,
      readOnly,
      filtersChanged
    } = this.props;

    return (
      <Popover
        placement="bottomLeft"
        style={{ padding: "0" }}
        autoAdjustOverflow={false}
        overlayClassName={styles.overlay}
        onFocus={this.onFocus}
        trigger="click"
        content={
          <div className={styles.innerContent}>
            <FilterList
              catalog={catalog}
              sceneId={sceneId}
              viewId={viewId}
              additionalFilters={filters}
              withHeader={true}
              readOnly={readOnly}
            />
          </div>
        }
      >
        <Button
          className={styles.filtersPopoverButton}
          title={this.props.t("views.filters")}
        >
          <Icon className={styles.icon} type={"icon content-47"} />

          {/* указание числа по скольким полям идет фильтрация */}
          {filtersCount > 0 ? (
            <small className={styles.filtersPopoverCount}>{filtersCount}</small>
          ) : null}
        </Button>
        <UrlParamSync
          name="filters"
          value={filtersChanged ? filtersFromScene : undefined}
          base64={true}
          onChange={this.onFiltersChange}
        />
      </Popover>
    );
  }
}

export default withTranslation()(
  connect(
    PopoverFilter,
    {
      scenes: ["scenes"]
    },
    (props, { scenes }) => {
      const { sceneId, viewId, catalog } = props;
      const catalogId = catalog && catalog.get("id");
      const filtersChanged =
        scenes && scenes.getIn([sceneId, "views", viewId, "filtersChanged"]);
      const filtersFromData =
        scenes.get(sceneId) && scenes.getIn([sceneId, "data", "filters"]);

      const filtersFromView =
        scenes.get(sceneId) &&
        scenes.getIn([sceneId, "views", viewId, "filters"]);

      const filtersFromViewSize =
        (filtersFromView && filtersFromView.size) || 0;
      const filtersFromDataSize =
        (filtersFromData && filtersFromData.size) || 0;

    const filtersCount = filtersFromViewSize + filtersFromDataSize;
    const filtersFromScene = scenes && scenes.getIn([sceneId, "views", viewId, "filters"]);
    return {
      ...props,
      filters: filtersFromData,
      filtersFromScene,
      filtersChanged,
      catalogId,
      filtersCount
    };
  }
));

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userInfo__containerUserMenuAbout___7ClF7 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.userInfo__containerUserInfo___fJkkO {\n  display: flex;\n  flex-direction: column;\n  padding: 5px 0;\n}\n.userInfo__userName___QHpsr {\n  font-size: 13px;\n  color: #282828;\n  line-height: normal;\n}\n.userInfo__userEmail___noxbu {\n  font-size: 11px;\n  color: #7d7f80;\n  line-height: normal;\n}\n/* Logout */\n.userInfo__btnExit___F3LHe {\n  padding: 0;\n}\n.userInfo__containerLogout___h8vSb {\n  color: #7d7f80;\n  padding: 0;\n  display: inline-flex;\n  align-items: baseline;\n  gap: 5px;\n  font-size: 13px;\n}\n.userInfo__containerLogout___h8vSb:hover {\n  color: #282828;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppHeader/UserMenu/UserInfo/userInfo.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAIA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;AAFJ;AAKA;EACI,eAAA;EACA,cAAA;EACA,mBAAA;AAHJ;AAMA;EACI,eAAA;EACA,cAAA;EACA,mBAAA;AAJJ;AACA,WAAW;AAQX;EACI,UAAA;AANJ;AASA;EACI,cAAA;EACA,UAAA;EACA,oBAAA;EACA,qBAAA;EACA,QAAA;EACA,eAAA;AAPJ;AAQI;EACE,cAAA;AANN","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\n.containerUserMenuAbout {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.containerUserInfo {\n    display: flex;\n    flex-direction: column;\n    padding: @PADDING_BASE/2 0;\n}\n\n.userName {\n    font-size: @TEXT_SIZE_BASE;\n    color: @TEXT;\n    line-height: normal;\n}\n\n.userEmail {\n    font-size: @TEXT_SIZE_SMALL;\n    color: @TEXT_EXTRA;\n    line-height: normal;\n}\n\n/* Logout */\n\n.btnExit {\n    padding: 0;\n}\n\n.containerLogout {\n    color: @TEXT_EXTRA;\n    padding: 0;\n    display: inline-flex;\n    align-items: baseline;\n    gap: @PADDING_BASE / 2;\n    font-size: @TEXT_SIZE_BASE;\n    &:hover{\n      color: @TEXT;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerUserMenuAbout": "userInfo__containerUserMenuAbout___7ClF7",
	"containerUserInfo": "userInfo__containerUserInfo___fJkkO",
	"userName": "userInfo__userName___QHpsr",
	"userEmail": "userInfo__userEmail___noxbu",
	"btnExit": "userInfo__btnExit___F3LHe",
	"containerLogout": "userInfo__containerLogout___h8vSb"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout__sectionWrapper___sGxzB {\n  height: 100%;\n  position: relative;\n}\n.layout__section___dlbCC {\n  height: 100%;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/layout.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;AAEA;EACE,YAAA;EACA,kBAAA;AAAF","sourcesContent":[".sectionWrapper {\n  height: 100%;\n  position: relative;\n}\n\n.section {\n  height: 100%;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapper": "layout__sectionWrapper___sGxzB",
	"section": "layout__section___dlbCC"
};
export default ___CSS_LOADER_EXPORT___;

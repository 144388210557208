import _ from "lodash";

export function createElementsHelperDeclarative(
  factory,
  { type, options, children },
  parent = null
) {
  const element = factory.create(type, options);
  if (children) {
    const childOptions = _.reduce(
      children,
      (res, child, key) => {
        if (_.isArray(child)) {
          if (!_.isArray(key)) {
            res[key] = [];
          }

          child.forEach(child => {
            res[key].push(
              createElementsHelperDeclarative(factory, child, element)
            );
          });
        } else {
          res[key] = createElementsHelperDeclarative(factory, child, element);
        }
        return res;
      },
      {}
    );

    _.forEach(childOptions, (value, key) => {
      element.set(key, value);
    });
  }
  if (parent) {
    element.$parent = parent;
  }
  return element;
}

export function createElementsHelper(factory, type, options, parent = null) {
  const element = factory.create(type);
  const childCreator = (type, options) =>
    createElementsHelper(factory, type, options, element);
  const mappedOptions = _.isFunction(options)
    ? options(childCreator)
    : _.mapValues(
        options,
        option =>
          _.isFunction(option)
            ? option(childCreator)
            : _.isArray(option)
              ? option.map(
                  option =>
                    _.isFunction(option) ? option(childCreator) : option
                )
              : option
      );

  _.forEach(mappedOptions, (value, key) => {
    element.set(key, value);
  });

  if (parent) {
    element.$parent = parent;
  }

  return element;
}

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import PropTypes from "prop-types";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import userSettingsActions from "../../../../actions/userSettingsActions";
import HelperContent from "./HelperContent";
import HelpIcon from "../../../common/HelpIcon";
import { connect } from "../../../StateProvider";
import uiActions from "../../../../actions/uiActions";
import ButtonTransparent from "../../UI/ButtonTransparent";

import styles from "./styles/helper.less";

const PRIVILEGE_CODE = {
  ADMIN: "admin",
  USER: "user"
};

const alignConfig = {
  points: ["tr", "br"],        // align top right point of sourceNode with bottom right point of targetNode
  offset: [15, 4],            // the offset sourceNode by 15px in x and 4px in y,
  targetOffset: ["0","20%"], // the offset targetNode by 0% of targetNode width in x and 20% of targetNode height in y,
  overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
};

const Helper = ({ isOpen, shownHelpers, sections, code = "" }) => {
  // privilege to see tutorials
  const [privilegeCode, setPrivilegeCode] = useState(null);
  const { t } = useTranslation();

  const handleVisibleChange = show => {
    uiActions.toggleHelper(show);
  };

  const needToDisplay = async () => {
    // shownHelpers is Null by default, so if its null still it means we still loading
    const isLoaded = !_.isNull(shownHelpers);

    if (!code) return;
    if (!isLoaded) return;

    let visited = shownHelpers && shownHelpers.get(code);
    if (!visited) {
      // Save visited code
      userSettingsActions.setKey(["ui", "shownHelpers", code], true);
      handleVisibleChange(true);
    }
  };

  // if code is changed...
  useEffect(
    () => {
      if (code) {
        needToDisplay();
      }
    },
    [code, needToDisplay, shownHelpers]
  );

  useEffect(
    () => {
      /**
       * If user have admin rights for atleast one sections
       * he can see all tutorisla about system
       * In other case only user's tutorisla available
       */
      const adminSections = sections.filter(
        section => section.get("privilegeCode") === PRIVILEGE_CODE.ADMIN
      );

      if (adminSections && adminSections.size !== 0) {
        setPrivilegeCode(PRIVILEGE_CODE.ADMIN);
      } else {
        setPrivilegeCode(PRIVILEGE_CODE.USER);
      }
    },
    [sections]
  );

  return (
    <Popover
      align={alignConfig}
      onOpenChange={handleVisibleChange}
      open={isOpen}
      content={
        <HelperContent
          visible={isOpen}
          code={code}
          privilegeCode={privilegeCode}
        />
      }
      className={styles.popoverWrapper}
      trigger="click"
      placement="bottomLeft"
      overlayClassName={styles.popoverWrapperContent}
    >
      <ButtonTransparent
        onClick={() => handleVisibleChange(!isOpen)}
        className={cn(styles.mainWidgetButton, {
          [styles.helpIconBadge]: !!code
        })}
        light
        title={t("helper.title")}
      >
        <HelpIcon />
      </ButtonTransparent>
    </Popover>
  );
};

Helper.propTypes = {
  code: PropTypes.string
};

export default connect(
  Helper,
  {
    sections: ["sections"],
    isOpen: ["ui", "helper", "isOpen"],
    code: ["ui", "helper", "code"],
    shownHelpers: ["userSettings", "ui", "shownHelpers"]
  }
);

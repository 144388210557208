import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import guid from "guid";

import HashMemoryRouter from "../common/router/HashMemoryRouter";
import getConfirmation from "../common/Modal/getConfirmation";
import NavLink from "../common/router/Link";
import routes from "../../routes";
import { connect } from "../StateProvider";
import getValuesFromLinkedRecord from "../../stores/helpers/records/getValuesFromLinkedRecord";
import sceneActions from "../../actions/sceneActions";
import recordActions from "../../actions/recordActions";

class LinkedRecordCreate extends React.PureComponent {
  static propTypes = {
    catalogId: PropTypes.string.isRequired,
    viewId: PropTypes.string,
    values: PropTypes.object,
    onCreate: PropTypes.func,
    silent: PropTypes.bool
  };

  constructor(props) {
    super(props);
    const sectionId = this.props.catalogs.getIn([props.catalogId, "sectionId"]);
    this.state = {
      catalogId: props.catalogId,
      sectionId
    };
  }

  openNewRecord = () => {
    const {
      fromCatalogId,
      fromRecordId,
      catalogId,
      silent,
      parentSceneId
    } = this.props;
    let { values } = this.props;

    if (fromCatalogId && fromRecordId) {
      values = getValuesFromLinkedRecord(
        fromCatalogId,
        fromRecordId,
        catalogId,
        values
      );
    }

    if (silent) {
      const newRecordId = guid.raw();
      recordActions.generateNewRecord(
        catalogId,
        newRecordId,
        values,
        this.onCreate
      );
    } else {
      sceneActions.openNewRecord({ catalogId, parentSceneId }, values, {
        onCreateRecord: this.onCreate,
        onClose: this.onClose
      });
    }
  };

  onClose = (...args) => {
    this.props.onClose && this.props.onClose(...args);
  };

  onCreate = (...args) => {
    this.props.onCreate && this.props.onCreate(...args);
  };

  onClick = e => {
    e.preventDefault();
    this.openNewRecord();
  };

  render() {
    if (!this.state.sectionId && this.state.catalogId !== "$scripts") {
      return null;
    }
    const { linkProps, t } = this.props;
    return (
      <HashMemoryRouter
        initialEntries={[routes.record.path]}
        initialIndex={1}
        getUserConfirmation={(message, callback) =>
          getConfirmation(message, callback, null, undefined, t)
        }
      >
        <NavLink
          route={routes.record}
          params={{
            sectionId: this.state.sectionId || "0",
            catalogId: this.state.catalogId,
            recordId: "$new"
          }}
          render={({ link }) => {
            return <Link onClick={this.onClick} to={link} {...linkProps} />;
          }}
        />
      </HashMemoryRouter>
    );
  }
}

export default connect(
  withTranslation()(LinkedRecordCreate),
  ["catalogs"]
);

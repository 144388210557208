import _ from "lodash";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import cn from "classnames";
import { CSSTransitionGroup } from "react-transition-group";
import { Modal, Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import EmptyRules from "./EmptyRules";
import HelperToggler from "../common/widgets/Helper/HelperToggler";
import { connect } from "../StateProvider";
import DropdownRemote from "../common/DropdownRemote";
import apiActions from "../../actions/apiActions";
import availableLinkedRecordActions from "../../actions/availableLinkedRecordActions";
import HelpIcon from "../common/HelpIcon";
import ButtonClose from "../common/UI/ButtonClose";
import AccessRules from "./AccessRules";

import PRIVILEGE_CODES from "../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../configs/resourceTypes";

import PublicAccess from "./publicAccess";

import styles from "./access.less";
import { privilegeCodesComparator } from "../../utils/rights";

const log = require("debug")("CRM:Component:Rights:Modal");

const MIN_RULES = 7;

const PolicyHelpIcon = connect(
  HelpIcon,
  {
    helpPath: ["vendor", "docsPolicyPath"]
  }
);

class AccessModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rules: this.mergeRules(this.props.rules || new Immutable.List()),
      selectedSubjects: new Immutable.List(),
      isLoading: true
    };
  }

  mergeRules(rules) {
    const newList = [];

    rules.forEach(rule => {
      const subject = rule.get("rightSubject").toJS();
      const privilegeCode = rule.get("privilegeCode");
      const existItem = newList.find(item => _.isEqual(item.subject, subject));

      const hasAvailableRule =
        rule.get("hasAvailableRule") ||
        privilegeCode === PRIVILEGE_CODES.AVAILABLE ||
        privilegeCode === PRIVILEGE_CODES.SEARCH;

      if (existItem) {
        existItem.privilegeCodes.push(privilegeCode);
        if (hasAvailableRule) {
          existItem.hasAvailableRule = true;
        }
      } else {
        newList.push({
          subject,
          rule,
          privilegeCodes: [privilegeCode],
          hasAvailableRule
        });
      }
    });

    return new Immutable.List(
      newList.map(({ rule, privilegeCodes, hasAvailableRule }) => {
        const maxPrivilegeCode = privilegeCodes
          .sort(privilegeCodesComparator)
          .pop();

        return rule
          .set("hasAvailableRule", hasAvailableRule)
          .set("privilegeCode", maxPrivilegeCode);
      })
    );
  }

  updateState = obj => {
    // merge subjects with search privilege and other and remove search rules
    if (obj.rules) {
      obj.rules = this.mergeRules(obj.rules);
    }

    return this.setState(obj);
  };

  onAddSubjects = items => {
    log("add subjects", items);
    if (items.length == 0) {
      return;
    }
    //let items = this.state.selectedSubjects.toJS();
    let newRules = items.map(item => {
      return Immutable.fromJS({
        rightSubject: item.subject,
        privilegeCode: PRIVILEGE_CODES.EDIT
      });
    });

    this.updateState({
      rules: this.state.rules.push(...newRules),
      selectedSubjects: Immutable.fromJS([])
    });
  };

  filterAvailableSubjects = item => {
    return !this.state.rules.find(rule => {
      return _.isEqual(rule.getIn(["rightSubject"]).toJS(), item.subject);
    });
  };

  onClickRemoveRight = index => {
    if (this.state.rules.getIn([index, "hasAvailableRule"])) {
      this.onChangePrivilege(index, PRIVILEGE_CODES.AVAILABLE);
    } else {
      this.updateState({
        rules: this.state.rules.splice(index, 1)
      });
    }
  };

  onSave = () => {
    let data = {
      object: this.props.object,
      rules: this.state.rules
    };

    apiActions.createRight({}, data);
    this.props.onOk(this.props.object);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newRules = nextProps.rules;

    if (this.props.rules !== newRules) {
      log("new rules from props", newRules);
      this.updateState({
        rules: newRules || Immutable.fromJS([])
      });
    }

    this.updateState({
      isLoading: nextProps.isLoading
    });
  }

  componentDidMount() {
    let object = this.props.object;
    let query = {
      withSearch: true
    };

    apiActions.getRights({}, _.extend({}, query, object));

    // get rules for parent objects
    this.props.parents.forEach(ro => {
      apiActions.getRights({}, _.extend({}, query, ro.get("object").toJS()));
    });

    if (!(this.props.privilegeCodes && this.props.privilegeCodes.size)) {
      apiActions.getPrivileges();
    }
  }

  onChangePrivilege = (indexOfRule, items) => {
    let privilegeCode = _.get(items, "[0].key", items);
    this.updateState({
      rules: this.state.rules
        .setIn([indexOfRule, "privilegeCode"], privilegeCode)
        .setIn([indexOfRule, "fields"], Immutable.fromJS({}))
    });
  };

  onChangeRule = (indexOfRule, rule) => {
    this.updateState({
      rules: this.state.rules.set(indexOfRule, rule)
    });
  };

  render() {
    const { readOnly, onCancel, resource, object, t } = this.props;
    const { isLoading, rules } = this.state;
    let error, footer;

    const addSubjects = (
      <Row type="flex" className={styles.addSubjectsRow}>
        <span className="anticon-icon interface-69" />
        <Col xs={24}>
          <DropdownRemote
            bordered={false}
            type="subjects"
            filterFn={this.filterAvailableSubjects}
            onSelectItems={this.onAddSubjects}
            loadAvailableItems={availableLinkedRecordActions.loadAvailableItems}
            clearAvailableItems={
              availableLinkedRecordActions.clearAvailableItems
            }
            placeholder={t("modals.access.selectSubject")}
            className={cn("select-transparent")}
          />
        </Col>
      </Row>
    );

    let parentRules = new Immutable.List();

    this.props.parents.forEach(ro => {
      let parentObjectRules = ro.get("rules");
      parentObjectRules &&
        parentObjectRules.forEach(rule => {
          rule = rule.set("fromObject", ro.get("object"));

          if (
            rule.get("privilegeCode") === PRIVILEGE_CODES.AVAILABLE ||
            rule.get("privilegeCode") === PRIVILEGE_CODES.SEARCH
          ) {
            return;
          }

          if (resource === RESOURCE_TYPES.VIEW) {
            if (rule.get("privilegeCode") === PRIVILEGE_CODES.ADMIN) {
              parentRules = parentRules.push(rule);
            }
          } else {
            parentRules = parentRules.push(rule);
          }
        });
    });

    if (this.props.hasAdminRule) {
      const adminRule = rules.find(
        rule => rule.get("privilegeCode") === PRIVILEGE_CODES.ADMIN
      );
      if (!adminRule) {
        error = (
          <span className={styles.footerTextError}>
            {t("modals.access.errors.adminPrivilegeRequired")}
          </span>
        );
      }
    }

    if (error && !isLoading) {
      footer = error;
    } else {
      footer = [
        readOnly ? null : (
          <Button key="submit" type="primary" onClick={this.onSave}>
            {t("modals.save")}
          </Button>
        ),
        <Button key="back" type="default" onClick={onCancel}>
          {readOnly ? t("modals.close") : t("modals.cancel")}
        </Button>,
        <PublicAccess key="link" resource={resource} object={object} />
      ];
    }

    return (
      <Modal
        open={true}
        maskClosable={false}
        closable={false}
        footer={footer}
        width="60%"
      >
        <div>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className={styles.headerModal}
          >
            <Row type="flex" align="middle">
              <h2 className={styles.headerModalTitle}>
                {t("modals.access.header")}{" "}
                {t("modals.access.headerSuffix." + this.props.resource)}
              </h2>
              <HelperToggler href="https://docs.bpium.ru/manual/rights/groups" />
            </Row>
            <ButtonClose large shiftRight onClick={onCancel} />
          </Row>
          <div className={styles.bodyModal}>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className={styles.stuff}
            >
              <Col xs={14}>
                <strong>{t("modals.access.stuffs")}</strong>
              </Col>
              <Col xs={8} className={styles.privilegeTitle}>
                <strong>{t("modals.access.privilege")}</strong>
              </Col>
              <Col xs={2}>
                <CSSTransitionGroup
                  transitionEnterTimeout={300}
                  transitionLeaveTimeout={300}
                  transitionName={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    leave: styles.leave,
                    leaveActive: styles.leaveActive
                  }}
                >
                  {isLoading ? (
                    <span className={styles.mutedField}>
                      {t("modals.access.loading")}
                    </span>
                  ) : null}
                </CSSTransitionGroup>
              </Col>
            </Row>
            <div className={styles.rulesContainer}>
              <AccessRules
                rules={parentRules}
                parentCatalog={this.props.parentCatalog}
                parentSection={this.props.parentSection}
                resource={resource}
                privilegeCodes={this.props.privilegeCodes}
                readOnly={true}
              />

              <AccessRules
                rules={this.state.rules}
                resource={this.props.resource}
                privilegeCodes={this.props.privilegeCodes}
                parentCatalog={this.props.parentCatalog}
                object={this.props.object}
                catalogs={this.props.catalogs}
                readOnly={readOnly}
                isAdmin={this.props.isAdmin}
                onClickRemoveRight={this.onClickRemoveRight}
                onChangePrivilege={this.onChangePrivilege}
                onChangeRule={this.onChangeRule}
              />

              {readOnly ? null : addSubjects}
              <EmptyRules
                count={MIN_RULES - this.state.rules.size - parentRules.size}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

AccessModal.defaultProps = { privilegeCodes: new Immutable.List() };

AccessModal.propTypes = {
  resource: PropTypes.string.isRequired,
  privilegeCodes: PropTypes.object,
  object: PropTypes.object.isRequired,
  parentCatalog: PropTypes.object,
  parentSection: PropTypes.object,
  rules: ImmutablePropTypes.list,
  parents: ImmutablePropTypes.list,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasAdminRule: PropTypes.bool,
  catalogs: PropTypes.object,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isValidRules: PropTypes.func
};

export default withTranslation()(AccessModal);

export default t => ({
  element: "bpmn:ServiceTask",
  service: "bpiumSendMessage",
  title: t("script.controlls.bpiumSendMessage.title"),
  helplink: "",
  class: "bpium",
  color: "#7cab9e",
  border: 2,
  icon: "anticon-icon communication-89",
  icon_scale: 0.95,
  offset_x: 0,
  offset_y: -16,
  toolbar_offset_y: -15,
  toolbar_offset_x: 0,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.bpiumSendMessage.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "messageLocation",
      name: t("script.controlls.bpiumSendMessage.messageLocation"),
      type: "group"
    },
    {
      id: "catalogFormat",
      name: t("script.common.enterCatalog"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "catalog",
            name: t("script.common.fromList"),
            color: "E7E9ED"
          },
          {
            id: "var",
            name: t("script.common.fromVariables"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["catalog"],
        map: "input"
      }
    },
    {
      id: "catalogId",
      name: t("script.common.catalog"),
      type: "radiobutton",
      readOnly: false,
      config: {
        items: [],
        type: "select",
        data: "catalogs",
        map: "input"
      },
      visible: {
        catalogFormat: {
          $include: "catalog"
        }
      }
    },
    {
      id: "catalogIdFromVar",
      name: t("script.common.catalogId"),
      type: "text",
      readOnly: false,
      config: {
        placeholder: t("script.common.numberExpression"),
        type: "text",
        theme: "script",
        map: "input"
      },
      visible: {
        catalogFormat: {
          $include: "var"
        }
      }
    },
    {
      id: "recordId",
      name: t("script.common.recordId"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: t("script.common.numberExpression")
      }
    },
    {
      id: "messageGroup",
      name: t("script.controlls.bpiumSendMessage.messageGroup"),
      type: "group"
    },
    {
      id: "text",
      name: t("script.controlls.bpiumSendMessage.messageText"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "attachments",
      name: t("script.controlls.bpiumSendMessage.attachments"),
      type: "text",
      hint: t("script.controlls.bpiumSendMessage.attachmentsHint"),
      config: {
        type: "multiline",
        theme: "script",
        map: "input",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "mentions",
      name: t("script.controlls.bpiumSendMessage.mentions"),
      hint: t("script.controlls.bpiumSendMessage.mentionsHint"),
      type: "text",
      config: {
        type: "text",
        theme: "script",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "author",
      name: t("script.controlls.bpiumSendMessage.author"),
      hint: t("script.controlls.bpiumSendMessage.authorHint"),
      type: "text",
      config: {
        type: "text",
        theme: "script",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "replyMessageId",
      name: t("script.controlls.bpiumSendMessage.replyMessageId"),
      hint: t("script.controlls.bpiumSendMessage.replyMessageIdHint"),
      type: "text",
      config: {
        type: "text",
        theme: "script",
        map: "replyMessageId",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "resultSection",
      name: t("script.common.result"),
      type: "group"
    },
    {
      id: "output",
      name: t("script.common.saveTo"),
      type: "text",
      hint: t("script.controlls.bpiumSendMessage.outputHint"),
      readOnly: false,
      config: {
        type: "text",
        map: "output",
        placeholder: t("script.common.variableName")
      }
    }
  ],
  exits: []
});

import React, { Component } from "react";
import ModalViewer from "../Modal";

const viewerComponent = file => {
  const url = file.url;

  const iframe = (
    <iframe src={url} width="100%" height="100%">
      <embed src={url} type="application/pdf" width="100%" height="100%" />
    </iframe>
  );
  return iframe;
};

export default class PDFViewer extends Component {
  static priority = 4;
  static viewerName = "PDFViewer";

  render() {
    return <ModalViewer viewerComponent={viewerComponent} {...this.props} />;
  }
}

import _ from "lodash";
import React, { Component, lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { matchPath } from "react-router";
import { Row } from "antd";

import apiActions from "../../../../actions/apiActions";
import uiActions from "../../../../actions/uiActions";
import { connect } from "../../../StateProvider";

import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../utils/rights";

import SectionBody from "./SectionBody";

import styles from "./section.less";
import Loading from "../../../common/Loading";

const CatalogsMenu = lazy(() => import("./CatalogsMenu/index"));
const SectionSettings = lazy(() => import("./SectionSettings"));

class Section extends Component {
  componentDidMount() {
    const { sectionId } = this.props.match.params;

    if (sectionId) {
      apiActions.getSection({ sectionId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSectionId = nextProps.match.params.sectionId;

    if (newSectionId && newSectionId !== this.props.match.params.sectionId) {
      // update catalogs.
      apiActions.getSection({ sectionId: newSectionId });
    }
  }

  componentDidUpdate(prevProps) {
    this.showHelper();
    if (!_.isEqual(prevProps.location, this.props.location)) {
      uiActions.unsetHelperCode("sectionCatalogCreation");
    }
  }

  showHelper() {
    const { catalogs, match } = this.props;
    const { sectionId } = match.params;
    const currentCatalogs =
      catalogs &&
      catalogs.filter(catalog => catalog.get("sectionId") == sectionId);
    // Check if section loaded and no catalogs present
    if (
      catalogs &&
      catalogs.size &&
      currentCatalogs &&
      currentCatalogs.size === 0
    ) {
      uiActions.showHelper("sectionCatalogCreation");
    }
  }

  render() {
    const { hoveredSectionId, favoriteCatalogs } = this.props;

    const realSectionId = this.props.match.params.sectionId;
    const sectionId = hoveredSectionId ? hoveredSectionId : realSectionId;
    const ghostMode = hoveredSectionId && realSectionId != hoveredSectionId;


    const section = this.props.sections.get(sectionId);
    const catalogs = this.props.catalogs
      .valueSeq()
      .filter(
        c => sectionId === "$favorite" ? favoriteCatalogs.indexOf(c.get("id")) > -1 : c.get("sectionId") === sectionId 
      )  
      // show catalogs with privilegeCode > available (search, view, ...)
      .filter( c => c.get("privilegeCode") !== "available" )
      .sortBy(c => c.get("index"))

    const firstCatalog = catalogs.get(0);
    
    // todo: check access for
    const isAccessAdmin = checkAccessOnObject(
      RESOURCE_TYPES.SECTION,
      section,
      PRIVILEGE_CODES.ADMIN
    );

    return (
      <section className={styles.container}>
        <Row
          type="flex"
          justify="start"
          align="middle"
          className={styles.sectionHeaderWrapper}
        > 
          <Suspense fallback={<Loading />}>
            <CatalogsMenu
              sectionId={sectionId}
              catalogs={catalogs}
              isAccessAdmin={isAccessAdmin}
              history={this.props.history}
              location={this.props.location}
            />
            <SectionSettings
              section={section}
              className={styles.sectionSettings}
            />
          </Suspense>  
        </Row>

        <SectionBody
          firstCatalog={firstCatalog}
          ghostMode={ghostMode}
        />
      </section>
    );
  }
}

export default connect(
  Section,
  {
    sections: ["sections"],
    catalogs: ["catalogs"],
    hoveredSectionId: ["ui", "hoveredSectionId"],
    favoriteCatalogs: ["userSettings", "ui", "favoriteCatalogs"]
  }
);

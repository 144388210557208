module.exports = {
  SIDEBAR_ITEM: "sidebarItem",
  FIELD_TYPE: "type",
  FIELD: "field",
  DROPDOWN_ITEM: "dropdownItem",
  TABLE_FIELD: "catalogField",
  IMPORT_FIELD: "importField",
  GRID: "grid",
  CALENDAR_EVENT: "calendarEvent",
  BATCH_UPDATE_RESUL_COMPONENT: "batch_update_result_component"
};

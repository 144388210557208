import Immutable from "immutable";

export function subMenuItemCreate({ place, mount, unmount }) {
  switch (place) {
    case "header.subMenu":
      this.updateIn(["headerSubMenu"], list =>
        list.push(
          Immutable.fromJS({
            id: Math.random(),
            place,
            mount,
            unmount
          })
        )
      );
      break;
    default:
      throw new Error(`Unknown place "${place}"`);
  }
  this.changed();
}

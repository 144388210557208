// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userMenu__containerUserMenu___1ZiKy {\n  padding-right: 15px;\n}\n.userMenu__dropdownItems___BDrun {\n  max-width: 410px !important;\n  top: 25px !important;\n}\n.userMenu__dropdownItems___BDrun .ant-popover-content {\n  padding-right: 15px;\n}\n.userMenu__dropdownItems___BDrun .ant-popover-title {\n  padding: 5px 10px 5px;\n}\n.userMenu__dropdownItems___BDrun .ant-popover-inner-content {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppHeader/UserMenu/userMenu.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAIA;EACE,2BAAA;EACA,oBAAA;AAFF;AAAA;EAMM,mBAAA;AAHN;AAHA;EASM,qBAAA;AAHN;AANA;EAYM,UAAA;AAHN","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.containerUserMenu {\n  padding-right: 15px;\n}\n\n.dropdownItems {\n  max-width: 410px !important;\n  top: 25px !important;\n\n  :global {\n    .ant-popover-content {\n      padding-right: 15px;\n    }\n    .ant-popover-title {\n      padding: @PADDING_BASE / 2 @PADDING_BASE @PADDING_BASE / 2;\n    }\n    .ant-popover-inner-content {\n      padding: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerUserMenu": "userMenu__containerUserMenu___1ZiKy",
	"dropdownItems": "userMenu__dropdownItems___BDrun"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import ObjectSelector from "./ObjectSelector";
import { useTranslation } from "react-i18next";

const UserSelector = props => {
  const { t } = useTranslation();
  return (
    <ObjectSelector
      remoteGroup={props.field.get("elementsRemoteGroup")}
      placeholder={props.placeholder || t("record.fields.user.addUser")}
      {...props}
    />
  );
};

export default UserSelector;

import _ from "lodash";
import React from "react";
import { withRouter } from "react-router";
import { decode, encode } from "../utils/queryParams";


class UrlParamSync extends React.Component {
    constructor(props) {
        super(props);
        const { name, value } = this.props;
        this.state = {
            [name]: value
        };
    };

    setToURL(name, value) {
        const { history, base64, location } = this.props;

        let search = location.search;
        const searchObject = decode(search, name, base64);

        value = value && value.toJS ? value.toJS() : value;
        if (!this.isEqual(value, searchObject[name]) || (_.isUndefined(value) || _.isUndefined(searchObject[name]))) {
            search = encode(search, name, value, base64);
            location.search = search;
            history.push({ search: location.search });
            this.setState(() => ({ [name]: value }));
        };
    };

    getFromURL(name, location = this.props.location) {
        const { base64 } = this.props;
        let search = location.search;

        const searchObject = decode(search, name, base64);
        return searchObject[name];
    };

    deleteFromURL(name) {
        const { location, base64, value } = this.props;

        let search = location.search;
        search = encode(search, name, value, base64);
        location.search = search;
        this.setState(() => ({ [name]: value }));
        // this.setToURL(name);
    };

    onUrlChanged(name, value) {
        let currentValue = this.state[name];
        currentValue = currentValue && currentValue.toJS ? currentValue.toJS() : currentValue;
        if (!this.isEqual(currentValue, value)) {
            this.setState(() => ({ [name]: value }));
            this.props.onChange && this.props.onChange(value);
        };
    };

    isEqual(value1, value2) {
        return _.isObject(value1)
            ? _.isEqual(value1, value2)
            : String(value1) === String(value2);
    }

    componentDidMount() {
        let { name, value } = this.props;

        // если два компонента отслеживают один параметр при анмаунте один удаляет параметры, второй теряет.
        // тогда мы записываем в url значение из пропсов.
        const urlValue = this.getFromURL(name);
        const isUrlValue = !_.isUndefined(urlValue) && !_.isNull(urlValue);

        if (isUrlValue) {
            this.onUrlChanged(name, urlValue);
        } else if (!isUrlValue && value) {
            this.setToURL(name, value);
        };
    };
    componentDidUpdate(prevProps, prevState) {
        let { name, value } = this.props;
        let { value: prevValue, name: prevName, location: prevLocation } = prevProps;
        // if value changed in url
        const urlValue = this.getFromURL(name);
        const prevUrlValue = this.getFromURL(prevName, prevLocation);

        const isUrlValue = !_.isUndefined(urlValue) && !_.isNull(urlValue);
        // call update props only if param is set in url
        if (isUrlValue && !this.isEqual(urlValue, prevUrlValue)) {
            this.onUrlChanged(name, urlValue);
        };

        // если два компонента отслеживают один параметр при анмаунте один удаляет параметры, второй теряет.
        // тогда мы записываем в url значение из пропсов.
        if (!isUrlValue && value) {
            this.setToURL(name, value);
        };

        // if value changed from parent component
        if (!_.isEqual(value, prevValue)) {
            this.setToURL(name, value);
        };
    };
    componentWillUnmount() {
        const { name } = this.props;
        this.deleteFromURL(name);
    };

    render() {
        return null;
    };
};

const UrlParamSyncMemo = React.memo(UrlParamSync, (next, prev) => _.isEqual(next, prev));
export default withRouter(UrlParamSyncMemo);
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import React, { Component } from "react";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";

import Activities from "../../../Record/Activities";
import { BATCH_RECORDS_STATUS } from "../../../../configs/recordsBatchStatus";
import { connect } from "../../../StateProvider";

class RecordsBatchUpdateActivities extends Component {
  static propTypes = {
    record: ImmutablePropTypes.map.isRequired,
    catalog: ImmutablePropTypes.map.isRequired,
    sceneId: PropTypes.string.isRequired,
    scene: ImmutablePropTypes.map.isRequired,
    hasBeenEdit: PropTypes.bool,
    onClose: PropTypes.func,
    onStartUpdate: PropTypes.func.isRequired,
    onPauseUpdate: PropTypes.func.isRequired
  };

  // getDropDownButtonItems = () => {

  // }

  getMainButton = () => {
    const {
      scene,
      onStartUpdate,
      onPauseUpdate,
      onResumeUpdate,
      selectedFieldsCount,
      t
    } = this.props;
    const updatingStatus = scene && scene.getIn(["data", "batchStatus"]);
    const recordsCount = scene && scene.get("recordsCount");
    let mainButton;

    switch (updatingStatus) {
      case BATCH_RECORDS_STATUS.LOADING:
        mainButton = {
          type: "ghost",
          text: t("batchUpdateRecords.buttons.loading"),
          disabled: true
        };
        break;

      case BATCH_RECORDS_STATUS.READY:
        mainButton = {
          type: "primary",
          text:
            t("batchUpdateRecords.buttons.update") +
            t("record.groupFields.count", { count: +selectedFieldsCount }),
          onClick: onStartUpdate,
          disabled: recordsCount <= 0 || selectedFieldsCount <= 0
        };
        break;

      case BATCH_RECORDS_STATUS.UPDATING:
        mainButton = {
          type: "primary",
          text: t("batchUpdateRecords.buttons.pause"),
          onClick: onPauseUpdate
        };
        break;

      case BATCH_RECORDS_STATUS.PAUSED:
        mainButton = {
          type: "primary",
          text: t("batchUpdateRecords.buttons.resume"),
          onClick: onResumeUpdate
        };
        break;

      case BATCH_RECORDS_STATUS.COMPLETED:
      default:
        mainButton = null;
        break;
    }

    return Immutable.fromJS(mainButton);
  };

  render() {
    // const dropDownButtonItems = this.getDropDownButtonItems()
    const mainButton = this.getMainButton();

    const singleButton = mainButton && mainButton.get("new");

    return (
      <Activities
        singleButton={singleButton}
        mainButton={mainButton}
        // dropDownButtonItems={dropDownButtonItems}
      />
    );
  }
}

export default withTranslation()(
  connect(
    RecordsBatchUpdateActivities,
    {
      scenes: ["scenes"]
    },
    (props, { scenes }) => {
      const scene = scenes.get(props.sceneId);
      return {
        ...props,
        scene
      };
    }
  )
);

import _ from "lodash";
import moment from "moment";

import i18n from "../configs/i18n";
import * as AXIS_SUB_TYPES from "../configs/reports/widget/axisSubTypes";

const log = require("debug")("CRM:Utils:formatDate");

const CURRENT_YEAR = new Date().getFullYear();

const removeYearFromFormat = _.memoize(function(format) {
  return format
    .replace(/([\,\.\/ ]*Y+[\,\.\/ ]*)/, " ") // remove year
    .replace(/ +/g, " ") // remove double spaces
    .replace(/(^ | $)/g, ""); // trim
});

function getFormatWithoutYear(format, locale = moment.locale()) {
  // and others but need test
  if (/ru|en|cs/.test(locale)) {
    return removeYearFromFormat(
      moment()
        .localeData()
        .longDateFormat(format)
    );
  }

  return format;
}

export function formatDate(date, withTime = false) {
  let locale = i18n.language;
  moment.locale(locale);
  if (!date) {
    log("empty date");
    return "";
  }
  if (!moment.isMoment(date)) {
    date = moment(new Date(date));
  }

  let format = withTime ? "lll" : "ll";

  if (date.year() === CURRENT_YEAR) {
    format = getFormatWithoutYear(format, locale);
  }

  return date.format(format);
}

export function formatTime(date) {
  if (!date) {
    log("empty date");
    return "";
  }
  if (!moment.isMoment(date)) {
    date = moment(new Date(date));
  }
  return date.format("LT");
}

export function formatDateWithSec(date, locale, withTime = false) {
  moment.locale(locale);
  if (!date) {
    log("empty date");
    return "";
  }
  if (!moment.isMoment(date)) {
    date = moment(new Date(date));
  }

  return date.format("LL LTS");
}

export function getFormatDate(axis, mode) {
  if (axis === null) {
    return null;
  }
  switch (mode) {
    case AXIS_SUB_TYPES.DAY_OF_WEEK:
      // axis == 1 - is sunday
      return (
        moment.weekdaysShort(
          axis -
            moment()
              .localeData()
              .firstDayOfWeek()
        ) || `{day: ${axis}}`
      );
    case AXIS_SUB_TYPES.MONTH:
      const date = moment(new Date(axis + "-01"));
      if (date.isValid()) {
        if (date.year() === CURRENT_YEAR) {
          return date.format("MMM");
        } else {
          return date.format("MMM YY");
        }
      }
      return `{month: ${axis}}`;
    case AXIS_SUB_TYPES.MONTH_OF_YEAR:
      return moment.monthsShort(axis - 1) || `{month: ${axis}}`;
    case AXIS_SUB_TYPES.DAY:
    case AXIS_SUB_TYPES.WEEK:
      return axis && formatDate(axis);
    case AXIS_SUB_TYPES.HOUR:
      return axis && formatDate(axis, true);
  }

  return axis;
}

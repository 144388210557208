// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chats__chatsPreviewIconContainer___WkI8C {\n  box-shadow: none;\n  width: auto;\n  margin-right: 5px;\n  margin-left: 5px;\n}\n.chats__chatsPreviewIcon___y5XQr {\n  position: relative;\n  top: 1.5px;\n}\n.chats__chatsHeaderContainer___lN1P3 {\n  display: flex;\n  align-items: baseline;\n  gap: 10px;\n}\n.chats__chatsHeaderBtnRefresh___qAd-w {\n  font-size: 1.15em;\n}\n.chats__chatsHeaderRefreshIcon___Yj8Ho {\n  font-size: 19px;\n  color: #b6b8ba;\n  cursor: pointer;\n  position: relative;\n  top: 4px;\n}\n.chats__emptyData___es\\+Oz {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppHeader/Chats/chats.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAIA;EACI,kBAAA;EACA,UAAA;AAFJ;AAKA;EACI,aAAA;EACA,qBAAA;EACA,SAAA;AAHJ;AAUA;EACI,iBAAA;AARJ;AAYA;EACI,eAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;AAVJ;AAeA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAbJ","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.chatsPreviewIconContainer {\n    box-shadow: none;\n    width: auto;\n    margin-right: 5px;\n    margin-left: 5px;\n}\n\n.chatsPreviewIcon {\n    position: relative;\n    top: 1.5px;\n}\n\n.chatsHeaderContainer {\n    display: flex;\n    align-items: baseline;\n    gap: @PADDING_BASE;\n}\n\n.chatsHeaderTitle {\n    \n}\n\n.chatsHeaderBtnRefresh {\n    font-size: 1.15em;\n}\n\n\n.chatsHeaderRefreshIcon {\n    font-size: @MENU_ICON_SIZE;\n    color: @TEXT_MUTED;\n    cursor: pointer;\n    position: relative;\n    top: 4px;\n}\n\n// empty items\n\n.emptyData {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatsPreviewIconContainer": "chats__chatsPreviewIconContainer___WkI8C",
	"chatsPreviewIcon": "chats__chatsPreviewIcon___y5XQr",
	"chatsHeaderContainer": "chats__chatsHeaderContainer___lN1P3",
	"chatsHeaderBtnRefresh": "chats__chatsHeaderBtnRefresh___qAd-w",
	"chatsHeaderRefreshIcon": "chats__chatsHeaderRefreshIcon___Yj8Ho",
	"emptyData": "chats__emptyData___es+Oz"
};
export default ___CSS_LOADER_EXPORT___;

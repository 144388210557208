import _ from "lodash";

class Route {
  constructor({ path, defaults }) {
    this.path = path;
    this.defaults = defaults;
  }
}

const relativeRoutes = {
  auth: { path: "/auth" },
  companies: { parent: "auth", path: "/companies" },
  login: { parent: "auth", path: "/login" },
  setPass: { parent: "auth", path: "/setPass" },
  register: { parent: "auth", path: "/register" },
  resetPass: { parent: "auth", path: "/resetPass" },
  activate: { parent: "auth", path: "/activate" },
  paymentRequired: { parent: "auth", path: "/payment/required" },

  section: { path: "/section/:sectionId" },
  catalogAdd: { parent: "section", path: "/catalog/$add" },
  catalog: { parent: "section", path: "/catalog/:catalogId" },
  catalogEdit: { parent: "catalog", path: "/edit" },
  view: { parent: "catalog", path: "/view/:viewId", defaults: { viewId: "0" } },

  // records
  records: { parent: "view", path: "/records" },

  newRecord: { parent: "records", path: "/$new" },
  record: { parent: "records", path: "/:recordId" },
  recordMain: {
    parent: "record",
    path: "/tab/:tabId",
    defaults: { tabId: "0" }
  },
  recordHistory: { parent: "record", path: "/history" },
  recordLinks: { parent: "record", path: "/links" },
  recordChat: { parent: "record", path: "/chat" },

  // records mass changing
  batchUpdate: { parent: "record", path: "/batchUpdate" },
  batchUpdateMain: { parent: "batchUpdate", path: "/main" },
  batchUpdateResult: { parent: "batchUpdate", path: "/result" },

  // records mass delete
  batchDelete: { parent: "view", path: "/batchDelete" },

  // cards view
  cards: { parent: "view", path: "/cards" },

  // calendar
  calendar: { parent: "view", path: "/calendar" },
  calendarSettings: { parent: "calendar", path: "/:type/:date" },

  // history
  history: { parent: "view", path: "/history" },

  // count
  count: { parent: "view", path: "/count" },

  // reports
  reports: { parent: "view", path: "/reports" },
  board: { parent: "reports", path: "/board/:boardId" },

  //import
  import: { parent: "catalog", path: "/import" },

  // pivot
  pivot: { parent: "view", path: "/pivot" }
};

const absoluteRoutes = {};

const getRoute = _.memoize(function(routeName) {
  const route = relativeRoutes[routeName];
  const parentRoute = route.parent ? getRoute(route.parent) : {};
  return Object.assign(
    {},
    {
      path: (parentRoute.path || "") + route.path,
      defaults: Object.assign({}, parentRoute.defaults, route.defaults)
    }
  );
});

Object.keys(relativeRoutes).forEach(routeName => {
  absoluteRoutes[routeName] = new Route(getRoute(routeName));
});

export default absoluteRoutes;

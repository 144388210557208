import _ from "lodash";
import React from "react";
import Immutable from "immutable";
import cn from "classnames";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Icon from "../common/UI/Icon";
import Dropdown from "../common/Dropdown";
import RightsExceptions from "./RightsExceptions";
import antiCapitalize from "../../utils/antiCapitalize";
import modalsActions from "../../actions/modalsActions";
import ButtonClose from "../common/UI/ButtonClose";
import ButtonTransparent from "../common/UI/ButtonTransparent";

import PRIVILEGE_CODES from "../../configs/privilegeCodes";

import styles from "./access.less";

const log = require("debug")("CRM:Component:Rights:Modal");

// этот компонент нужен на случай, если правила будут в контейнере фиксированной высоты и надо будет
// при добавлении нового правила прокуручивать контейнер вниз
class AccessRules extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rules: this.props.rules
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rules) {
      this.setState({
        rules: nextProps.rules
      });
    }
  }

  getPrivilegeText = privilegeCode =>
    this.props.t(
      `modals.access.privileges.${this.props.resource}.${privilegeCode}`
    );

  render() {
    let rules;
    const object = this.props.object;
    const { t } = this.props;
    //Константы определяющие для какого объекта защиты открыто окно доступа
    const isSection = !!(this.props.object && this.props.object.sectionId);
    const isCatalog = !!(this.props.object && this.props.object.catalogId);
    // Пока не используется
    // const isView    = !!(this.props.object && this.props.object.viewId);
    // const isRecord  = !!(this.props.object && this.props.object.recordId);
    const catalogId = isCatalog
      ? this.props.object.catalogId
      : this.props.parentCatalog && this.props.parentCatalog.get("id");

    if (this.state.rules) {
      let privileges = this.props.privilegeCodes
        .map(p => {
          return {
            key: p,
            text: this.getPrivilegeText(p)
          };
        })
        .toJS(); // toJS for DropDown

      rules = this.state.rules.map((rule, index) => {
        let subject = rule.getIn(["rightSubject"]);
        let attr = subject.getIn(["userAttr"]);
        let attrTitle = antiCapitalize(subject.getIn(["userAttrTitle"]));
        let privilegeCode = rule.getIn(["privilegeCode"]);
        let subjectPrivileges = privileges.slice();
        // remove DENY privilege from available list
        if (privilegeCode !== PRIVILEGE_CODES.DENY) {
          subjectPrivileges = subjectPrivileges.filter(
            p => p.key !== PRIVILEGE_CODES.DENY
          );
        }

        // remove ADMIN privilege from available list for allExtUsers
        if (attr === "allExtUsers") {
          subjectPrivileges = subjectPrivileges.filter(
            p => p.key !== PRIVILEGE_CODES.ADMIN
          );
        }

        let subjectIcon =
          subject.getIn(["catalogIcon"]) ||
          (attr === "id" && "users-9") ||
          "users-24";
        let subjectDescription;
        let privilegeView;
        let rightsExceptions;
        let privilegeField = null;
        let subjectName = subject.getIn(["recordTitle"]);
        let subjectTitle = subjectName;
        let readOnly =
          (this.props.readOnly || privilegeCode == PRIVILEGE_CODES.ADMIN) &&
          !this.props.isAdmin;
        let hasRemoveBtn = privilegeCode != PRIVILEGE_CODES.AVAILABLE;

        let onClickRemove = () => {
          this.props.onClickRemoveRight(index);
        };

        let onChangePrivilege = items => {
          this.props.onChangePrivilege(index, items);
        };
        const openViewFieldRightsModal = () => {
          const object = this.props.object;
          const subjectPrivilege = privilegeCode;
          let basePrivilege = "edit";
          if (privilegeCode == PRIVILEGE_CODES.VIEW) {
            basePrivilege = "view";
          } else if (privilegeCode == PRIVILEGE_CODES.SEARCH) {
            basePrivilege = "";
          } else if (privilegeCode == PRIVILEGE_CODES.AVAILABLE) {
            basePrivilege = "";
          }
          const fields = this.props.catalogs.getIn([catalogId, "fields"]);

          modalsActions.openViewFieldRightsModal(
            rule,
            index,
            object,
            subjectPrivilege,
            basePrivilege,
            catalogId,
            fields,
            fields => {
              this.props.onChangeRule(index, rule.set("fields", fields));
              log("Field rights modal saved");
            },
            () => {
              log("Field rights modal dismised");
            }
          );
        };

        if (attrTitle) {
          subjectDescription = <span>({attrTitle})</span>;
          subjectTitle += ` (${attrTitle})`;
        }

        // workaround. because Dropdown incorrect work, while privileges array is empty
        // workaround. to show search privilege
        if (!_.find(subjectPrivileges, { key: privilegeCode })) {
          subjectPrivileges.unshift({
            key: privilegeCode,
            text: this.getPrivilegeText(privilegeCode)
          });
        }

        if (attr === "allUsers") {
          _.remove(subjectPrivileges, p => p.key === PRIVILEGE_CODES.DENY);
        }

        if (!this.props.isAdmin) {
          _.remove(subjectPrivileges, p => p.key === PRIVILEGE_CODES.ADMIN);
        }

        if (readOnly) {
          privilegeView = (
            <span className={styles.textLikeInput}>
              {this.getPrivilegeText(privilegeCode)}
            </span>
          );
        } else {
          privilegeView = (
            <Dropdown
              bordered={false}
              value={privilegeCode}
              items={subjectPrivileges}
              onSelectItems={onChangePrivilege}
              className={cn("select-transparent", styles.privilegeViewSelect)}
            />
          );
        }

        let fieldsRules = rule.get("fields") || new Immutable.Map();
        if (
          !isSection &&
          [
            PRIVILEGE_CODES.AVAILABLE,
            PRIVILEGE_CODES.SEARCH,
            PRIVILEGE_CODES.VIEW,
            PRIVILEGE_CODES.EDIT,
            PRIVILEGE_CODES.CREATE,
            PRIVILEGE_CODES.EXPORT,
            PRIVILEGE_CODES.DELETE,
            PRIVILEGE_CODES.ACCESS,
            PRIVILEGE_CODES.ADMIN
          ].indexOf(privilegeCode) != -1 &&
          !readOnly
        ) {
          privilegeField = (
            <ButtonTransparent
              onClick={openViewFieldRightsModal}
              title={t("modals.access.byFields")}
            >
              <Icon type="icon edition-31" />
            </ButtonTransparent>
          );
        }

        // fields exception rules
        let basePrivilege = "edit";
        if (privilegeCode == PRIVILEGE_CODES.VIEW) {
          basePrivilege = "view";
        } else if (privilegeCode == PRIVILEGE_CODES.SEARCH) {
          basePrivilege = "";
        } else if (privilegeCode == PRIVILEGE_CODES.AVAILABLE) {
          basePrivilege = "";
        }
        if ((fieldsRules && fieldsRules.size) !== 0 && object) {
          rightsExceptions = (
            <RightsExceptions
              readOnly={readOnly}
              exceptions={fieldsRules}
              basePrivilege={basePrivilege}
              catalogId={catalogId}
            />
          );
        }
        // rights__row--group

        let removeBlock = null;

        if (!readOnly) {
          removeBlock = hasRemoveBtn && (
            <ButtonClose onClick={onClickRemove} shiftRight small />
          );
        } else if (rule.getIn(["fromObject"])) {
          let fromObjIcon;
          let fromTitle;

          if (rule.getIn(["fromObject", "sectionId"])) {
            fromObjIcon =
              this.props.parentSection && this.props.parentSection.get("icon");
            fromTitle = t("modals.access.inheritedFromSection");
          } else if (rule.getIn(["fromObject", "catalogId"])) {
            fromObjIcon =
              this.props.parentCatalog && this.props.parentCatalog.get("icon");
            fromTitle = t("modals.access.inheritedFromCatalog");
          }

          if (fromObjIcon) {
            removeBlock = (
              <span title={fromTitle}>
                {t("modals.access.from")}{" "}
                <span
                  className={cn(
                    "anticon-icon",
                    fromObjIcon,
                    styles.fromObjIcon
                  )}
                />
              </span>
            );
          }
        }

        return (
          <Row type="flex" className={styles.rightsRow} key={index}>
            <Col xs={14} title={subjectTitle} className={styles.subjectColumn}>
              <span
                className={cn("anticon-icon " + subjectIcon, {
                  [styles.subjectName]: !readOnly
                })}
              />
              <span
                className={cn(styles.textLikeInput, {
                  [styles.subjectName]: !readOnly
                })}
              >
                {subjectName}
              </span>
              {subjectDescription}
            </Col>
            <Col xs={8} className={styles.rightsColumn}>
              <Row type="flex" align="middle">
                {privilegeView}
                {privilegeField}
              </Row>
              {rightsExceptions && (
                <div className={styles.rightsExceptions}>
                  {rightsExceptions}
                </div>
              )}
            </Col>
            <Col xs={2} className={styles.rightsColumn}>
              {removeBlock}
            </Col>
          </Row>
        );
      });
    } else {
      return (rules = Immutable.fromJS([]));
    }

    return <div>{rules}</div>;
  }
}

AccessRules.propTypes = {
  resource: PropTypes.string.isRequired,
  privilegeCodes: PropTypes.object.isRequired,
  rules: PropTypes.object,
  object: PropTypes.object,
  parentCatalog: PropTypes.object,
  parentSection: PropTypes.object,
  onClickRemoveRight: PropTypes.func,
  onChangePrivilege: PropTypes.func,
  readOnly: PropTypes.bool,
  isAdmin: PropTypes.bool,
  catalogs: PropTypes.object
};

export default withTranslation()(AccessRules);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timer__containerTimer___qSa8E {\n  min-width: 165px;\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n}\n.timer__settingIcon___4NayT {\n  padding: 0 10px 0 5px;\n  opacity: 0.75;\n  min-height: 0;\n}\n.timer__settingIcon___4NayT .ant-checkbox {\n  margin: 0;\n}\n.timer__autoUpdateTitle___V3qhQ {\n  padding-right: 5px;\n}\n.timer__timer___3Dni6 {\n  color: #b6b8ba;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Records/Timer/timer.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;AADJ;AAIA;EACI,qBAAA;EACA,aAAA;EACA,aAAA;AAFJ;AADA;EAMU,SAAA;AAFV;AAOA;EACI,kBAAA;AALJ;AAQA;EACI,cAAA;AANJ","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n\n.containerTimer {\n    min-width: 165px;\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n}\n\n.settingIcon {\n    padding: 0 @PADDING_BASE 0 @PADDING_BASE / 2;\n    opacity: 0.75;\n    min-height: 0;\n    :global {\n        .ant-checkbox {\n          margin: 0;\n        }\n      }\n}\n\n.autoUpdateTitle {\n    padding-right: @PADDING_BASE / 2;\n}\n\n.timer {\n    color: @TEXT_MUTED;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerTimer": "timer__containerTimer___qSa8E",
	"settingIcon": "timer__settingIcon___4NayT",
	"autoUpdateTitle": "timer__autoUpdateTitle___V3qhQ",
	"timer": "timer__timer___3Dni6"
};
export default ___CSS_LOADER_EXPORT___;

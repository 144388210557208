import React from "react";
import { Cell } from "fixed-data-table-2";
import cn from "classnames";

import styles from "./styles.less";

export default class FooterCell extends React.Component {
  render() {
    const { children, onClick, className } = this.props;

    return (
      <div onClick={onClick} className={cn(styles.footer, className)}>
        <Cell className={styles.footerCell}>{children}</Cell>
      </div>
    );
  }
}

import React, { Component } from "react";
import cn from "classnames";
import { connect } from "../../StateProvider";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";

import RecordCloneModal from "./RecordCloneModal";
import FIELD_TYPES from "../../../configs/fieldTypes";
import apiActions from "../../../actions/apiActions";
import Activities from "../Activities";

import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../utils/rights";

import Icon from "../../common/UI/Icon";

import styles from "./recordActivities.less";
import { promptModal } from "../../common/Modal";
import { message } from "antd";
import { validateApiKey } from "../../../utils/validateApiKey";
import recordActions from "../../../actions/recordActions";

class RecordActivities extends Component {
  static propTypes = {
    record: PropTypes.object,
    catalog: PropTypes.object,
    viewId: PropTypes.string,
    sceneId: PropTypes.string,
    hasBeenEdit: PropTypes.bool,
    onRefresh: PropTypes.func,
    onRemove: PropTypes.func,
    onClickCreate: PropTypes.func,
    onClickAccess: PropTypes.func,
    onSave: PropTypes.func,
    isLoading: PropTypes.func,
    scenes: PropTypes.object
  };

  fileLoading() {
    return this.props.catalog.get("fields").find(field => {
      if (field.get("type") === FIELD_TYPES.FILE) {
        const files = this.props.record.getIn(["values", field.get("id")]);
        if (files) {
          return files.find(file => file.get("loading"));
        }
      }
      return false;
    });
  }

  openApiKeyModal = () => {
    const { t, record } = this.props;
    promptModal({
      headerText: t("modals.apiKeys.recordApiKeyConfirm.headerText"),
      defaultValue: record && record.get("id"),
      onOk: this.onSetKey,
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel"),
      promptHint: t("modals.apiKeys.promptHint"),
      validateFunc: validateApiKey,
      t
    });
  };

  onSetKey = async newKey => {
    const { catalog, record, scenes, sceneId, t } = this.props;
    const catalogId = catalog.get("id");
    const recordId = record.get("id");
    try {
      // Не задано? Явно указываем null
      // => удаляем запись из recordIds и получаем числовой dbId как раньше
      newKey = _.isEmpty(_.trim(newKey)) ? null : newKey;

      await apiActions.updateRecord(
        { catalogId, recordId },
        { id: newKey },
        { sceneId }
      );

      // В теории родительская сцена это всегда сцена каталога
      const catalogSceneId = _.get(scenes.toJS(), [sceneId, "parentSceneId"]);

      if (catalogSceneId) {
        await recordActions.setShouldReload(catalogSceneId, true);
        await recordActions.setTimeUntilTimerEnd(
          catalogSceneId,
          Date.now() + 10 * 60 * 1001
        );
      }
    } catch (e) {
      message.open({
        type: "error",
        content: _.get(e, "response.text") || t("modals.saveError.text")
      });
    }
  };

  changeCopyURL = () => {
    const currentURL = window.location.href;
    const location = this.props.history.location;
    const pathPartsBegin = currentURL.split("/section")[0];
    const pathPartsEnd = currentURL.split("?")[1];
    return pathPartsBegin + location.pathname + "?" + pathPartsEnd;
  };

  copyLinkToClipboard = () => {
    if (!navigator.clipboard) {
      return;
    }
    const updateURL = this.changeCopyURL();
    navigator.clipboard.writeText(updateURL);
  };

  getDropDownButtonItems = () => {
    const { catalog, record, sceneId, t } = this.props;
    const dropDownButtonItems = [];

    // button copy link
    /* ------------------------------------------------ */
    dropDownButtonItems.push(
      <a onClick={this.copyLinkToClipboard}>
        <Icon type={"icon edition-50"} className={styles.settingIcon} />
        {t("buttons.copyLink")}
      </a>
    );
    /* ------------------------------------------------ */

    // button clone
    /* ------------------------------------------------ */
    const isAccessCreateAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.CREATE
    );

    isAccessCreateAtCatalog &&
      dropDownButtonItems.push(
        <RecordCloneModal
          params={{
            sectionId: catalog.get("sectionId"),
            catalogId: catalog.get("id"),
            recordId: record.get("id")
          }}
          linkProps={{
            icon: "text-5",
            text: t("buttons.clone")
          }}
          parentSceneId={sceneId}
        />
      );
    /* ------------------------------------------------ */

    
    // button access
    /* ------------------------------------------------ */
    dropDownButtonItems.push(
      <a onClick={this.props.onClickAccess}>
        <Icon type={"icon edition-55"} className={cn(styles.settingIcon, styles.settingIconAccess)} />
        {t("buttons.accessRecord")}
      </a>
    );
    /* ------------------------------------------------ */

    // button setApiKeys
    /* ------------------------------------------------ */
    const isAccessAdminAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.ADMIN
    );

    const isStartWithDollar = _.startsWith(record.get("id"), "$");

    isAccessAdminAtCatalog &&
      !isStartWithDollar &&
      dropDownButtonItems.push(
        <a onClick={this.openApiKeyModal}>
          <Icon type={"icon edition-59"} className={styles.settingIcon} />
          {t("buttons.setApiKey")}
        </a>
      );
    /* ------------------------------------------------ */

    // button delete
    /* ------------------------------------------------ */
    const isAccessDel = checkAccessOnObject(
      RESOURCE_TYPES.RECORD,
      record,
      PRIVILEGE_CODES.DELETE
    );

    isAccessDel &&
      dropDownButtonItems.push(
        <a onClick={this.props.onRemove} className={styles.settingRemove}>
          <Icon type={"icon edition-41"} className={styles.settingIcon} />
          {t("buttons.removeRecord")}
        </a>
      );
    /* ------------------------------------------------ */

    return dropDownButtonItems;
  };

  onSave = options => {
    this.props.onSave(options);
  };

  getMainButton = () => {
    const { t, record } = this.props;
    let createButton;

    const disabled =
      (record.getIn(["updateProcesses", "count"]) &&
        record.getIn(["updateProcesses", "count"]) > 0) ||
      record.getIn(["updateProcesses", "should"]) ||
      this.fileLoading();

    const isNew = record.get("isNew");
    if (isNew) {
      // create
      if (record.get("creating")) {
        createButton = {
          disabled: true,
          type: "primary",
          text: t("buttons.creating"),
          new: true
        };
      } else {
        createButton = {
          type: "primary",
          text: t("buttons.create"),
          onClick: this.props.onClickCreate,
          disabled: disabled,
          new: true
        };
      }
    } else {
      // main button
      if (this.props.hasBeenEdit) {
        // save
        if (record.get("saving")) {
          createButton = {
            type: "primary",
            text: t("buttons.saving"),
            disabled: record.get("saving") || disabled
          };
        } else {
          createButton = {
            type: "primary",
            text: t("buttons.save"),
            onClick: this.onSave,
            disabled: disabled
          };
        }
      } else {
        createButton = {
          text: t("buttons.refresh"),
          onClick: this.props.onRefresh,
          disabled: disabled || (this.props.isLoading && this.props.isLoading())
        };
      }
    }

    return Immutable.fromJS(createButton);
  };

  getClearButton = () => {
    let createButton;

    const { record, isWebForm, onClear, t } = this.props;
    const isNew = record && record.get("isNew");

    if (isNew && isWebForm && onClear) {
      createButton = {
        text: t("buttons.clear"),
        onClick: onClear,
        className: styles.buttonClear
      };
    }
    return Immutable.fromJS(createButton);
  };

  render() {
    const dropDownButtonItems = this.getDropDownButtonItems();
    const mainButton = this.getMainButton();
    const clearButton = this.getClearButton();

    const singleButton = mainButton && mainButton.get("new");

    return (
      <Activities
        singleButton={singleButton}
        mainButton={mainButton}
        dropDownButtonItems={dropDownButtonItems}
        clearButton={clearButton}
      />
    );
  }
}

export default connect(
  withTranslation()(
    connect(
      withRouter(RecordActivities),
      {
        scenes: ["scenes"]
      }
    )
  ),
  {
    scenes: ["scenes"]
  },
  (props, { scenes }) => {
    const currentScene = props.sceneId && scenes.get(props.sceneId);
    return {
      ...props,
      currentScene
    };
  }
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewSelector__viewsSelectorContainer___uROjE {\n  width: 100%;\n  border-radius: 4px;\n  background: #eef0f3;\n}\n.viewSelector__viewsSelector___mPBGa {\n  flex-basis: 0;\n  min-width: 0;\n  flex-grow: 1;\n  margin-right: 3px;\n}\n.viewSelector__viewsSelector___mPBGa .ant-select-selector {\n  background: #eef0f3 !important;\n  border-color: transparent !important;\n}\n.viewSelector__viewsSelector___mPBGa.ant-select-focused .ant-select-selector {\n  box-shadow: none !important;\n  border-color: transparent !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/Catalog/View/ViewHeader/ViewSelector/viewSelector.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,kBAAA;EACA,mBAAA;AADF;AAGA;EACE,aAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;AADF;AAME;EACE,8BAAA;EACA,oCAAA;AAJJ;AAOE;EACE,2BAAA;EACA,oCAAA;AALJ","sourcesContent":["@import (reference) \"../../../../../../../../../styles/helpers/index.less\";\n\n.viewsSelectorContainer {\n  width: 100%;\n  border-radius: @BORDER_RADIUS;\n  background: @BG_HEADER;\n}\n.viewsSelector {\n  flex-basis: 0;\n  min-width: 0;\n  flex-grow: 1;\n  margin-right: 3px;\n}\n\n\n.viewsSelector {\n  & :global(.ant-select-selector) {\n    background: @BG_HEADER !important;\n    border-color: transparent !important;\n  }\n\n  &:global(.ant-select-focused .ant-select-selector) {\n    box-shadow: none !important;\n    border-color: transparent !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewsSelectorContainer": "viewSelector__viewsSelectorContainer___uROjE",
	"viewsSelector": "viewSelector__viewsSelector___mPBGa"
};
export default ___CSS_LOADER_EXPORT___;

import { Skeleton } from 'antd';
import React from 'react';
import classNames from 'classnames';

import styles from "./skeletonItems.less";

const SkeletonItems = ({ countSkeleton = 0, classNameContainer, classNameItem, active = false, block = false }) => {
    return (
        <>
            {Array.from(Array(countSkeleton).keys()).map((s) => {
                return (
                    <div key={s} className={classNames(styles.skeletonItemContainer, {
                        [classNameContainer]: !!classNameContainer
                    })}>
                        <Skeleton.Button className={classNames(styles.skeletonItem, {
                            [classNameItem]: !!classNameItem
                        })} active={active} block={block} />
                    </div>
                )
            })}
        </>
    );
};

export default SkeletonItems;
import React, { PureComponent } from "react";
import cn from "classnames";
import { Dropdown } from "antd";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import Icon from "../../../common/UI/Icon";
import modalsActions from "../../../../actions/modalsActions";
import apiActions from "../../../../actions/apiActions";
import sectionActions from "../../../../actions/sectionActions";
import {
  promptConfirm,
  prompt,
  promptModal,
  alert
} from "../../../common/Modal";

import ButtonTransparent from "../../../common/UI/ButtonTransparent";

import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../utils/rights";
import { validateApiKey } from "../../../../utils/validateApiKey";

import styles from "./SectionSettings.less";

class SectionSettings extends PureComponent {
  constructor() {
    super();
    this.state = {
      loadingForModal: false
    };
  }

  static propTypes = {
    section: PropTypes.object,
  };

  onClickAccess = () => {
    const sectionId = this.props.section.get("id");
    if (sectionId) {
      const isAdmin = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ADMIN
      );
      const readOnly = !checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ACCESS
      );
      const object = { sectionId };
      modalsActions.openAccessModal(
        { object },
        RESOURCE_TYPES.SECTION,
        { readOnly, isAdmin },
        true
      );
    }
  };

  openRenameModal = () => {
    const { t } = this.props;
    prompt({
      headerText: t("modals.changeSectionName.headerText"),
      value: this.props.section.get("name"),
      onOk: this.onRename,
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel")
    });
  };

  onRename = newName => {
    apiActions.updateSection(
      {
        sectionId: this.props.section.get("id")
      },
      {
        name: newName
      }
    );
  };

  openApiKeyModal = () => {
    const { t } = this.props;
    promptModal({
      headerText: t("modals.apiKeys.sectionApiKeyConfirm.headerText"),
      defaultValue: this.props.section.get("id"),
      onOk: this.onSetKey,
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel"),
      promptHint: t("modals.apiKeys.promptHint"),
      loading: this.state.loadingForModal,
      validateFunc: validateApiKey,
      t
    });
  };

  onSetKey = async newKey => {
    const { section, history, t } = this.props;
    this.setState({ loadingForModal: true });
    try {
      const sectionId = section.get("id");

      // Не задано? Явно указываем null
      // => удаляем запись из recordIds и получаем числовой dbId как раньше
      newKey = _.isEmpty(_.trim(newKey)) ? null : newKey;

      const { id: newSectionId } = await apiActions.updateSection(
        { sectionId },
        { id: newKey }
      );

      sectionActions.truncateSection({ sectionId });
      history.push({
        pathname: `/section/${newSectionId}`,
        search: _.get(history, ["location", "search"], "")
      });
    } catch (e) {
      alert({
        headerText: t("message.saveError"),
        text: t("modals.setApiKeyErrors.onSaveError"),
        okText: t("modals.setApiKeyErrors.okText")
      });
    } finally {
      this.setState({ loadingForModal: false });
    }
  };

  openRemoveConfirm = () => {
    const { t } = this.props;
    const sectionName = this.props.section.get("name");

    promptConfirm({
      value: sectionName,
      placeHolder: sectionName,
      headerText: t("modals.removeSectionConfirm.headerText"),
      text: (
        <span>
          {t("modals.removeSectionConfirm.firstText")}
          <br />
          {t("modals.removeSectionConfirm.secondText")}
        </span>
      ),
      okText: t("modals.removeSectionConfirm.okText"),
      cancelText: t("modals.removeSectionConfirm.cancelText"),
      onOk: () =>
        apiActions
          .deleteSection({
            sectionId: this.props.section.get("id")
          })
          .then(() => {
            this.props.history.replace({
              pathname: "/",
              search: this.props.location ? this.props.location.search : ""
            });
          })
    });
  };

  render() {
    const { t, className, section } = this.props;
    const sectionId = section && section.get("id");

    const isSectionAdmin = checkAccessOnObject(
      RESOURCE_TYPES.SECTION,
      section,
      PRIVILEGE_CODES.ADMIN
    );
    const isSectionAccess = checkAccessOnObject(
      RESOURCE_TYPES.SECTION,
      section,
      PRIVILEGE_CODES.ACCESS
    );

    if ( !isSectionAccess ) {
      return null;
    }

    let menu = [
      {
        key: "access",
        label: (
          <a onClick={this.onClickAccess}>
            <Icon type={"icon edition-55"} className={cn(styles.settingIcon, styles.settingIconAccess)} />
            {t("buttons.accessSection")}
          </a>
        )
      }
    ];

    if (isSectionAdmin) {
      let adminItemsMenu = [
        {
          key: "rename",
          label: (
            <a onClick={this.openRenameModal}>
              <Icon type={"icon edition-33"} className={styles.settingIcon} />
              {t("buttons.renameSection")}
            </a>
          )
        },
        {
          key: "setApiKey",
          label: (
            <a onClick={this.openApiKeyModal}>
              <Icon type={"icon edition-59"} className={styles.settingIcon} />
              {t("buttons.setApiKey")}
            </a>
          )
        },
        {
          key: "remove",
          label: (
            <a
              onClick={this.openRemoveConfirm}
              className={styles.settingRemove}
            >
              <Icon type={"icon edition-41"} className={styles.settingIcon} />
              {t("buttons.removeSection")}
            </a>
          )
        }
      ];

      if (_.startsWith(sectionId, "$")) {
        adminItemsMenu = adminItemsMenu.filter(item => item.key != "setApiKey");
      }

      menu = [...menu, ...adminItemsMenu];
    }

    return (
      <Dropdown
        key="dropdown"
        menu={{items: menu}}
        trigger={["click"]}
      >
          <ButtonTransparent className={cn(styles.sectionSettings, className)} title={t("section.actionsButton")}>
            <Icon type="icon files-105" className={styles.sectionSettingsIcon}/>
          </ButtonTransparent>
      </Dropdown>
    );
  }
}

export default withTranslation()(
    withRouter(SectionSettings)
);

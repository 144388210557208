import React, { useMemo } from "react";
import { Popover } from "antd";
import cn from "classnames";

import FieldApi from "../../../../models/FieldApi";

import styles from "./table.less";

const FieldPopover = props => {
  const EditableField = useMemo(
    () => FieldApi.getComponent(props.field, "control"),
    [props.field]
  );
  const InlineField = useMemo(
    () => FieldApi.getComponent(props.field, "inline"),
    [props.field]
  );

  const getPopoverContent = () => {
    const { field, value, controlConfig } = props;

    return (
      <div
        onClick={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
        className={styles.fieldPopover}
        // onBlur={e => {
        //   // fix for react 17
        //   if (!e.currentTarget.contains(e.relatedTarget)) {
        //      props.onEndEditing();
        //   }
        // }}
      >
        <input autoFocus={true} className={styles.popoverGhostInput} />
        <EditableField
          value={value}
          editable={true}
          autoFocus={true}
          onChange={props.onChange}
          config={field.get("config")}
          fieldId={field.get("id")}
          fieldType={field.get("type")}
          controlConfig={controlConfig}
          style={{ minWidth: 250 }}
          wrapperClassName={styles.fieldPopoverWrapper}
        />
      </div>
    );
  };

  return (
    <Popover
      placement={"bottomLeft"}
      content={getPopoverContent()}
      defaultOpen={true}
      trigger={"click"}
      onOpenChange={visible => !visible && props.onEndEditing()}
    >
      <InlineField
        fieldId={props.field.get("id")}
        config={props.field.get("config")}
        value={props.originalValue}
        fieldType={props.field.get("type")}
        containerClassName={cn(props.fieldClassName, styles.cellPopover)}
      />
    </Popover>
  );
};

export default FieldPopover;

import _ from "lodash";
import Immutable from "immutable";

import batchRecordsActions from "../actions/batchRecordsActions";
import {
  BATCH_RECORDS_STATUS,
  EPOCH_SIZE
} from "../configs/recordsBatchStatus";
import SCENE_TYPE from "../configs/sceneTypes";
import FIELD_TYPES from "../configs/fieldTypes";
import valueActionTypes from "../configs/valueActionTypes";
import apiActions from "../actions/apiActions";

import filterUtills from "../utils/filters";

const getPromiseQueueKey = sceneId => {
  return ["scenes", sceneId, "data", "promises"];
};

export default {
  changefieldsEditableStatus(params, status) {
    const { sceneId, fieldId } = params;

    let fieldsEditableStatus =
      this.getIn(["scenes", sceneId, "data", "fieldsEditableStatus"]) ||
      Immutable.Map({});
    fieldsEditableStatus = fieldsEditableStatus.set(fieldId, status);

    this.setIn(
      ["scenes", sceneId, "data", "fieldsEditableStatus"],
      fieldsEditableStatus
    );
    this.changed();
  },

  changeBatchUpdateValueActions(params, action) {
    const { sceneId, fieldId } = params;

    let valueActions =
      this.getIn(["scenes", sceneId, "data", "valueActions"]) ||
      Immutable.Map({});
    valueActions = valueActions.set(fieldId, action);

    this.setIn(["scenes", sceneId, "data", "valueActions"], valueActions);
    this.changed();
  },

  addElementToValues(value, receivedValues) {
    /*
      нам необходимо тут мутировать значение поля
      1. concat двух значений из бд и клиента
      2. возвращаем измененное значение
      но нужно умно конкатить написать свою реализацию
    */
    let newValues = receivedValues.concat(value);
    /* в каких-то случаях у нас может быть уникальный индификатор айди записи в каких-то просто айди в каких-то нет и индификатора */
    /* можно сделать легче чем пробегаться по всему массиву можно брать первый элемент и какой у него индификатор */
    if (receivedValues.length > 0) {
      for (let i = 0; i < 1; i++) {
        const val = receivedValues[i];
        if (val.recordId) {
          newValues = _.uniqBy(newValues, "recordId");
        } else if (val.id) {
          newValues = _.uniqBy(newValues, "id");
        } else {
          newValues = _.uniq(newValues);
        }
      }
    }
    return Immutable.fromJS(newValues);
  },

  removeElementToValues(value, receivedValues) {
    /*
      нам необходимо тут мутировать значение поля
      1. убрать значения которые пришли с клиента
      2. возвращаем измененное значение
      но нужно умно исключать написать свою реализацию
    */
    /* тут нам тоже нужно будет определять по уникальному индификатору recordId || id || без индификатору по значению */
    let newValues = receivedValues.filter(receivedValue => {
      if (receivedValue.recordId) {
        return !value.some(v => receivedValue.recordId == v.recordId);
      } else if (receivedValue.id) {
        return !value.some(v => receivedValue.id == v.id);
      } else {
        return !value.some(v => _.isEqual(receivedValue, v));
      }
    });
    return Immutable.fromJS(newValues);
  },

  async preparingValues(values, valueActions, params) {
    /* нам нужно получить текущие значения поля для того чтобы их изменить */
    /* Для того чтобы мы могли Добавлять или Исключать из текущих значений полей */
    let fields = [];
    _.forEach(values.toJS(), (value, fieldId) => {
      const valueAction = valueActions && valueActions.get(fieldId);
      if (
        valueAction == valueActionTypes.ADD ||
        valueAction == valueActionTypes.EXCLUDE
      ) {
        fields.push(fieldId);
      }
    });

    /* Для того чтобы получить запись с конкретными полями и Добавить туда или Исключить */
    if (fields.length) {
      const receivedRecord = await apiActions.getRecord(params, { fields });

      _.forEach(values.toJS(), (value, fieldId) => {
        /* Проверяем какой параметр у нас выбран пользователем и что нужно нам делать */
        const valueAction = valueActions && valueActions.get(fieldId);
        if (valueAction == valueActionTypes.ADD) {
          /* Добавить в оригинальные значения новые значения */
          const newValues = this.addElementToValues(
            value,
            receivedRecord.values[fieldId]
          );
          values = values.set(fieldId, newValues);
        } else if (valueAction == valueActionTypes.EXCLUDE) {
          /* Исключить их оригинальных значений значения которые выбрал пользователь */
          const newValues = this.removeElementToValues(
            value,
            receivedRecord.values[fieldId]
          );
          values = values.set(fieldId, newValues);
        }
      });
    }
    return values;
  },

  _saveBatchRecord(params, data, actionParams) {
    const { catalogId, recordId } = params;
    const { values: _values, fields } = data; // to not modify data object

    const values = _values && _values.toJS();
    const error = this.recordHasErrors(catalogId, recordId, values, fields);

    if (error) {
      return Promise.reject(error);
    }

    return this.saveRecord(params, actionParams);
  },

  _deleteBatchRecord(params, actionParams) {
    return apiActions.deleteRecord(params, actionParams);
  },

  changeBatchStatus(sceneId) {
    const status = this._getBatchStatus(sceneId);

    if (status === BATCH_RECORDS_STATUS.COMPLETED) {
      this._setBatchStatus(sceneId, BATCH_RECORDS_STATUS.READY);
      this.changed();
    }
  },

  getBatchRecords(params, query, actionParams) {
    this._setBatchStatus(actionParams.sceneId, BATCH_RECORDS_STATUS.LOADING);

    let newIter = query.offset <= 0;

    if (newIter) {
      this._clearResultInfo(actionParams.sceneId);
    }

    this.changed();
  },

  getBatchRecordsCompleted(body, params, query, response, actionParams) {
    // get total recordsCount
    const recordsTotalsCount = response.headers["x-total-count"];
    const loadedRecords = query.offset + EPOCH_SIZE;

    let appendRecords = query.offset > 0;

    let recordIds;
    if (appendRecords) {
      // get recordIds from response & concat them
      recordIds =
        this.getIn(["scenes", actionParams.sceneId, "data", "recordIds"]) ||
        Immutable.List([]);
    } else {
      recordIds = Immutable.List([]);
    }

    const responseRecordIds = _.map(body, r => r.id);
    recordIds = recordIds.concat(Immutable.fromJS(responseRecordIds));

    this.setIn(
      ["scenes", actionParams.sceneId, "data", "recordIds"],
      recordIds
    );
    this.setIn(
      ["scenes", actionParams.sceneId, "recordsCount"],
      recordsTotalsCount
    );

    if (recordsTotalsCount > loadedRecords) {
      query = {
        ...query,
        offset: loadedRecords
      };

      batchRecordsActions.getBatchRecords(params, query, actionParams);
    } else {
      this._setBatchStatus(actionParams.sceneId, BATCH_RECORDS_STATUS.READY);
    }

    this.changed();
  },

  getBatchRecordsFailed(err, params, query, actionParams) {
    this._setBatchStatus(actionParams.sceneId, BATCH_RECORDS_STATUS.FAILED);
    this.changed();
  },

  _onSaveMessage(sceneId, recordId, text, success = false) {
    // если сцены уже нет нам не нужно создавать по новой
    const sceneExists = !!this.getIn(["scenes", sceneId]);
    if (!sceneExists) {
      return;
    }
    let key = success ? "successRecords" : "errorRecords";
    let messages =
      this.getIn([
        "scenes",
        sceneId,
        "data",
        key,
        recordId,
        "indicator",
        "messages"
      ]) || Immutable.List([]);

    if (text) {
      messages = messages.push(text);
    }

    this.setIn(
      ["scenes", sceneId, "data", key, recordId, "indicator", "messages"],
      messages
    );
    this.setIn(["scenes", sceneId, "data", key, recordId, "id"], recordId);
  },

  _clearResultInfo(sceneId) {
    this.deleteIn(["scenes", sceneId, "data", "errorRecords"]);

    this.deleteIn(["scenes", sceneId, "data", "successRecords"]);
  },

  // INIT PROMISE QUEUE UPDATE
  async _initPromiseQueueUpdate(sceneId, catalogId) {
    const promiseKey = getPromiseQueueKey(sceneId);

    const inititalRecordId = this.getIn([
      "scenes",
      sceneId,
      "params",
      "recordId"
    ]);

    this.setIn(["records", catalogId, inititalRecordId, "hasBeenEdit"], false);

    let recordIds = this.getIn(["scenes", sceneId, "data", "recordIds"]);
    let record = this.getIn(["records", catalogId, inititalRecordId]);
    let fields = this.getIn(["catalogs", catalogId, "fields"]);

    const fieldsEditableStatus = this.getIn([
      "scenes",
      sceneId,
      "data",
      "fieldsEditableStatus"
    ]);

    const valueActions = this.getIn([
      "scenes",
      sceneId,
      "data",
      "valueActions"
    ]);

    // only activated to edit fields
    fields =
      fields &&
      fields.filter(
        field =>
          fieldsEditableStatus && fieldsEditableStatus.get(field.get("id"))
      );

    // set empty value for activated fields without value
    let inititalValues =
      record &&
      record.get("values").filter((value, fieldId) => {
        return fieldsEditableStatus && fieldsEditableStatus.get(fieldId);
      });

    const FieldApi = require("../models/FieldApi").default;

    // фильтруем значения записи и если поле видимое, но пустое, то генерируем empty значение
    fields.forEach(field => {
      const fieldId = field.get("id");

      if (!inititalValues.get(fieldId)) {
        inititalValues = inititalValues.set(
          fieldId,
          FieldApi.getEmptyValue(field)
        );
      }
    });

    record = record.set("values", inititalValues);

    const _action = async recordId => {
      let newValues = await this.preparingValues(inititalValues, valueActions, {
        catalogId,
        recordId
      });

      record = record.set("values", newValues);

      this.setIn(["records", catalogId, recordId], record);
      record = record.set("id", recordId);

      return this._saveBatchRecord(
        {
          catalogId,
          recordId
        },
        {
          values: newValues,
          fields
        },
        {
          sceneId,
          onCompleted: (_catalogId, _recordId, text) =>
            this._onSaveMessage(sceneId, recordId, text, true),
          onFailed: (_catalogId, _recordId, text) =>
            this._onSaveMessage(sceneId, recordId, text, false)
        }
      );
    };

    const _onComplited = (recordId, recordIdKey, result) => {
      this.deleteIn(["records", catalogId, recordId]); // удаляем измененнную запись из стейта
      this._onSaveMessage(sceneId, recordId, undefined, true); // потому что незачем сохранять результаты успешной замены
    };

    const _onFailed = (recordId, recordIdKey, result) => {
      this.deleteIn(["records", catalogId, recordId]);
      this._onSaveMessage(sceneId, recordId, result, false);
    };

    const _onFinish = () => {
      this._setBatchStatus(sceneId, BATCH_RECORDS_STATUS.COMPLETED);
      this.deleteIn(promiseKey);
    };

    const _onProgress = waitingTime => {
      this._setBatchWaitingTime(sceneId, waitingTime);
      this.changed();
    };

    const queue = Immutable.Map({
      map: recordIds, // [recordIds]
      action: _action,
      onComplited: _onComplited,
      onFailed: _onFailed,
      onFinish: _onFinish,
      onProgress: _onProgress,
      updateTime: 3000
    });

    this.initQueue(promiseKey, queue);
    this.startQueue(promiseKey);
  },

  // INIT PROMISE QUEUE FOR DELETE
  async _initPromiseQueueDelete(sceneId, catalogId, viewId) {
    const promiseKey = getPromiseQueueKey(sceneId);

    let recordIds = this.getIn(["scenes", sceneId, "data", "recordIds"]);

    const _action = recordId => {
      return this._deleteBatchRecord(
        {
          catalogId,
          recordId
        },
        {
          sceneId,
          viewId,
          silent: true,
          onFailed: (_catalogId, _recordId, text) =>
            this._onSaveMessage(sceneId, recordId, text, false)
        }
      );
    };

    const _onComplited = (recordId, recordIdKey, result) => {
      this._onSaveMessage(sceneId, recordId, undefined, true); // потому что незачем сохранять результаты успешной замены
    };

    const _onFailed = (recordId, recordIdKey, result) => {
      this._onSaveMessage(sceneId, recordId, result, false);
    };

    const _onFinish = () => {
      this._setBatchStatus(sceneId, BATCH_RECORDS_STATUS.COMPLETED);
      this.deleteIn(promiseKey);
    };

    const _onProgress = waitingTime => {
      this._setBatchWaitingTime(sceneId, waitingTime);
      this.changed();
    };

    const queue = Immutable.Map({
      map: recordIds, // [recordIds]
      action: _action,
      onComplited: _onComplited,
      onFailed: _onFailed,
      onFinish: _onFinish,
      onProgress: _onProgress,
      updateTime: 3000
    });

    this.initQueue(promiseKey, queue);
    this.startQueue(promiseKey);
  },

  _setBatchWaitingTime(sceneId, waitingTime) {
    // если сцены уже нет нам не нужно создавать по новой
    const sceneExists = !!this.getIn(["scenes", sceneId]);
    sceneExists && this.setIn(["scenes", sceneId, "waitingTime"], waitingTime);
  },

  _startQueue(sceneId) {
    const recordId = this.getIn(["scenes", sceneId, "params", "recordId"]);
    const catalogId = this.getIn(["scenes", sceneId, "params", "catalogId"]);

    const promiseKey = getPromiseQueueKey(sceneId);
    recordId &&
      this.setIn(["records", catalogId, recordId, "hasBeenEdit"], false);
    this.startQueue(promiseKey);
  },

  // START THE WAITING QUEUE
  startBatchUpdateRecords(sceneId, catalogId) {
    const promiseKey = getPromiseQueueKey(sceneId);
    const promiseQueueExist = !!this.getIn(promiseKey);

    const recordId = this.getIn(["scenes", sceneId, "params", "recordId"]);
    const hasBeenEdit = this.getIn([
      "records",
      catalogId,
      recordId,
      "hasBeenEdit"
    ]);

    const updatingStatus = this._getBatchStatus(sceneId);
    const allowedToStart =
      updatingStatus === BATCH_RECORDS_STATUS.PAUSED ||
      updatingStatus === BATCH_RECORDS_STATUS.READY;

    this._setBatchStatus(sceneId, BATCH_RECORDS_STATUS.UPDATING);

    if (allowedToStart) {
      if (promiseQueueExist && !hasBeenEdit) {
        this._startQueue(sceneId);
      } else {
        this._initPromiseQueueUpdate(sceneId, catalogId);
      }
    }
  },

  startBatchDeleteRecords(sceneId, catalogId, viewId) {
    const promiseKey = getPromiseQueueKey(sceneId);
    const promiseQueueExist = !!this.getIn(promiseKey);

    const deletingStatus = this._getBatchStatus(sceneId);
    const allowedToStart =
      deletingStatus === BATCH_RECORDS_STATUS.PAUSED ||
      deletingStatus === BATCH_RECORDS_STATUS.READY;

    this._setBatchStatus(sceneId, BATCH_RECORDS_STATUS.DELETING);

    if (allowedToStart) {
      if (promiseQueueExist) {
        this._startQueue(sceneId);
      } else {
        this._initPromiseQueueDelete(sceneId, catalogId, viewId);
      }
    }
  },

  updateBatchUpdateRecordValues(catalogId, recordId, values) {
    this.setIn(["records", catalogId, recordId, "values"], values);
    this.changed();
  },

  // STOP UPDATING
  _pauseBatchRecords(sceneId) {
    const promiseKey = getPromiseQueueKey(sceneId);
    this.pauseQueue(promiseKey);
  },

  _setBatchStatus(sceneId, status) {
    this.setIn(["scenes", sceneId, "data", "batchStatus"], status);
  },

  _getBatchStatus(sceneId) {
    return this.getIn(["scenes", sceneId, "data", "batchStatus"]);
  },

  pauseBatchRecords(sceneId) {
    this._pauseBatchRecords(sceneId);
    this._setBatchStatus(sceneId, BATCH_RECORDS_STATUS.PAUSED);

    this.changed();
  },

  loadBatchRecords(sceneId, catalogId, viewId, offset = 0) {
    // modify fields
    // bad solution because if the same catalog will be opened — it will destroy this hacks
    this._resetChangesOnCatalog(catalogId);
    this._resetCreatingLinkedRecord(catalogId);
    this._resetFieldsVisibilityRules(catalogId);

    let query = {
      // fields: "[]",
      limit: EPOCH_SIZE,
      offset
    };

    let allFilters = [];

    const fields = this.getIn(["catalogs", catalogId, "fields"]);
    let filtersFromScene = this.getIn(["scenes", sceneId, "data", "filters"]);
    const searchText = this.getIn(["scenes", sceneId, "data", "searchText"]);

    if (filtersFromScene) {
      filtersFromScene = filterUtills.getFiltersForRequest(
        filtersFromScene.toJS(),
        fields
      );

      allFilters = _.concat(allFilters, filtersFromScene);
    }

    if (!_.isEmpty(allFilters)) {
      query = {
        ...query,
        filters: allFilters
      };
    }

    if (!_.isEmpty(searchText)) {
      query = {
        ...query,
        searchText
      };
    }

    const params = {
      catalogId
    };

    const actionParams = {
      sceneId
    };

    batchRecordsActions.getBatchRecords(params, query, actionParams);
  },

  _resetChangesOnCatalog(catalogId) {
    let fields = this.getIn(["catalogs", catalogId, "fields"]);

    /* я хз почему, но это все ломает. в this.CATALOG_FIELDS_WITH_EVENTS почему-то не сохраняются fields с параметрами */
    // this.CATALOG_FIELDS_WITH_EVENTS = _.cloneDeep(fields);
    // let newFields = _.cloneDeep(fields);

    fields = fields.map(f => f.set("eventable", false));

    this.setIn(["catalogs", catalogId, "fields"], fields);
  },

  _resetCreatingLinkedRecord(catalogId) {
    // делаем создание всех полей с всплытием
    let fields = this.getIn(["catalogs", catalogId, "fields"]);

    fields = fields.map(f => {
      const fieldType = f.get("type");

      if (fieldType === FIELD_TYPES.OBJECT) {
        f = f.setIn(["config", "enableUnsaved"], false);
      }

      return f;
    });

    this.setIn(["catalogs", catalogId, "fields"], fields);
  },

  _resetFieldsVisibilityRules(catalogId) {
    let fields = this.getIn(["catalogs", catalogId, "fields"]);

    // delete visible rules from fields
    // needed to save value for context visible fields
    fields = fields.map(f => {
      f = f.set("visible", Immutable.Map());
      f = f.set("visibleRules", Immutable.Map());
      return f;
    });

    this.setIn(["catalogs", catalogId, "fields"], fields);
  },

  clearBatch(catalogId, sceneId) {
    const allScenes = this.get("scenes").filter(
      scene =>
        scene.getIn(["params", "catalogId"]) == catalogId &&
        scene.get("type") == SCENE_TYPE.CATALOG
    );
    allScenes &&
      allScenes.forEach((scene, sceneId) => {
        this.setShouldReload(sceneId, true);
      });

    //   console.log("this.CATALOG_FIELDS_WITH_EVENTS", this.CATALOG_FIELDS_WITH_EVENTS);

    // this.setIn(
    //   ["catalogs", catalogId, "fields"],
    //   this.CATALOG_FIELDS_WITH_EVENTS
    // );

    // delete this.CATALOG_FIELDS_WITH_EVENTS;

    this.changed();
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changesResultList__listWrapper___53x7s {\n  max-height: 400px;\n  overflow: auto;\n  margin-right: -20px;\n}\n.changesResultList__changesResultItem___7Pw6T {\n  width: 96%;\n  background-color: #f5f6f8;\n  border-radius: 4px;\n  padding: 15px 15px 20px;\n  display: flex;\n  margin-bottom: 10px;\n}\n.changesResultList__changesResultItem___7Pw6T div {\n  width: 100%;\n}\n.changesResultList__changesResultItemIcon___\\+ggxx {\n  font-size: 16px;\n  color: #666b70;\n}\n.changesResultList__changesResultItemInfo___O2XQ8 {\n  padding-left: 10px;\n}\n.changesResultList__changesResultItemTitle___3TVV9 {\n  font-size: 13px;\n  color: #666b70;\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/ChangesResultList/changesResultList.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,cAAA;EACA,mBAAA;AADF;AAIA;EACE,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AAFF;AAIE;EACE,WAAA;AAFJ;AAMA;EACE,eAAA;EACA,cAAA;AAJF;AAOA;EACE,kBAAA;AALF;AAQA;EACE,eAAA;EACA,cAAA;EACA,mBAAA;AANF","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n\n.listWrapper {\n  max-height: 400px;\n  overflow: auto;\n  margin-right: -20px;\n}\n\n.changesResultItem {\n  width: 96%;\n  background-color: @BG_WORKPLACE;\n  border-radius: @BORDER_RADIUS;\n  padding: 15px 15px 20px;\n  display: flex;\n  margin-bottom: @PADDING_BASE;\n\n  & div {\n    width: 100%;\n  }\n}\n\n.changesResultItemIcon {\n  font-size: @TEXT_SIZE_BIG;\n  color: @TEXT_SUB;\n}\n\n.changesResultItemInfo {\n  padding-left: @PADDING_BASE;\n}\n\n.changesResultItemTitle {\n  font-size: @TEXT_SIZE_BASE;\n  color: @TEXT_SUB;\n  margin-bottom: @PADDING_BASE;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listWrapper": "changesResultList__listWrapper___53x7s",
	"changesResultItem": "changesResultList__changesResultItem___7Pw6T",
	"changesResultItemIcon": "changesResultList__changesResultItemIcon___+ggxx",
	"changesResultItemInfo": "changesResultList__changesResultItemInfo___O2XQ8",
	"changesResultItemTitle": "changesResultList__changesResultItemTitle___3TVV9"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import PropTypes from "prop-types";

import Base from "../common/Modal";

class ComponentRecord extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    catalogId: PropTypes.string,
    recordId: PropTypes.string,
    destroyModal: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    if (!this.props.match) {
      prevProps.onCancel();
    }
  }

  onClose = () => {
    this.props.onCancel && this.props.isNew && this.props.onCancel();
  };

  onCreate = (...args) => {
    this.props.onOk && this.props.onOk(...args);
  };

  render() {
    let { Child, catalogId, recordId } = this.props;
    return (
      <Base onCancel={this.onClose}>
        <Child
          catalogId={catalogId}
          recordId={recordId}
          onClose={this.onClose}
          onCreate={this.onCreate}
        />
      </Base>
    );
  }
}

export default ComponentRecord;

import _ from "lodash";
import cn from "classnames";
import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import Icon from "../../Icon";
import { withTranslation } from "react-i18next";

import TextInput from "./common/Text/TextInput";

import {
  EMAIL,
  SITE,
  PHONE,
  SCRIPT
} from "../../../../../configs/contactFieldSubTypes";
import styles from "./controls.less";

class PairWithSubValue extends React.Component {
  constructor(props) {
    super(props);
    this.forOktellBtn = React.createRef();
    this.state = {
      valueInFocus: false,
      subValueInFocus: false,
      index: _.uniqueId(),
      wasFocused: false
    };
  }

  renderPhoneBtn = () => {
    const phoneNode = this.forOktellBtn.current;

    if (phoneNode) {
      $(phoneNode).oktellButton();
    }
  };

  componentDidMount() {
    this.renderPhoneBtn();
  }

  componentDidUpdate() {
    this.renderPhoneBtn();
  }

  render() {
    const {
      valueUpdateProcess,
      subValueUpdateProcess,
      editable,
      eventable,
      options,
      value,
      subValue,
      t
    } = this.props;

    let containerClasses,
      wrapperCNValue,
      wrapperCNSubValue,
      inputCNValue,
      inputCNSubValue;

    let action = null;
    let multiline = true;
    let phoneBtn = null;

    // actions
    if (value) {
      if (this.props.type === EMAIL) {
        action = (
          <a href={"mailto:" + _.trim(value)} tabIndex={this.state.index + 3}>
            {t("record.fields.contact.mailTo")}
          </a>
        );
      } else if (this.props.type === SITE) {
        let site = _.trim(value);
        let href;

        if (/^([a-z]+:\/\/|\/\/)/.test(site)) {
          href = site;
        } else {
          href = "http://" + site;
        }

        action = (
          <a href={href} target="_blank" tabIndex={this.state.index + 3}>
            {t("record.fields.contact.open")}
          </a>
        );
      }
    }
    if (this.props.type === PHONE) {
      multiline = false;

      if ($.fn.oktellButton) {
        let number = String(value)
          .replace(/[- ()]/g, "")
          .replace(/.*?([0-9]+).*$/, "$1")
          .replace(/[^0-9]/g, "");

        if (number) {
          phoneBtn = <span data-phone={number} ref={this.forOktellBtn} />;
        }
      } else {
        phoneBtn = (
          <Icon
            type="icon communication-2"
            className={cn(styles.phoneFieldBtn)}
          />
        );
      }
    }

    const showSubValueField = (this.state.wasFocused && editable) || subValue;

    // style for scripts
    if (this.props.type === SCRIPT) {
      wrapperCNValue = styles.scriptInputWrapperValue;
      wrapperCNSubValue = styles.scriptInputWrapperSubValue;
      inputCNValue = styles.scriptInputValue;
      inputCNSubValue = styles.scriptInputSubValue;
      containerClasses = cn(
        this.props.className,
        styles.pairSubValueRow,
        styles.scriptRow
      );
    } else {
      inputCNValue = { [styles.inputValueWithSubValue]: showSubValueField };
      inputCNSubValue = styles.inputSubValue;
      containerClasses = cn(this.props.className, styles.pairSubValueRow);
    }

    const valueFocusMixin = {
      onFocus: () => this.setState({ valueInFocus: true, wasFocused: true }),
      onBlur: e => {
        setTimeout(() => {
          this.isMounted &&
            this.isMounted() &&
            this.setState({ valueInFocus: false });
        }, 0);
        this.props.onEndEditing(e);
      }
    };

    const subValueFocusMixin = {
      onFocus: () => this.setState({ subValueInFocus: true }),
      onBlur: e => {
        this.setState({ subValueInFocus: false });
        this.props.onEndEditing(e);
      }
    };

    let actions = [];

    if (action) {
      actions.push(<span className={styles.actionText}>{action}</span>);
    }

    if (phoneBtn) {
      actions.push(phoneBtn);
    }

    return (
      <div className={containerClasses}>
        <TextInput
          wrapperClassName={wrapperCNValue}
          className={inputCNValue}
          id={this.props.id}
          autoFocus={this.props.autoFocus}
          value={value}
          onChange={this.props.valueChangeFn}
          onEndEditing={this.props.onEndEditing}
          onKeyPress={this.props.onValueKeyPress}
          multiline={multiline}
          allowTabs={false}
          readOnly={!editable}
          tabIndex={this.state.index + 1}
          eventable={eventable}
          updateProcess={valueUpdateProcess}
          actions={actions}
          placeholder={this.props.placeholderValue}
          options={options}
          {...valueFocusMixin}
        />
        {this.props.type === SCRIPT && (
          <span className={styles.scriptIcon}>
            <Icon type="icon multimedia-72" className="fa-rotate-90" />
          </span>
        )}
        {showSubValueField || this.props.type === SCRIPT ? (
          <TextInput
            wrapperClassName={wrapperCNSubValue}
            className={inputCNSubValue}
            value={subValue}
            theme={this.props.theme}
            onChange={this.props.subValueChangeFn}
            multiline={true}
            allowTabs={this.props.type === SCRIPT}
            readOnly={!editable}
            tabIndex={this.state.index + 2}
            onEndEditing={this.props.onEndEditing}
            eventable={eventable}
            updateProcess={subValueUpdateProcess}
            placeholder={this.props.placeholderSubValue}
            {...subValueFocusMixin}
            style={
              !editable && this.props.type !== SCRIPT && { marginTop: "-5px" }
            }
          />
        ) : null}
      </div>
    );
  }
}

PairWithSubValue.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  subValue: PropTypes.string.isRequired,
  valueChangeFn: PropTypes.func.isRequired,
  subValueChangeFn: PropTypes.func.isRequired,
  onEndEditing: PropTypes.func,
  onValueKeyPress: PropTypes.func,
  editable: PropTypes.bool,
  eventable: PropTypes.bool,
  error: PropTypes.string
};

export default withTranslation()(PairWithSubValue);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SectionSettings__sectionSettingsIcon___3dx2D {\n  font-size: 15px;\n}\n.SectionSettings__settingIcon___Ing5V {\n  padding: 0 10px 0 5px;\n  opacity: 0.8;\n}\n.SectionSettings__settingIconAccess___\\+0B8O {\n  transform: translateX(2px) translateY(-1px);\n}\n.SectionSettings__settingRemove___LT1a6 {\n  color: #c82d2d !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionSettings.less"],"names":[],"mappings":"AAIA;EACE,eAAA;AAHF;AAOA;EACE,qBAAA;EACA,YAAA;AALF;AAOA;EACE,2CAAA;AALF;AAQA;EACE,yBAAA;AANF","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.sectionSettings {\n}\n.sectionSettingsIcon {\n  font-size: 15px;\n}\n\n\n.settingIcon {\n  padding: 0 @PADDING_BASE 0 @PADDING_BASE / 2;\n  opacity: 0.8;\n}\n.settingIconAccess {\n  transform: translateX(2px) translateY(-1px);\n}\n\n.settingRemove {\n  color: @BTN_DANGER !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionSettingsIcon": "SectionSettings__sectionSettingsIcon___3dx2D",
	"settingIcon": "SectionSettings__settingIcon___Ing5V",
	"settingIconAccess": "SectionSettings__settingIconAccess___+0B8O",
	"settingRemove": "SectionSettings__settingRemove___LT1a6"
};
export default ___CSS_LOADER_EXPORT___;

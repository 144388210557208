import _ from "lodash";
import { message } from "antd";

import i18n from "../../configs/i18n";

const recordMixinCreating = {
  createRecordCompleted(
    result,
    params,
    data,
    {},
    response,
    { recordId: oldRecordId, sceneId, onCompleted, silent = false }
  ) {
    const { id: recordId, title } = result;
    const { catalogId } = params;
    const catalog = this.getIn(["catalogs", catalogId]);

    const recordKey = ["records", catalogId, recordId];
    const oldRecordKey = ["records", catalogId, oldRecordId];

    // run on all the parents of this record and set came values
    // to this record located in other links
    this.modifyItselfInLinks(
      { catalogId, recordId: oldRecordId },
      linkedRecord =>
        linkedRecord.merge({
          recordTitle: title,
          recordId: recordId,
          sectionId: catalog.get("sectionId"),
          isNew: false
        })
    );

    const parents = this.getIn([...oldRecordKey, "parents"]);

    this.setRecord(
      { catalogId, recordId },
      _.assign({ catalogId, parents }, result)
    );
    this.setIn([...recordKey, "saving"], false);
    this.setIn([...recordKey, "_loadTime"], Date.now());
    this.setIn([...recordKey, "loading"], false);
    this.setIn([...recordKey, "loadError"], null);
    this.deleteIn(["records", catalogId, oldRecordId]);

    const values = this.getIn([...recordKey, "values"]);
    const linkedRecords = this.getLinkedRecords(
      catalogId,
      values,
      true /* with fieldId */
    );

    linkedRecords.forEach(({ fieldId, linkedRecord }) => {
      const params = {
        catalogId: linkedRecord.get("catalogId"),
        recordId: linkedRecord.get("recordId")
      };
      this.removeParentFromRecord(params, catalogId, oldRecordId, fieldId);
      this.addParentToRecord(params, catalogId, recordId, fieldId);
    });

    // show message from successed events
    let messageFromResult = result && result.message;

    if (messageFromResult) {
      const { alert } = require("../../components/common/Modal");
      const title = _.isObject(messageFromResult)
        ? messageFromResult.title
        : "";
      messageFromResult = _.isObject(messageFromResult)
        ? messageFromResult.text
        : messageFromResult;
      // message.error(messageFromResult)

      const text = {
        headerText:
          title !== ""
            ? title || i18n.t("modals.record.success.title")
            : i18n.t("modals.record.success.title"),
        text: messageFromResult,
        okText: i18n.t("modals.record.success.okText")
      };

      onCompleted ? onCompleted(catalogId, recordId, text) : alert(text);
    }

    if (!silent) {
      this.changed();
    }
  },

  createRecordFailed(err, params, data, query, { recordId, onFailed }) {
    const errText = _.isObject(err) && err.text ? err.text : "";
    this.setIn(["records", params.catalogId, recordId, "creating"], false);
    this.setIn(
      ["records", params.catalogId, recordId, "createError"],
      errText || true
    );

    const { alert } = require("../../components/common/Modal");
    this.setIn(["records", params.catalogId, recordId, "creating"], false);
    this.setIn(
      ["records", params.catalogId, recordId, "createError"],
      errText || true
    );

    const isAccessDenied = err && err.status == 403;
    const notFound = err && err.status == 404;
    const serverError = err ? err.status && err.status[0] === 5 : false;

    let text;

    if (isAccessDenied) {
      /* можно разные текста использовать */
      text = errText || i18n.t("modals.denied.record.create.text");
      onFailed
        ? onFailed(params.catalogId, recordId, text)
        : message.error(text);
    } else if (notFound) {
      text = errText || i18n.t("modals.createRecordError.headerText");
      onFailed
        ? onFailed(params.catalogId, recordId, text)
        : message.error(text);
    } else if (serverError) {
      text = errText || i18n.t("modals.createRecordError.text");
      onFailed
        ? onFailed(params.catalogId, recordId, text)
        : message.error(text);
    } else {
      text = {
        headerText:
          (err && err.title) || i18n.t("modals.createRecordError.headerText"),
        text: errText || i18n.t("modals.createRecordError.text"),
        okText: i18n.t("modals.createRecordError.okText")
      };
      onFailed ? onFailed(params.catalogId, recordId, text) : alert(text);
    }

    this.changed();
  }
};

export default recordMixinCreating;

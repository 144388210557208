import _ from "lodash";

export const InputMappers = {
  boolToCheckbox(value) {
    return value ? [String(value)] : [];
  }
};

export const OutputMappers = {
  checkboxToBool(value) {
    return _.isArray(value) && value.length ? true : false;
  }
};

import React from "react";
import cn from "classnames";

import BaseMenuList from "./BaseMenuList";
import dndContext from "../../../../services/dndContext";

import styles from "./menu.less";

const Menu = ({ direction = "row", tabPosition, ...props }) => {
  // for tabs (horizontal menu)
  if (direction === "row") {
    tabPosition = tabPosition || "top";
  }

  // for list (vertical menu)
  if (direction === "column") {
    tabPosition = tabPosition || "left";
  }

  return (
    <BaseMenuList
      tabPosition={tabPosition}
      direction={direction}
      {...props}
    />
  );
};

export default dndContext(Menu);

import debug from "debug";

import FieldFactory from "../models/FieldFactory";
import generateUniqName from "../utils/generateUniqName";
import FIELD_TYPES from "../configs/fieldTypes";
import FieldApi from "../models/FieldApi";
import { newId } from "../configs/fieldIds";

const log = debug("CRM:Store:editorMixin");

const editorMixin = {
  _setEditingCatalogUnsaved(sectionId, val) {
    this.setIn(["editingCatalogs", sectionId, "unsaved"], !!val);
  },

  removeField(sectionId, fieldIndex) {
    let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
    let section = fields.get(fieldIndex);
    const newSection = !section;

    // delete visibility rules
    const fieldId = fields.get(fieldIndex).get("id");
    if (fieldId) {
      fields = fields.map(f => {
        if (f.getIn(["visible", fieldId])) {
          f = f.deleteIn(["visible", fieldId]);
        }
        return f;
      });
    }

    // если удалена секция

    // делаем следующую секцию ПОСЛЕ УДАЛЕННОЙ СЕКЦИИ вкладкой, если текущая секция БЫЛА вкладкой
    if (!newSection && section.getIn(["config", "tab"]) === true) {
      const fromCurrentSectionIndex = fieldIndex + 1; // + next position
      let nextSectionIndex = -1;
      for (let i = fromCurrentSectionIndex + 1; i < fields.size; i++) {
        if (fields.get(i).get("type") === FIELD_TYPES.GROUP) {
          nextSectionIndex = i;
          break;
        }
      }
      if (nextSectionIndex !== -1) {
        let nextSection = fields.get(nextSectionIndex);
        if (!nextSection.getIn(["config", "tab"])) {
          nextSection = nextSection.setIn(["config", "tab"], true);
          fields = fields.set(nextSectionIndex, nextSection);
        }
      }
    }

    // delete field
    fields = fields.delete(fieldIndex);

    // update fields list
    this.setIn(["editingCatalogs", sectionId, "fields"], fields);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  dropField(sectionId, fieldIndex, fieldType, prevFieldIndex, config = {}, t) {
    let field;
    // если попытаемся переташить поле перед вкладкой
    if (prevFieldIndex === -1) {
      return;
    }

    if (fieldIndex !== null) {
      let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);

      field = fields.get(fieldIndex);

      this.setIn(
        ["editingCatalogs", sectionId, "fields"],
        fields.delete(fieldIndex)
      );
    }

    if (!field) {
      field = FieldFactory.create({
        type: fieldType,
        config
      });
      let uniqName, newName;
      // если расширенное поле
      if (!_.isEmpty(config.type)) {
        newName = t(`fieldTypes.${field.getIn(["config", "type"])}.name`);
        // если стнадартное поле
      } else {
        newName = field.get("name");
      }

      uniqName = generateUniqName(
        newName,
        this.getIn(["editingCatalogs", sectionId, "fields"])
          .toArray()
          .map(c => c.get("name"))
      );

      field = field.set("name", uniqName);
    }

    let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
    let offset = 0;
    if (fieldIndex === undefined || prevFieldIndex < fieldIndex) {
      offset = 1;
    }

    this.setIn(
      ["editingCatalogs", sectionId, "fields"],
      fields.splice(prevFieldIndex + offset, 0, field)
    );

    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  dropSection(
    sectionId,
    sectionIndex = null,
    sectionType,
    prevFieldIndex,
    moveWithFields,
    setSectionAsTab
  ) {
    let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
    let section = fields.get(sectionIndex);
    const newSection = !section;
    // если перетаскиваем секции на свое же место
    if (prevFieldIndex === sectionIndex) {
      return;
    }
    // создаем новую секуцию
    if (newSection) {
      section = FieldFactory.create({ type: FIELD_TYPES.GROUP });
      const name = section.get("name");
      const allNamesFields = this.getIn([
        "editingCatalogs",
        sectionId,
        "fields"
      ])
        .toArray()
        .map(c => c.get("name"));

      let uniqName = generateUniqName(name, allNamesFields);
      section = section.set("name", uniqName);
    }

    // делаем следующую секцию НА ПРОШЛОЙ ПОЗИЦИИ вкладкой, если текущая секция БЫЛА вкладкой
    if (!newSection && section.getIn(["config", "tab"]) === true) {
      const fromCurrentSectionIndex = sectionIndex + 1; // + next position
      let nextSectionIndex = -1;
      for (let i = fromCurrentSectionIndex + 1; i < fields.size; i++) {
        if (fields.get(i).get("type") === FIELD_TYPES.GROUP) {
          nextSectionIndex = i;
          break;
        }
      }
      if (nextSectionIndex !== -1) {
        let nextSection = fields.get(nextSectionIndex);
        if (!nextSection.getIn(["config", "tab"])) {
          nextSection = nextSection.setIn(["config", "tab"], true);
          fields = fields.set(nextSectionIndex, nextSection);
        }
      }
    }

    // set tab state
    section = section.setIn(["config", "tab"], setSectionAsTab);

    // запоминаем филд, перед которым будем вставлять секцию
    let prevField = prevFieldIndex > -1 ? fields.get(prevFieldIndex) : null;

    // свернутые секция переносим с полями
    const moveSectionWithFields = [section];
    if (!newSection && moveWithFields) {
      // ищем все филды этой секции, спускаясь вниз по массиву до следующей секции
      for (let i = sectionIndex + 1; i < fields.size; i++) {
        if (fields.get(i).get("type") === FIELD_TYPES.GROUP) {
          break;
        }
        moveSectionWithFields.push(fields.get(i));
      }
    }

    let newPrevFieldIndex;

    // вырезаем секцию с филдами из общего массива филдов
    if (!newSection) {
      fields = fields.splice(sectionIndex, moveSectionWithFields.length);
    }

    // в новом массиве ищем филд, перед которым будем вставлять секцию
    // если такого нет, то вставляем в начало массива
    newPrevFieldIndex = prevField
      ? fields.findIndex(f => f.get("uuid") === prevField.get("uuid"))
      : prevFieldIndex;
    // филда может не быть в новом масиве, так как он переносится на свое же место
    newPrevFieldIndex =
      prevField && newPrevFieldIndex === -1
        ? sectionIndex - 1
        : newPrevFieldIndex;

    // если секция находится в конце массива и ее перемещаем снова в конец
    if (prevFieldIndex > fields.size) {
      newPrevFieldIndex = fields.size - 1;
    }
    // вставляем секцию в новое место
    fields = fields.splice(newPrevFieldIndex + 1, 0, ...moveSectionWithFields);

    // делаем следующую секцию НА НОВОЙ ПОЗИЦИИ не вкладкой, если текущая секция СТАЛА вкладкой
    if (setSectionAsTab) {
      const fromCurrentSectionIndex = newPrevFieldIndex + 1; // + next position
      let nextSectionIndex = -1;
      for (let i = fromCurrentSectionIndex + 1; i < fields.size; i++) {
        if (fields.get(i).get("type") === FIELD_TYPES.GROUP) {
          nextSectionIndex = i;
          break;
        }
      }
      if (nextSectionIndex !== -1) {
        let nextSection = fields.get(nextSectionIndex);
        if (nextSection.getIn(["config", "tab"])) {
          nextSection = nextSection.setIn(["config", "tab"], false);
          fields = fields.set(nextSectionIndex, nextSection);
        }
      }
    }

    this.setIn(["editingCatalogs", sectionId, "fields"], fields);

    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  dropTab(sectionId, fieldIndex, prevFieldIndex) {
    let fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
    let section = fields.get(fieldIndex);

    // если идет перемещение в конец

    // определение позиции вставки вкладки, в зависимости от направления ее переноса (налево или направо)
    let prevTabIndex = prevFieldIndex;
    if (prevFieldIndex < fields.size) {
      // prevFieldIndex = последнему полю во вкладке, на которую перемещаем (а не позиции перед этой вкладкой), так как это нужно для перемещения секций и полей вконкец вкладкит, при их дропе на вкладку
      // если перетаскиваем вклдаку справа-налево - то ищем от текущего места вставки
      // если перетаскиваем вклдаку слева-направо - то ищем от следующей вкладки
      const offset = fieldIndex > prevFieldIndex ? 0 : 1;
      for (let i = prevFieldIndex + offset; i >= 0; i--) {
        if (fields.getIn([i, "config", "tab"])) {
          prevTabIndex = i;
          break;
        }
      }
    }

    // ищем все поля этой вкладкти, спускаясь вниз по массиву до следующей вкладки
    const moveSectionWithFields = [section];
    for (let i = fieldIndex + 1; i < fields.size; i++) {
      if (fields.getIn([i, "config", "tab"])) {
        break;
      }
      moveSectionWithFields.push(fields.get(i));
    }

    // вырезаем секцию с филдами из общего массива филдов
    fields = fields.splice(fieldIndex, moveSectionWithFields.length);

    // в новом массиве ищем филд, перед которым будем вставлять секцию
    prevTabIndex =
      fieldIndex > prevFieldIndex
        ? prevTabIndex
        : prevTabIndex - moveSectionWithFields.length;

    // перенос вкладки со всеми полями
    fields = fields.splice(prevTabIndex, 0, ...moveSectionWithFields);
    this.setIn(["editingCatalogs", sectionId, "fields"], fields);

    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  updateField(sectionId, fieldIndex, field) {
    let state = this.getState();

    const fieldPath = ["editingCatalogs", sectionId, "fields", fieldIndex];
    // Удаление ключа newId из поля, если его больше не существует,
    // чтобы не мерджить со старым значением
    // TODO: Это явно не должно быть тут в качестве исключения
    if (field && !field.has(newId)) {
      state = state.deleteIn([...fieldPath, newId]);
    }

    state = state.mergeIn(fieldPath, field || {});
    this.setState(state);

    // validate field
    this.validateField(sectionId, fieldIndex);

    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  changeFieldConfig(sectionId, fieldIndex, config) {
    let state = this.getState();
    state = state.mergeIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex, "config"],
      config || {}
    );
    this.setState(state);

    // validate field
    this.validateField(sectionId, fieldIndex);

    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setFieldName(sectionId, fieldIndex, value) {
    this.setFieldAttr(sectionId, fieldIndex, "name", value);
  },

  setFieldRequired(sectionId, fieldIndex, value) {
    this.setFieldAttr(sectionId, fieldIndex, "required", value);
  },

  setFieldApiOnly(sectionId, fieldIndex, value) {
    this.setFieldAttr(sectionId, fieldIndex, "enabled", value);
  },

  setFieldHint(sectionId, fieldIndex, value) {
    this.setFieldAttr(sectionId, fieldIndex, "hint", value);
  },

  setFieldAttr(sectionId, fieldIndex, attr, value) {
    // set attr value
    this.setIn(
      ["editingCatalogs", sectionId, "fields", fieldIndex, attr],
      value
    );

    // validate field
    this.validateField(sectionId, fieldIndex);

    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  validateField(sectionId, fieldIndex) {
    // validate field
    const field = this.getIn([
      "editingCatalogs",
      sectionId,
      "fields",
      fieldIndex
    ]);
    const fields = this.getIn(["editingCatalogs", sectionId, "fields"]);
    const validateErrors = FieldApi.validateField(field, fields) || [];
    if (validateErrors) {
      this.setIn(
        ["editingCatalogs", sectionId, "fields", fieldIndex, "validateErrors"],
        validateErrors
      );
    }
  },

  setCatalogIcon(sectionId, icon) {
    this.setIn(["editingCatalogs", sectionId, "icon"], icon);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setCatalogName(sectionId, name) {
    this.setIn(["editingCatalogs", sectionId, "name"], name);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  },

  setCatalogSection(sectionId, catalogSectionId) {
    this.setIn(["editingCatalogs", sectionId, "sectionId"], catalogSectionId);
    this._setEditingCatalogUnsaved(sectionId, true);
    this.changed();
  }
};

export default editorMixin;

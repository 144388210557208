import FIELD_TYPES, { INPUT_LIST } from "../../../../configs/fieldTypes";

export const CELL_TYPES = {
  POPOVER: "popover",
  SELECTOR: "selector",
  CONTROL: "control",
  INLINE: "inline"
};

const cellTypes = new Map([
  [FIELD_TYPES.PROGRESS, CELL_TYPES.POPOVER],
  [FIELD_TYPES.ADDRESS, CELL_TYPES.POPOVER],
  [FIELD_TYPES.STARS, CELL_TYPES.POPOVER],
  [FIELD_TYPES.CONTACT, CELL_TYPES.POPOVER],
  [FIELD_TYPES.FILE, CELL_TYPES.POPOVER],
  [INPUT_LIST.MULTILINE, CELL_TYPES.POPOVER],
  [INPUT_LIST.TEXT_EDITOR, CELL_TYPES.POPOVER],

  [FIELD_TYPES.OBJECT, CELL_TYPES.SELECTOR],
  [FIELD_TYPES.USER, CELL_TYPES.SELECTOR],
  [FIELD_TYPES.DROPDOWN, CELL_TYPES.SELECTOR],
  [FIELD_TYPES.CHECKBOXES, CELL_TYPES.SELECTOR],
  [FIELD_TYPES.RADIOBUTTON, CELL_TYPES.SELECTOR],
  [FIELD_TYPES.DATE, CELL_TYPES.SELECTOR],

  [FIELD_TYPES.TEXT, CELL_TYPES.CONTROL],
  [FIELD_TYPES.NUMBER, CELL_TYPES.CONTROL],
  [FIELD_TYPES.SWITCH, CELL_TYPES.CONTROL],
]);

export default field => {
  if (!field) {
    return false;
  }

  return (
    cellTypes.get(field.getIn(["config", "type"])) ||
    cellTypes.get(field.get("type")) ||
    CELL_TYPES.INLINE
  );
};

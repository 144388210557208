import React from "react";
import Immutable, { fromJS } from "immutable";
import _ from "lodash";

import importActions from "../../actions/importActions";
import FIELD_TYPES from "../../configs/fieldTypes";
import appState from "../../appState";
import { VALUE_STATUSES } from "../../configs/import";
import { Field } from "./BaseClass.js";

const delimiters = [",", ";"];

const splitValue = (values, delimiter) => {
  values = _.chain(values)
    .split(delimiter)
    .map(_.trim)
    .value();

  return Immutable.List(values);
};

/* загружаем доступные варианты выбора юсера в апп стейт и передаем их далее */
const getItems = async () => {
  const users = appState.getIn(["import", "users"]);

  if (users) {
    return users;
  } else {
    const result = await importActions.loadUsers({});
    return result;
  }
};

const findUser = async (value, field) => {
  const users = await getItems();

  const userData = users.find(user => {
    const title = _.get(user, "title");
    const text = _.get(user, "text");
    const id = _.get(user, "id");
    return (
      _.toLower(value) == _.toLower(title) ||
      value == id ||
      _.toLower(value) == _.toLower(text)
    );
  });

  if (userData) {
    let { key, text, ...user } = userData;

    return Immutable.fromJS([
      {
        ...user,
        id: key,
        title: text
      }
    ]);
  }
  return false;
};

export default class User extends Field {
  constructor() {
    super();
  }
  static type = FIELD_TYPES.USER;
  static components = {
    inline: require("../../components/common/dataTypes/UserField").default,
    control: require("../../components/common/UI/ControlList/controls/User")
      .default,
    selector: require("../../components/Fields/selectors/UserSelector").default
  };

  static parseValue = async (field, value) => {
    await getItems();

    value = _.trim(value);

    /* попытка распарсить пустое значение */
    if (_.isEmpty(value)) {
      value = User.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    if (field) {
      const multiselect = field.getIn(["config", "multiselect"]);

      /* попытка распарсить мультиселект */
      if (multiselect) {
        /* как заданное через разделители*/
        let valuesByDelimetr = {};

        for (let delimiter of delimiters) {
          /* разделение значения по разделителям */
          const splitedValuesByDelimetr = splitValue(value, delimiter);

          if (splitedValuesByDelimetr && !splitedValuesByDelimetr.isEmpty()) {
            /* для каждого значения проводим валидацию, это нужно для "мягкого" режима отображения */

            let validatedValues = Immutable.List([]);

            for (let value of splitedValuesByDelimetr) {
              const item = await findUser(value, field);

              if (User.validateValue(field, item)) {
                validatedValues = validatedValues.concat(item);
              }
            }

            /* заполняем объект удачно распаршенными значениями */
            if (validatedValues && !validatedValues.isEmpty()) {
              valuesByDelimetr[delimiter] = validatedValues;
            }
          }
        }

        valuesByDelimetr = _.sortBy(valuesByDelimetr, values => values.size);
        const mostMatchesValues = _.last(valuesByDelimetr);

        if (mostMatchesValues && !mostMatchesValues.isEmpty()) {
          return { value: mostMatchesValues, status: VALUE_STATUSES.VALID };
        }
      } else {
        /* попытка распарсить не мультиселект */
        const item = await findUser(value, field);

        if (User.validateValue(field, item)) {
          return { value: item, status: VALUE_STATUSES.VALID };
        }
      }
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (field, value) => {
    if (Immutable.List.isList(value)) {
      return true;
    }

    if (!field) {
      return false;
    }
  };

  static sortValues = (field, values, sortType) => {
    values = _.sortBy(values.toJS ? values.toJS() : values, item => item.title);

    return Immutable.List(values.map(value => fromJS(value)));
  };

  static getComponent = type => User.components[type];

  static getCanComponentExpandWidthOrHeight = value => {
    return;
  };

  static convertIdToValue = (field, id, records, filters) => {
    const value = User.receivePossibleItems(field, records, filters).find(
      v => v.get("id") === id
    );
    return value ? value : null;
  };

  static setValue = (values, newValues) => {
    return newValues ? values.push(newValues) : values;
  };

  static hasItem = (value, id) => {
    return value && value.some(v => v.get("id") !== id);
  };

  static removeItem = (values, id) => {
    return values.filter(v => v.get("id") !== id);
  };

  static getEmptyValue = () => {
    return Immutable.List();
  };

  static receivePossibleItems = (field, records, filters) => {
    let items = [];
    records.forEach(record => {
      const addItems = record.getIn(["values", field.get("id")]);
      addItems.forEach(addItem => {
        if (!items.some(item => User.compareItem(item, addItem))) {
          items.push(addItem);
        }
      });
    });
    filters &&
      filters.forEach(filterItem => {
        if (!items.some(item => User.compareItem(item, filterItem))) {
          items.push(filterItem);
        }
      });
    return fromJS(items);
  };

  static compareItem = (item1, item2) => {
    return item1.get("id") === item2.get("id");
  };

  static compare = (value1, value2) => {
    if (value1 && value1.toJS && value2 && value2.toJS) {
      return value1.equals(value2);
    }

    return _.isEqual(value1, value2);
  };

  static valueIs = (value, type) => {
    return typeof value === type;
  };

  static calcId = item => {
    return item.get("id");
  };

  static isEmpty = value => !(value && value.size);

  static createComponent = (field, value, type) => {
    const Component = User.components[type];
    value = value.toJS ? value : fromJS(value);
    value = _.isArray(value.toJS()) ? value : Immutable.List([value]);
    return ({ containerClassName }) => {
      if (User.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };
  static convertFilterToRecordValue = (field, fieldFilters, catalogs, user) => {
    if (!fieldFilters) return;

    let values = [];

    fieldFilters.forEach(filterValues => {
      if (_.isEmpty(filterValues)) return;
      const filterValue = filterValues.value;
      filterValue.forEach(value => {
        switch ("CURRENT_USER") {
          case value.id:
            value.id = user.get("id");
            value.title = user.get("title");
            values.push(value);
            break;
          default:
            values.push(value);
        }
      });
    });
    values = _.uniqBy(values, "id");
    return values;
  };
  static getDefaultValue = field => {
    const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  static validateRequired = value => {
    const empty = !value || (_.isArray(value) && value.length == 0);
    return empty;
  };

  static boardWitchColor = () => {
    return true;
  };

  static visibleRules = v => {
    v = v.map(i => {
      if (i.id == "CURRENT_USER") {
        i.id = appState.getIn(["user", "id"]);
      }
      return { id: i.id };
    });
    v = _.uniqBy(v, "id");
    v = { $in: v };

    return v;
  };

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get("id");
    // sort by username
    return records.sortBy(r => r.getIn(["values", fieldId, 0, "title"]));
  };

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(["config", "type"]);
    switch (type) {
      default:
        return false;
    }
  };

  static validateField(field, allFields) {
    return super.validateField(field, allFields);
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Text colors */\n/* Background colors */\n/* Border colors */\n/* Colors */\n/* badge */\n/* btn-danger */\n/* btn-warning */\n/* btn-light */\n/* btn-dark */\n/* Required field is empty */\n/* Required field */\n/* AppUpdateButton */\n/* Standart padding */\n/* padding wrapper like inputs @input-padding-vertical-base + 1 (border) */\n/* padding wrapper like inputs @input-padding-horizontal + 1 (border) */\n/* height inputs */\n/* input width */\n/* popover max-width */\n/* some problem with popover cause it uses in 2 different places */\n/* Standart height header */\n/* Rows*/\n/* Standart record data header */\n/* ant menu item dropdwon */\n/* Fonts Size */\n/* left panel width */\n/* Sizes of menu popover */\n.script__sideBar___Hs7Nq {\n  background: white;\n}\n.script__sideBarHeader___921u8 {\n  background: white;\n}\n.script__tabsMenu___46aQg {\n  min-width: 200px;\n}\n.script__hidden___08jpp {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Script/script.less"],"names":[],"mappings":"AAAA,gBAAgB;AAChB,sBAAsB;AACtB,kBAAkB;AAClB,WAAW;AACX,UAAU;AACV,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd,aAAa;AACb,4BAA4B;AAC5B,mBAAmB;AACnB,oBAAoB;AACpB,qBAAqB;AACrB,0EAA0E;AAC1E,uEAAuE;AACvE,kBAAkB;AAClB,gBAAgB;AAChB,sBAAsB;AACtB,kEAAkE;AAClE,2BAA2B;AAC3B,QAAQ;AACR,gCAAgC;AAChC,2BAA2B;AAC3B,eAAe;AACf,qBAAqB;AACrB,0BAA0B;AApB1B;EACE,iBAAA;AAsBF;AAnBA;EACE,iBAAA;AAqBF;AAlBA;EACE,gBAAA;AAoBF;AAfA;EACE,aAAA;AAiBF","sourcesContent":["@import \"../../styles/helpers/variables.less\";\n\n\n// SIDE BAR \n\n.sideBar {\n  background: white;\n}\n\n.sideBarHeader {\n  background: white;\n}\n\n.tabsMenu {\n  min-width: 200px;\n}\n\n// SANDBOX\n\n.hidden {\n  display: none\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBar": "script__sideBar___Hs7Nq",
	"sideBarHeader": "script__sideBarHeader___921u8",
	"tabsMenu": "script__tabsMenu___46aQg",
	"hidden": "script__hidden___08jpp"
};
export default ___CSS_LOADER_EXPORT___;
